import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-rcyc-pagination',
  templateUrl: './rcyc-pagination.component.html'
})
export class RcycPaginationComponent implements OnInit {
  @Input() totalItems: number = 100;
  @Input() itemsPerPage: number = 9;
  @Input() pageNumbersToShow: number; // Change based on how many pages to show in the middle
  @Output() pageChanged = new EventEmitter<number>();

  totalPages: number = 0;
  currentPage: number = 1;
  visiblePages: number[] = [];
  showLeftElipses: boolean = false;
  showRightElipses: boolean = false;

  ngOnInit(): void {
    if (this.totalItems) {
      this.initPagination();
    }
  }

  ngOnChanges() {
    if (this.totalItems) {
      this.initPagination();
    }
  }

  initPagination() {
    this.showLeftElipses = false;
    this.showRightElipses = false;
    this.currentPage = 1;
    this.itemsPerPage = +this.itemsPerPage;
    this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
    this.updateVisiblePages();
  }

  updateVisiblePages(): void {
    this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
    const sidePages = Math.floor(this.pageNumbersToShow / 2);
    if (this.currentPage <= sidePages) {
      this.visiblePages = Array.from({ length: this.pageNumbersToShow }, (_, i) => i + 1).slice(0, this.totalPages);
    } else if (this.currentPage >= this.totalPages - sidePages) {
      this.visiblePages = Array.from({ length: this.pageNumbersToShow }, (_, i) => this.totalPages - this.pageNumbersToShow + i + 1);
    } else {
      const startPage = this.currentPage - sidePages;
      this.visiblePages = Array.from({ length: this.pageNumbersToShow }, (_, i) => startPage + i);
    }

    this.showLeftElipses = this.currentPage > Math.ceil(this.pageNumbersToShow / 2) && this.totalPages > this.pageNumbersToShow ? true : false;
    this.showRightElipses = this.currentPage < (this.totalPages - Math.floor(this.pageNumbersToShow / 2)) ? true : false;
  }

  goToPage(page: number): void {
    if (page !== this.currentPage && page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      this.pageChanged.emit(this.currentPage - 1);
      this.updateVisiblePages();
      setTimeout(() => {
        const y = (document.getElementById('scrollPosition') as HTMLElement).getBoundingClientRect().top + window.scrollY - 80;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }, 100);
    }

  }

  previousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.pageChanged.emit(this.currentPage - 1);
      this.updateVisiblePages();
      setTimeout(() => {
        const y = (document.getElementById('scrollPosition') as HTMLElement).getBoundingClientRect().top + window.scrollY - 80;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }, 100);
    }
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.pageChanged.emit(this.currentPage - 1);
      this.updateVisiblePages();
      setTimeout(() => {
        const y = (document.getElementById('scrollPosition') as HTMLElement).getBoundingClientRect().top + window.scrollY - 80;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }, 100);
    }
  }

}
