import { Component, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RcycCommonService } from '../../rcyc-services/rcyc-common/rcyc-common.service';
import { RcycDeckPlanRedesignService } from './rcyc-deck-plan-redesign.service';
import { AppConfig } from '../../rcyc-config/config';
import { RcycHomeService } from '../rcyc-home/rcyc-home.service';
import { RcycOnboardDiningModalComponent } from '../rcyc-onboard-dining-modal/rcyc-onboard-dining-modal.component';
import { RcycSuitesModalComponent } from './rcyc-suites-modal/rcyc-suites-modal.component';
import { RcycOnboardExpModalComponent } from '../rcyc-onboard-exp-modal/rcyc-onboard-exp-modal.component';
import { first, of, switchMap } from 'rxjs';
import { SharedServiceService } from '../../rcyc-services/rcyc-shared/shared-service.service';
import { RcycInternalApisService } from '../../rcyc-services/rcyc-internal-apis/rcyc-internal-apis.service';

@Component({
  selector: 'app-deck-plan-redesign',
  templateUrl: './rcyc-deck-plan-redesign.component.html',
  styleUrls: ['./rcyc-deck-plan-redesign.component.css'],
  providers: [RcycDeckPlanRedesignService, RcycHomeService],
})
export class DeckPlanRedesignComponent implements OnInit {
  @ViewChild('onboardDiningModal', { static: false }) onboardDiningModal: RcycOnboardDiningModalComponent;
  @ViewChild('onboardExpModal', { static: false }) onboardExpModal: RcycOnboardExpModalComponent;
  @ViewChild('onboardSuiteModal', { static: false }) onboardSuiteModal: RcycSuitesModalComponent;

  deckTabObject: any = false;
  apiResponsetab: any = false;
  deckImage: any;
  IMG_BASE_URL: any = AppConfig.IMG_BASE_URL;
  apiUrlMetatag: any;

  responseArray: any;
  yachtName: string = "";
  apiResponse: any = [];
  UserDetails: any = {};
  deckData: any;
  diningData: any;
  suiteData: any;
  currentDeckFloor: any;
  activeTab: any;
  eyebrow: any;
  subHeader: any;
  description: any;
  isMobile: boolean;
  topScreen: boolean = true;
  infoCommonMsg: any;
  additionalCostInfo: any;
  currencyCode: any;
  amenitiesHeaderData: any;
  accomodationHeaderData: any;

  constructor(
    private rcycCommonService: RcycCommonService,
    private rcycDeckPlanService: RcycDeckPlanRedesignService,
    private activatedRouter: ActivatedRoute,
    private rcycHomeService: RcycHomeService,
    private _SharedServiceService: SharedServiceService,
    private rcycInternalApisService: RcycInternalApisService,
  ) {
    //set yachName from url provided
    this.activatedRouter.params.subscribe(
      params => this.yachtName = params['yachtName']
    )
    this.rcycCommonService.changeSubHeaderStatus(true);

    // Call get Metatag function
    this.apiUrlMetatag = '/api/v1/meta/deckplans?_format=json';
    this.rcycCommonService.getMetaTags(this.apiUrlMetatag);
  }
  getUserDetails() {
    const tempUserDetails = this.rcycCommonService.getData('UserDetails');
    if (tempUserDetails) {
      this.UserDetails = tempUserDetails;
    } else {
      this.rcycCommonService.getUser_Details().subscribe(
        response => {
          this.UserDetails = response;
          this.rcycCommonService.setData('UserDetails', this.UserDetails);
        }
      );
    }
  }
  ngOnInit() {
    this.getInfoCommonMsg()
    this.screenSize();
    this.getDeckplanContent();
  }
  scrollTabs(direction: string): void {
    const container = document.querySelector('.tabs-container') as HTMLElement;
    const scrollAmount = 200;

    if (direction === 'left') {
      container.scrollBy({
        left: -scrollAmount,
        behavior: 'smooth'
      })
    } else {
      container.scrollBy({
        left: scrollAmount,
        behavior: 'smooth'
      })
    }
    setTimeout(() => {
      this.checkOverflow()
    }, 500)
  }
  checkOverflow(): void {
    const container = document?.querySelector('.tabs-container') as HTMLElement;
    const leftArrow = document?.querySelector('.left-arrow') as HTMLElement;
    const rightArrow = document?.querySelector('.right-arrow') as HTMLElement;

    const isOverFlowing = container?.scrollWidth > container?.clientWidth;

    const isAtStart = Math.floor(container?.scrollLeft) <= 0;
    const isAtEnd = Math.ceil(container?.scrollLeft + container?.clientWidth) >= container?.scrollWidth;

    requestAnimationFrame(() => {
      if (isOverFlowing && !isAtStart) {
        leftArrow?.classList?.remove('hidden');
      } else {
        leftArrow?.classList?.add('hidden');
      }

      if (isOverFlowing && !isAtEnd) {
        rightArrow?.setAttribute("style", "visibility: visible;")
      } else {
        rightArrow?.setAttribute("style", "visibility: hidden;")
      }
    })
  }
  @HostListener("window:scroll", [])
  onScroll(): void {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    if (scrollTop < 200) {
      this.topScreen = true;
    } else {
      this.topScreen = false;
    }
  }
  getHeaderData(deckFloor: any) {
    const headingUrl = '/api/v1/dynamic-title-eyebrow/deckplans/'+ deckFloor +'?_format=json';
    this.rcycInternalApisService.getDrupal(headingUrl).subscribe({
      next: (response: any = []) => {
        this.amenitiesHeaderData = response[0]
        this.accomodationHeaderData = response[1]
      },
      error: (error) => {
        console.log("Charters Incentives Heading error");
        console.log(error);
      },
    })
  }
  getDeckImage(deckFloor: any) {
    for (let eachDeck of this.apiResponsetab) {
      if (eachDeck.field_deck_floor === deckFloor) {
        if (this.isMobile) {
          this.deckImage = eachDeck.field_mobile_image;
        } else {
          this.deckImage = eachDeck.field_image;
        }
        this.currentDeckFloor = eachDeck.title;
        this.eyebrow = eachDeck.field_com_eyebrow;
        this.subHeader = eachDeck.field_sub_header;
        this.description = eachDeck.body;        
        this.getHeaderData(eachDeck.field_deck_floor)
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  screenSize() {
    this.isMobile = window?.innerWidth < 768;
  }
  // get each section content from Drupal
  getDeckplanContent() {
    // *************Banner****************/
    const apiUrlB = `/api/v1/banners/${this.yachtName}/deckplans/a?_format=json`;
    this.rcycDeckPlanService.deckPlans(apiUrlB).subscribe(
      response => {
        const apiResponseBTemp: any = response;
        if (apiResponseBTemp && apiResponseBTemp.length) {

          const res = Object.keys(response).map(function (key) {
            return response[key];
          });
          if (res.length) {
            this.responseArray = res;
            const resArray = [];
            for (const index in res) {

              const factor = res[index];
              if (factor.field_images) {

                factor.field_images_srcset = this.IMG_BASE_URL +
                  factor.field_images + ' 1600w, ' +
                  this.IMG_BASE_URL + factor.field_image_1440px + ' 1000w, ' +
                  this.IMG_BASE_URL + factor.field_image_768px + ' 800w, ' +
                  this.IMG_BASE_URL + factor.field_image_375px + ' 500w';
                factor.field_images_src1 = this.IMG_BASE_URL + factor.field_images;
                factor.field_images_src2 = this.IMG_BASE_URL + factor.field_image_1440px;
                factor.field_images_src3 = this.IMG_BASE_URL + factor.field_image_768px;
                factor.field_images_src4 = this.IMG_BASE_URL + factor.field_image_375px;
                factor.field_images = this.IMG_BASE_URL + factor.field_images;

                const imgTitle = this.rcycCommonService.imageNameAltSplit(factor.field_images);
                factor.altTextBannerImg = factor.title + ' ' + imgTitle;
                factor.titleTextBannerImg = factor.title + ' ' + imgTitle;
              } else {

                factor.altTextBannerImg = factor.title;
                factor.titleTextBannerImg = factor.title;
              }

              resArray.push(factor);
              if (parseInt(index, 10) + 1 === res.length) {

                const apiResponseB = resArray[0];

              }
            }
          }

        }
      },
      err => {
        console.log('Deckplans B error ');
        console.log(err);
      }
    );

    // *************Deck List****************/
    const apiUrlD = `/api/v1/deckgroup/${this.yachtName}?_format=json`;
    this.rcycDeckPlanService.deckPlans(apiUrlD).subscribe(response => {
      this.apiResponsetab = true;
      this.apiResponsetab = response;
      let temp = 0;

      if (this.apiResponsetab && this.apiResponsetab.length) {
        this.apiResponsetab.map((element, i) => {
          if (element.field_image) {

            element.field_image = this.IMG_BASE_URL + element.field_image;

            const imgTitle = this.rcycCommonService.imageNameAltSplit(element.field_image);

            element.altTextPlanImg = element.title + ' ' + imgTitle;
            element.titleTextPlanImg = element.title + ' ' + imgTitle;
          } else {

            element.altTextPlanImg = element.title;
            element.titleTextPlanImg = element.title;
          }

          if (i === 0) {
            this.changeDeck(element.field_deck_floor)
            this.getDeckImage(element.field_deck_floor)
            element.class = 'tab-link tab-link_suites is-active';
            (element.contentClass = 'tab-pane is-visible'),
              (element.active = true);
          } else {
            (element.class = 'tab-link'),
              (element.contentClass = 'tab-pane'),
              (element.active = false);
          }

          temp++;
        });


        if (temp === this.apiResponsetab.length) {
          this.deckTabObject = true;
          this.deckTabObject = this.apiResponsetab;
        }

      }
    });

    /******************Featured Voyage********************/
    const apiUrl = '/api/v1/featured-voyages/' + this.yachtName + '_deckplans?_format=json';
    this.rcycHomeService.home(apiUrl).subscribe(
      response => {
        const apiResponseDTemp: any = response;
        if (apiResponseDTemp && apiResponseDTemp.length) {
          apiResponseDTemp.map((item, i) => {
            this.rcycHomeService.get_voyage(item.field_com_voyage_id, this.UserDetails?.country_code).subscribe(response => {
              let data = { ...response['data'], ...apiResponseDTemp[i], slider: [] };
              this.rcycCommonService.getSessionData('drupalImageArray').map((image, j) => {
                if (item.field_com_voyage_id === image.field_voyages) {
                  data.slider[0] = [this.rcycCommonService.getSessionData('drupalImageArray')[j].field_voyage_thumbnail]
                }
              })
              this.rcycCommonService.drupalApisGet('/api/v1/voyages/' + item.field_com_voyage_id + '/tab/itineraryoverview?_format=json').subscribe(
                response => {
                  const res = Object.keys(response).map(function (key) {
                    return response[key];
                  });
                  if (res.length) {
                    data.slider[1] = res[0].field_voyage_image;
                  }
                },
                err => {
                  console.log(err);
                },
              );
              // Get data for each voyage
              this.rcycCommonService.drupalApisGet('/api/v1/voyage-card-slider/' + data.field_com_voyage_id + '?_format=json').subscribe(
                response => {
                  const res = Object.keys(response).map(function (key) {
                    return response[key];
                  });
                  if (res.length) {
                    data.slider = [...data.slider, ...res[0].field_com_images.split(', ')];
                    data.highlight = res[0].field_com_highlight_text
                  }
                },
                err => { },
              );
              this.apiResponse.push(data);
              this.currencyCode = this.apiResponse[0]?.feeCurrency;
              this.setAdditionalCostInfo()
            })
          });
        }
      },
      err => { },
    );
  }
  changeDeck(deckFloor: any) {
    this.activeTab = deckFloor;
    this.deckData = [];
    const apiUrlE = `/api/v1/deckcontent/${this.yachtName}/${deckFloor}?_format=json`;
    this.rcycDeckPlanService.deckPlans(apiUrlE).subscribe(
      result => {
        this.deckData = result
        this.getDiningData();
      });
  }
  getDiningData() {
    this.diningData = [];
    this.suiteData = [];
    let suite = [];
    for (let item of this.deckData) {
      if (item.field_deck_content_category === 'experience' || item.field_deck_content_category === 'dining') {
        this.diningData.push(item)
      } else {
        suite.push(item)
      }
    }
    this.suiteData = suite.sort(this.rcycCommonService.orderByProp('field_sort_order'));
  }
  // Open modal for onboard dining
  openDiningModal(diningName: any) {
    if (diningName.field_deck_content_category === 'dining') {
      this.onboardDiningModal.modalOpen({ yacht: this.yachtName, dining: diningName?.title, cta_title: diningName?.field_com_cta_title, cta_link: diningName?.field_com_cta_link, eyebrow: diningName?.field_com_eyebrow });
    } else {
      this.onboardExpModal.modalOpen({ yacht: this.yachtName, experience: diningName.title });
    }
  }
  // Open modal for Suite
  openSuiteModal(suiteName: any) {
    this.onboardSuiteModal.modalOpen({ yacht: this.yachtName, suite: suiteName });
  }

  /**
   * Get common info messages from drupal
   */
  getInfoCommonMsg() {
    this.rcycCommonService.currentInfoMessageSource$.pipe(
      switchMap((value) => value ? of(value) : this._SharedServiceService.getInfoCommonMsg()),
      first()
    ).subscribe((response) => {
      this.infoCommonMsg = response;
      this.rcycCommonService.setInfoMessageSource(response);
    });
  }

  /**
 * Set the additional cost info based on currency code
 */
  setAdditionalCostInfo() {
    const currencyFieldMap = {
      USD: 'field_usd_additional_cost_info',
      USM: 'field_usm_additional_cost_info',
      EUR: 'field_eur_additional_cost_info',
      GBP: 'field_gbp_additional_cost_info',
    };
    this.additionalCostInfo = this.infoCommonMsg?.[currencyFieldMap[this.currencyCode]];
  } 
}
