import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { RcycDestinationLandingService } from './rcyc-destination-landing.service';
import { AppConfig } from '../../rcyc-config/config';
import { RcycCommonService } from '../../rcyc-services/rcyc-common/rcyc-common.service';
import { RcycHomeService } from '../rcyc-home/rcyc-home.service';


@Component({
  selector: 'app-destination-landing',
  templateUrl: './rcyc-destination-landing.component.html',
  providers: [RcycDestinationLandingService, RcycHomeService]
})
export class RcycDestinationLandingComponent implements OnInit, OnDestroy {
  IMG_BASE_URL: any = AppConfig.IMG_BASE_URL;

  apiResponseB: any;
  apiResponseD: any;
  apiResponseE: any;

  mobileView: boolean = false;

  responseArray: any;
  carouselData: any;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const fancyElem: any = document.querySelector('.fancybox-container');
    if (fancyElem) {
      this.mobileView = false;
      if (event.target.innerWidth <= 769) {
        this.mobileView = true;
        fancyElem.classList.remove("fancybox-container-hide");
      } else {
        this.mobileView = false;
        fancyElem.classList.add("fancybox-container-hide");
      }
    }
  }

  constructor(
    private rcycDestinationLandingService: RcycDestinationLandingService,
    private rcycCommonService: RcycCommonService,
    private rcycHomeService: RcycHomeService,
  ) {
    // change changeCurrentComponent
    this.rcycCommonService.changeCurrentComponent('DestinationLandingComponent');

    // Call get Metatag function
    const apiUrlMetatag = '/api/v1/meta/destinations?_format=json';
    this.rcycCommonService.getMetaTags(apiUrlMetatag);
    this.rcycDestinationsContent();
  }

  ngOnInit() {
    if (window.innerWidth <= 769) {
      this.mobileView = true;
    } else this.mobileView = false;

    this.checkFindCruiseFilterData();
  }
  
  ngOnDestroy(): void {
    this.rcycCommonService.removeSessionData('regionlistDrupalData');
  }

  /* ========================================================================================================================
      Function to display Full Api Urls of Destination
   ========================================================================================================================== */
  rcycDestinationsContent() {
    this.rcycChannelContent();
    this.getDrupalContent();
  }

  /* ==============================================================================================================================
      Function to display Full Apis Channels
   ================================================================================================================================ */
  rcycChannelContent() {

    /******************Header  Section B********************/
    let apiResponseBTemp: any = [];
    let apiResponseDTemp: any = [];

    const apiUrlB = '/api/v1/banners/destinations/b?_format=json';
    const apiUrlD = '/api/v1/regionlist?_format=json';
    this.rcycDestinationLandingService.destinationLanding(apiUrlB).subscribe(
      response => {

        apiResponseBTemp = response;
        if (apiResponseBTemp && apiResponseBTemp.length) {

          // var res= Object.values(response);
          const res = Object.keys(response).map(function (key) {
            return response[key];
          });
          if (res.length) {
            this.responseArray = res;
            this.carouselData = res;
            if (this.carouselData[0].field_image_375px) {
              this.carouselData[0].field_image = this.carouselData[0].field_image_375px;
            }
            const resArray = [];
            for (const index in res) {

              const factor = res[index];
              if (factor.field_image) {

                factor.field_images_srcset = this.IMG_BASE_URL + factor.field_image + ' 1600w, ' + this.IMG_BASE_URL +
                  factor.field_image_1440px + ' 1000w, ' + this.IMG_BASE_URL
                  + factor.field_image_768px + ' 800w, ' +
                  this.IMG_BASE_URL + factor.field_image_375px + ' 500w';
                factor.field_images_src1 = this.IMG_BASE_URL + factor.field_image;
                factor.field_images_src2 = this.IMG_BASE_URL + factor.field_image_1440px;
                factor.field_images_src3 = this.IMG_BASE_URL + factor.field_image_768px;
                factor.field_images_src4 = this.IMG_BASE_URL + factor.field_image_375px;
                factor.field_image = this.IMG_BASE_URL + factor.field_image;

                const imgTitle = this.rcycCommonService.imageNameAltSplit(factor.field_image);

                factor.altTextBannerImg = factor.title + ' ' + imgTitle;
                factor.titleTextBannerImg = factor.title + ' ' + imgTitle;
              } else {

                factor.altTextBannerImg = factor.title;
                factor.titleTextBannerImg = factor.title;
              }
              resArray.push(factor);
              if (parseInt(index, 10) + 1 === res.length) {

                this.apiResponseB = resArray;

              }
            }
          }
        }
      },
      err => {
        console.log('Channels B Api err');
        console.log(err);
      }
    );

    /****************** Section D********************/

    this.rcycDestinationLandingService.destinationLanding(apiUrlD).subscribe(
      response => {
        apiResponseDTemp = response;
        if (apiResponseDTemp && apiResponseDTemp.length) {
          this.rcycCommonService.setSessionData('regionlistDrupalData', apiResponseDTemp);
          // var res= Object.values(response);
          const res = Object.keys(response).map(function (key) {
            return response[key];
          });
          if (res.length) {
            const resArray = [];
            for (const index in res) {
              const factor = res[index];
              const field_group_url_keyNew = factor.field_group_url_key.toLowerCase();
              factor.channelUrl = '/luxury-' + field_group_url_keyNew + '-cruises';
              resArray.push(factor);
              this.apiResponseD = resArray;
            }
          }
        }
      },
      err => {
        console.log('Channels D Api err');
        console.log(err);
      }
    );

  }

  getDrupalContent() {
    let apiResponseETemp: any = [];
    const apiUrlE = '/api/v1/featured-articles/destinations?_format=json';
    this.rcycHomeService.home(apiUrlE).subscribe(
      response => {

        apiResponseETemp = response;
        if (apiResponseETemp && apiResponseETemp.length) {

          const res = Object.keys(response).map(function (key) {
            return response[key];
          });
          if (res.length) {
            this.apiResponseE = res;
          }
        }
      },
      err => { }
    );
  }

  checkFindCruiseFilterData() {
    const tempSearch = this.rcycCommonService.getSessionData('findCruise');
    if (!tempSearch?.data?.yachtSearchCriterionForResponseDTO?.regions) {
      // Getting filter data if not available in session storage
      this.rcycHomeService.find_cruise('/api/criteria-result', { page: 0, size: 9, countryCode: 'US' }).subscribe(
        (response: any) => {
          this.rcycCommonService.setSessionData('findCruise', response);
        },
        err => {
          console.error(err);
        });
    }
  }

  setFilterData(region) {
    const tempSearch = this.rcycCommonService.getSessionData('findCruise');
    const regionCodes = tempSearch?.data?.yachtSearchCriterionForResponseDTO?.regions?.filter(item => item.generalDescription.toLowerCase() === region?.toLocaleLowerCase() || item.generalDescription.toLowerCase().includes(region?.toLocaleLowerCase()));
    const tempFilters = { "regionCodes": [regionCodes[0]?.travelRegion.toLocaleLowerCase()], "departureYearMonths": [], "durations": [], "portCodes": [], "yachtIds": [], "countryCode": "US", "page": 0, "size": 9, "selectedDurations": [] };
    this.rcycCommonService.setData('searchcruiseresult', tempFilters);
  }

}
