import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../rcyc-config/config';
import { RcycCommonLogoutService } from '../../rcyc-services/rcyc-logout/rcyc-logout.service';
import { of } from 'rxjs';

@Injectable()
export class RcycInternalApisService {

  API_BASE_URL: any = AppConfig.API_BASE_URL;
  ELASTIC_API_BASE_URL: any = AppConfig.ELASTIC_API_BASE_URL;
  ELASTIC_API_BASE_URL_EBROCHURE: any = AppConfig.ELASTIC_API_BASE_URL_EBROCHURE;
  API_BASE_URL_RESERVATION: any = AppConfig.API_BASE_URL_RESERVATION;
  API_BASE_URL_EDOC: string = AppConfig.API_BASE_URL_EDOC

  constructor(
    private http: HttpClient,
    private rcycCommonLogoutService: RcycCommonLogoutService,
  ) {

  }


  /* ======================================================================================================================================
    Funtion to call All GET Drupal Apis
  * ======================================================================================================================================*/

  getDrupal(apiUrl: string) {

    const url = this.API_BASE_URL + apiUrl;
    return this.http.get(url);
  }

  /* ======================================================================================================================================
    Funtion to call All GET Elastic Apis
  * ======================================================================================================================================*/

  getElastic(apiUrl: string) {

    const url = this.ELASTIC_API_BASE_URL + apiUrl;
    return this.http.get(url);
  }

  /* ======================================================================================================================================
    Funtion to call All GET External APis
  * ======================================================================================================================================*/

  getExternalApi(apiUrl: string) {
    return this.http.get(apiUrl);
  }
  
  getReservationForm(apiUrl){
    const url = this.API_BASE_URL_RESERVATION + apiUrl;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'Accept': '*/*'
      })
    };
    return this.http.get(url,httpOptions);
  }
  /* ======================================================================================================================================
     Funtion to call All POST Elastic Apis
  * ======================================================================================================================================*/

  postElastic(params, apiUrl) {

    const elasticParams = params;
    const url = this.ELASTIC_API_BASE_URL + apiUrl;
    return this.http.post(url, elasticParams);
  }

  /* ======================================================================================================================================
   Funtion to call Elastic Apis Without Auth -- POST
 * ======================================================================================================================================*/
  postEbrochureForm(apiUrl, params) {

    const url = this.ELASTIC_API_BASE_URL_EBROCHURE + apiUrl;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'Accept': '*/*'
      })
    };
    return this.http.post(url, params, httpOptions);
  }

/* ======================================================================================================================================
  Funtion to call Elastic Apis With JWT -- POST
* ======================================================================================================================================*/
  postSecureEbrochureForm(apiUrl, params, authToken = '') {

    const url = this.ELASTIC_API_BASE_URL_EBROCHURE + apiUrl;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'Accept': '*/*',
        'Authorization': `Bearer ${authToken}`
      })
    };
    return this.http.post(url, params, httpOptions);
  }

/* ======================================================================================================================================
 Funtion to call Reservations Apis Without Auth -- POST
* ======================================================================================================================================*/
  postReservationForm(apiUrl, params) {
    const url = this.API_BASE_URL_RESERVATION + apiUrl;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'Accept': '*/*'
      })
    };
    return this.http.post(url, params, httpOptions);
  }

 
  /* ======================================================================================================================================
    Funtion to call Reservation POST File Upload Apis --  POST
  * ======================================================================================================================================*/
  postFileUploadForm(apiUrl, params) {
    const url = this.API_BASE_URL_RESERVATION + apiUrl;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
        'Accept': '*/*'
      })
    };
    return this.http.post(url, params);
  }

  /* ======================================================================================================================================
    Funtion to call Reservation POST File Upload Apis --  POST
  * ======================================================================================================================================*/
  postFileUploadFormWithAuth(apiUrl, params) {
    const url = this.API_BASE_URL_RESERVATION + apiUrl;
    const userData = JSON.parse(localStorage.getItem('loggedUserDetails'));
    if (userData && userData.jwtToken) {
      const authToken = userData.jwtToken;
      const httpOptions = {
        headers: new HttpHeaders({
          // 'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
          'Accept': '*/*',
          'Authorization': `Bearer ${authToken}`
        })
      };
      return this.http.post(url, params, httpOptions);
    } else {
      this.rcycCommonLogoutService.logout();
      return of("Token Invalid");
    }
  }

  /* ======================================================================================================================================
    Funtion to call Reservation POST Apis --  POST
  * ======================================================================================================================================*/
  postReservationFormWithAuth(apiUrl, params) {

    const url = this.API_BASE_URL_RESERVATION + apiUrl;
    const userData = JSON.parse(localStorage.getItem('loggedUserDetails'));

    if (userData && userData.jwtToken) {

      const authToken = userData.jwtToken;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; charset=utf-8',
          'Accept': '*/*',
          'Authorization': `Bearer ${authToken}`
        })
      };

      return this.http.post(url, params, httpOptions);
    } else {

      this.rcycCommonLogoutService.logout();
      return of("Token Invalid");
    }
  }

  /* ======================================================================================================================================
   Funtion to call Reservation POST Apis --  DELETE
 * ======================================================================================================================================*/
  deleteReservationFormWithAuth(apiUrl, params) {

    const url = this.API_BASE_URL_RESERVATION + apiUrl;
    const userData = JSON.parse(localStorage.getItem('loggedUserDetails'));

    if (userData && userData.jwtToken) {

      const authToken = userData.jwtToken;
      const headers = new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'Accept': '*/*',
        'Authorization': `Bearer ${authToken}`
      });

      // {
      //   headers: headers,
      //     params: params,
      // }
      //const options = { httpOptions, params };
      // options: {
      //   headers?: HttpHeaders | {
      //       [header: string]: string | string[];
      //   };
      //   observe?: 'body';
      //   params?: HttpParams | {
      //       [param: string]: string | string[];
      //   };
      return this.http.delete(url, {
        headers: headers,
        params: params,
      });
      // return this.http.delete(url, options );
    } else {

      this.rcycCommonLogoutService.logout();
      return of("Token Invalid");
    }
  }

  /* ======================================================================================================================================
		 Funtion to call vimeo Api
		* ======================================================================================================================================*/

  getVimeoApi(videoUrl: string) {
    return this.http.get(videoUrl);
  }

  /* ======================================================================================================================================
    Funtion to call Reservation GET Apis --  GET
  * ======================================================================================================================================*/
  getReservationFormWithAuth(apiUrl) {

    const url = this.API_BASE_URL_RESERVATION + apiUrl;
    const userData = JSON.parse(localStorage.getItem('loggedUserDetails'));

    if (userData && userData.jwtToken) {

      const authToken = userData.jwtToken;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; charset=utf-8',
          'Accept': '*/*',
          'Authorization': `Bearer ${authToken}`
        })
      };

      return this.http.get(url, httpOptions);
    } else {

      this.rcycCommonLogoutService.logout();
    }
  }
  /* ======================================================================================================================================
   Funtion to call get VoyageInfo
   * ======================================================================================================================================*/
  getVoyageInfo(apiUrl) {

    const url = this.ELASTIC_API_BASE_URL + apiUrl;
    const headers = new HttpHeaders()
      .set('Content-Type', 'text/xml')
      .append('Access-Control-Allow-Methods', 'GET,PUT,POST,DELETE,PATCH,OPTIONS')
      .append('Access-Control-Allow-Origin', '*')
      .append('Access-Control-Allow-Headers', 'Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method')


    return this.http.get(url, { headers: headers, responseType: 'text' });
  }

  /* =======================================================
    Funtion to call All GET global Apis
  * =========================================================*/

  getGlobal(apiUrl: string) {
    const url = apiUrl;
    return this.http.get(url);
  }

  /* ======================================================================================================================================
      Funtion to save a File as PDF
  * ======================================================================================================================================*/

  downloadPdfByUrlWithAuth(bookId: string) {
    const url = `${this.API_BASE_URL_EDOC}/api/download/${bookId}` ;
    const userData = JSON.parse(localStorage.getItem('loggedUserDetails'));
    if (userData && userData.jwtToken) {
      const authToken = userData.jwtToken;
      const headers = new HttpHeaders().set('authorization','Bearer '+authToken);
      return this.http.get(url,{headers, responseType: 'blob' as 'json',reportProgress: true, observe:'events'} );
    }
    else {
      this.rcycCommonLogoutService.logout();
    }
  }

  downloadExcursionPdfByUrlWithAuth(voyageCodes: string) {
    const url = `${this.API_BASE_URL_EDOC}/api/downloadExcursionPdf/${voyageCodes}` ;
    const userData = JSON.parse(localStorage.getItem('loggedUserDetails'));
    if (userData && userData.jwtToken) {
      const authToken = userData.jwtToken;
      const headers = new HttpHeaders().set('authorization','Bearer '+authToken);
      return this.http.get(url,{headers, responseType: 'blob' as 'json'} );
    }
    else {
      this.rcycCommonLogoutService.logout();
    }
  }
  /* ======================================================================================================================================
    Funtion to call get E-doc APIs with JWT Auth --  GET
  * ======================================================================================================================================*/
 getEdocApiWithAuth(edocUrl) {
  const url =`${this.API_BASE_URL_EDOC}${edocUrl}`;
  const userData = JSON.parse(localStorage.getItem('loggedUserDetails'));
  if (userData && userData.jwtToken) {
    const authToken = userData.jwtToken;
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': '*/*',
        'Authorization': `Bearer ${authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }
  else {
    this.rcycCommonLogoutService.logout();
    return of("Token Invalid");
  }
 }



  /*===================================================================
    Function to return complete guest page configuration
  ===================================================================*/
  downloadVoyagePdf (pdfUrl: string) {
    const url =`${this.API_BASE_URL_EDOC}${pdfUrl}`;
    let headers = new HttpHeaders({
      'Accept': '*/*'
    });
    return this.http.get(url, {headers, responseType: 'blob' as 'json'});
  }

    /*===================================================================
    Function to check whether the entered mail is already in use
  ===================================================================*/
  public requestEmailCheck(email: any) {
    const apiUrl = this.API_BASE_URL_RESERVATION + '/secureapi/checkEmailValidity';
    const userData = JSON.parse(localStorage.getItem('loggedUserDetails'));
    if (userData && userData.jwtToken) {
      const authToken = userData.jwtToken;
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': '*/*',
          'Authorization': `Bearer ${authToken}`
        })
      };
      return this.http.post(apiUrl,{email},httpOptions);
    }
  }


   /**
   * returns the available arrival time slots for the boarding details section
   */
   getArrivalTimeSlots(bookingId,apiUrl) {
    const url = this.API_BASE_URL_RESERVATION  + apiUrl;
    const userData = JSON.parse(localStorage.getItem('loggedUserDetails'));
    if (userData && userData.jwtToken) {
      const authToken = userData.jwtToken;
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': '*/*',
          'Authorization': `Bearer ${authToken}`
        })
      };
      return this.http.post(url,bookingId,httpOptions);
    }
  }

  submitSlotData(data,apiUrl) {
    const url = this.API_BASE_URL_RESERVATION  + apiUrl;
    const userData = JSON.parse(localStorage.getItem('loggedUserDetails'));
    if (userData && userData.jwtToken) {
      const authToken = userData.jwtToken;
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': '*/*',
          'Authorization': `Bearer ${authToken}`
        })
      };
      return this.http.post(url,data,httpOptions);
    }
  }

  getDrupalDataForChartersIncentives(URL) {
    const APIURL = this.API_BASE_URL + URL;
    return this.http.get(APIURL);
  }
}

