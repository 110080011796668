import { Component, OnInit, ViewEncapsulation, ViewChild, Input, ElementRef, HostListener } from '@angular/core';
import { RcycCommonService } from '../../rcyc-services/rcyc-common/rcyc-common.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RcycRequestCallService } from './rcyc-request-call.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as EmailValidator from 'email-validator';
import { RcycFormTokenService } from '../../rcyc-services/rcyc-form-token/rcyc-form-token.service';
import { RcycErrorModalComponent } from '../../rcyc-modals/rcyc-error-modal/rcyc-error.component';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { RcycHomeService } from '../rcyc-home/rcyc-home.service';
import { RcycInternalApisService } from '../../rcyc-services/rcyc-internal-apis/rcyc-internal-apis.service';
import { ValidatePhoneNumber,PhoneDataFormat,deepPhoneConfiguration,formatPhoneList } from '../../rcyc-share-components/rcyc-phonenumber-validator/rcyc-phonenumber-validator';
// import { CookiesService } from '@ngx-utils/cookies';
import {CookieService} from 'ngx-cookie-service';
import { getMaskedCode } from '../../../utils/phone-number-validation';
import { ChangeDetectorRef } from '@angular/core';
import { RcycHiddenSalesforceFormService } from '../../rcyc-services/rcyc-salesforce/rcyc-hidden-salesforce-form.service'
import { SalesForceFormData } from '../../rcyc-services/rcyc-salesforce/salesforce-form-data.interface';
import { AppConfig } from '../../rcyc-config/config';
 
declare let ga: Function;
 
@Component({
  selector: 'app-rcyc-request-call',
  templateUrl: './rcyc-request-call.component.html',
  styleUrls: ['./rcyc-request-call.component.css'],
  // encapsulation: ViewEncapsulation.None
})
export class RcycRequestCallComponent implements OnInit {
 
 
 
  @ViewChild('errorModal') errorModal: RcycErrorModalComponent;
  @Input() specialOfferExpandFlag;
  salesForceImplementation:boolean = AppConfig.salesForceImplementation;
  userAreaDetails: any;
  userArea: any;
  lead_gen_form: UntypedFormGroup;
  phNumReqStatusDynamic: boolean;
  phoneDataArray: PhoneDataFormat[];
  formttedPhoneList : any={};
  userCountryDetails: PhoneDataFormat;
  requestCallFormToken: any;
  apiResponseA: any;
  apiResponseB: any;
  apiResponseC: any;
  apiUrlMetatag: any;
  flagCodeClickStatus: boolean;
  countrySearch: string;
  requestCallFormResponse: Object;
  formSubmitFailStatus: boolean;
  permanentGeoLocation: any;
  zipcodeReqStatusDynamic = false;
  countryListArray: any = [];
  isMobilebutton: false;
  submitLoading:boolean = false;
 
  tokenErrorMsg: any = this.rcycFormTokenService.tokenErrorMsg;
  apiUrlD: any;
  apiResponseDTemp: any = [];
  maxLength:number=10;
  zipcodeMaxLength: number=10;
 
  snapshotUrl: any;
  currentUrlPathAsArray: any;
  currentUrlPath: any;
  isRequestCallStatus=false;
  specialCountryInputClickStatus=false;
  specialCountryInputCloseBtnStatus=false;
  isSpecialCountrySelect=false;
  selectCountryCode:string;
  mask = [];
  specialOfferFormErrorMsg:string;
  tokenMessage:any;
  requestCallFormStatus = false;
  showPhoneopt = false;
  showTermUS = false;
  showPhoneOptRequiredText = false;
  drupalErrorMessages: any;
  sessionExpiredMessages:any;
  isFirstNameInputFocused: boolean = false;
  isLastNameInputFocused: boolean = false;
  isSpecialCountryInputFocused: boolean = false;
  isZipCodeInputFocused: boolean = false;
  isEmailInputFocused: boolean = false;
  isPhoneInputFocused: boolean = false;
  public expertCarouselSettings: any;
  isMobileScreen: boolean;
 
  constructor(
    private formBuilder: UntypedFormBuilder,
    private rcycCommonService: RcycCommonService,
    private http: HttpClient,
    private rcycRequestCallService: RcycRequestCallService,
    private router: Router,
    private rcycFormTokenService: RcycFormTokenService,
    private scrollToService: ScrollToService,
    private rcycHomeService: RcycHomeService,
    private activatedRoute: ActivatedRoute,
    private elementRef: ElementRef,
    private cookies: CookieService,
    private rcycInternalApisService: RcycInternalApisService,
    private changeDetector: ChangeDetectorRef,
    private rcycHiddenSalesforceFormService : RcycHiddenSalesforceFormService,
  ) {
 
    this.snapshotUrl = this.activatedRoute.snapshot as any;
    this.currentUrlPathAsArray = this.snapshotUrl._urlSegment.segments;
    this.currentUrlPath = this.currentUrlPathAsArray[0].path;
    if(this.currentUrlPath == 'request-call') {
      this.isRequestCallStatus = true;
    }
 
  }
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
  ngOnInit() {
    this.reqCallConstructorFn();
    this.createFormRequestCall();
    this.getUserAreaCode();  
    this.rcycRequestACallContent();  
    const userData = JSON.parse(localStorage.getItem('loggedUserDetails'));
    if (userData && userData.jwtToken) {
      this.getMyProfileDetails();
    }
    this.getDrupalErrorMessages();
 
    this.expertCarouselSettings = {
      grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
      slide: 1,
      speed: 400,
      interval: 0,
      point: {
        visible: false
      },
      load: 3,
      touch: true,
      loop: true,
      custom: 'banner'
    };

    this.isMobileScreen = (window.innerWidth <= 500) ? true : false;
  }

  @HostListener('document:click', ['$event']) onDocumentClick(event: MouseEvent): void{
    const targetElement = event.target as HTMLElement;
    const isOutsideClick = !targetElement.closest('.phoneFlagOuter') && !targetElement.closest('.countryResultOuter');
    if(isOutsideClick){
      this.flagCodeClickStatus = false
    }
  }

  @HostListener('window:resize',['$event'])
  onResize(event) {
    this.isMobileScreen = (event.target.innerWidth <= 500) ? true : false;
  }
 
  getUserAreaCode() {
    // Subscriber to get User Area
    this.rcycCommonService.userAreaCode.subscribe((userAreaDetails) => {
      if (userAreaDetails.length) {
        this.userAreaDetails = userAreaDetails[0];
        this.userArea = this.userAreaDetails.field_area_code;        
      }
    });
  }
  createFormRequestCall(){
    this.lead_gen_form = this.formBuilder.group({
        firstname: ['', [Validators.required,Validators.pattern('[a-zA-ZÀ-ÿ-.\'_ ]*')]],
        lastname: ['', [Validators.required,Validators.pattern('[a-zA-ZÀ-ÿ-.\'_ ]*')]],
        email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-.]{2,})+$')]],
        country: ['', Validators.required],
        countryCode: ['US', Validators.required],
        zipcode: [''],
        phone: ['', Validators.required],
        comment: [''],
        isMobile: [false],
        isAgreeToReceiveMail: [false],
        isAgreeToContact: [false],
        isAgreeToSms: [false],
        dialCode: ['']
    });
    this.formValueChangeCapture();
    this.http.get('./assets/select_country.json').subscribe((data: any) => {
      if(data && data?.length){
        this.countryListArray =  data;
      }
    });
    this.http.get('./assets/rescoNumberFormatted.json').subscribe((phoneDataArray: PhoneDataFormat[]) => {
      if(phoneDataArray && phoneDataArray.length){
        this.phoneDataArray = phoneDataArray;
        this.formttedPhoneList = formatPhoneList(phoneDataArray);
        this.deepPhoneConfigurations(); 
      }         
    });
   
   
  } 
   /* ===================================================
  Function for value  change of form
  =======================================================*/
  formValueChangeCapture() {  
    this.lead_gen_form.get('phone').setValidators([ValidatePhoneNumber]);
    this.lead_gen_form.get('phone').updateValueAndValidity();
    this.lead_gen_form.get('isMobile').valueChanges.subscribe(newValue => {
      if(newValue === false) {
        this.lead_gen_form.patchValue({isAgreeToSms: false});
      }
      this.isMobilebutton = newValue;
    });
    if(!this.isRequestCallStatus) {
      this.lead_gen_form.valueChanges.subscribe((val) => {
        this.specialOfferFormErrorMsg='';
        this.formSubmitFailStatus = false;
      });
      this.lead_gen_form.get('country').valueChanges.subscribe((val) => {
 
        // Editing country field
        this.selectCountryCode='';
        this.specialCountryInputClickStatus=true;
        this.specialCountryInputCloseBtnStatus=true
        this.isSpecialCountrySelect=false;
        this.lead_gen_form.get('phone').setValidators([ValidatePhoneNumber]);
        this.lead_gen_form.get('phone').updateValueAndValidity();
        this.phNumReqStatusDynamic = false;
        this.phoneValueChangeFn();
      });    
    } else {
      this.lead_gen_form.valueChanges.subscribe((val) => {        
        this.formSubmitFailStatus = false;
      });
      this.lead_gen_form.get('country').valueChanges.subscribe((val) => {
        this.countryValueChangeFn(val);
        this.phoneValueChangeFn();
      });
    }
  }
 
  deepPhoneConfigurations() {
    let res:{formttedPhoneList:any,userCountryDetails: PhoneDataFormat,phoneDataArray:PhoneDataFormat[]} = deepPhoneConfiguration(this.phoneDataArray,this.formttedPhoneList);
      this.formttedPhoneList=res.formttedPhoneList;
      this.userCountryDetails=res.userCountryDetails;
      this.phoneDataArray = res.phoneDataArray;
      this.permanentGeoLocation=Object.assign({},this.userCountryDetails);
      this.lead_gen_form.get('countryCode').setValue(this.userCountryDetails.code);
      this.lead_gen_form.get('dialCode').setValue(this.userCountryDetails.dial_code);
      this.mask = getMaskedCode(res.userCountryDetails);
  }
 
 
  /* ===================================================
  Function for select country click
  =======================================================*/
  selectCountryClick(item) {
    this.lead_gen_form.get('phone').setValue('');
    this.lead_gen_form.get('countryCode').setValue(item.code);
    this.lead_gen_form.get('dialCode').setValue(item.dial_code);
    // this.getAreaCodeOnCountrySelect(item.code);
    this.userCountryDetails.dial_code = item.dial_code;
    this.userCountryDetails.code = item.code;
    this.userCountryDetails.flagUrl = item.flagUrl;
    this.userCountryDetails.ph_length = +item.ph_length + 5;
    this.userCountryDetails.defaultFormat = item.defaultFormat;
    this.userCountryDetails.defaultPhoneNumber = item.defaultPhoneNumber;
    this.flagCodeClickStatus = false;
    this.countrySearch = "";
    this.mask = getMaskedCode(item);
  }
 
  onClickedOutside(e: Event) {
    this.flagCodeClickStatus = false;
    this.countrySearch = "";
  }
 
  /* ===================================================================
    Functions to call all functions in request call
  ====================================================================== */
  reqCallConstructorFn() {
    // Changing subheader A value
    if (this.specialOfferExpandFlag) {
 
    } else {
      if(this.isRequestCallStatus) {
        this.rcycCommonService.changeSubHeaderStatus(true);
        // Call get Metatag function
        this.apiUrlMetatag = '/api/v1/meta/requestcall?_format=json';
        this.rcycCommonService.getMetaTags(this.apiUrlMetatag);        
      }
    }
 
    // Function for Call form token generation
    this.requestCallFormTokenFn();
 
  } 
 
 
 
  /* =======================================================================================================================================
    Function to call all  Request a Call APIs
  ======================================================================================================================================= */
 
  rcycRequestACallContent() {
 
   
    if (this.specialOfferExpandFlag) {
 
    } else {
      if(this.isRequestCallStatus) {
        /****************** Section Metatag********************/
        this.rcycCommonService.getMetaTags(this.apiUrlMetatag);
       
        /****************** Section A********************/
        let apiUrlA = '/api/v1/requestcall/header/a?_format=json';
        this.rcycRequestCallService.requestACallDrupal(apiUrlA).subscribe(
          (response:any) => {
            if (response && response.length) {  
                this.apiResponseA = response[0];
                // Changing subheader A value
                this.rcycCommonService.changeSubHeaderDataArray_A(this.apiResponseA);            
             
            } else {
              // Changing subheader A value
              this.rcycCommonService.changeSubHeaderDataArray_A({});
            }
 
          },
          err => {
            console.log('Section A Request Call Api A error');
            console.log(err);
          }
        );
      }
    }
 
    /****************** Section B********************/
    let apiUrlB = '/api/v1/banners/requestcall/a?_format=json';
    this.rcycRequestCallService.requestACallDrupal(apiUrlB).subscribe(
      (response:any) => {        
          if (response && response.length) {
            this.apiResponseB = response;
          }  
      },
      err => {
        console.log('Section B Request Call Api B error');
        console.log(err);
      }
    );
 
    /****************** Section C********************/
    let apiUrlC = '/api/v1/requestcall/terms?_format=json';
    this.rcycRequestCallService.requestACallDrupal(apiUrlC).subscribe(
      (response:any) => {
        if (response && response.length) {
          this.apiResponseC = response[0];
          let message = this.apiResponseC.field_term2.replace('<p>', "").replace("</p>","");
          let newMessage = `<p> ${message}<span>*</span></p>`;
          this.apiResponseC["field_term2_required"] = newMessage;
        }
      },
      err => {
        console.log('Section C Request Call Api C error');
        console.log(err);
      }
    );
  }
  /* ======================================================================================================================================
    Funtion for creating token for request Call
  * ======================================================================================================================================*/
  requestCallFormTokenFn() {
 
    this.rcycFormTokenService.formTokenService().subscribe(
      (response:any) => {
        if (response && response.code === 200 && response.data.logInfo) {
          this.requestCallFormToken = response.data.logInfo;
        }
      },
      err => {
        console.log('err  formTokenService Request Call');
        console.log(err);
      }
    );
  }
 
  // method to display modal
  shownErrorModal() {
 
    const modalContents = this.rcycFormTokenService.modalService('request-call');
    this.errorModal.modalTitle  = modalContents.title;
    this.errorModal.modalContent  = modalContents.modalContent;
    this.errorModal.routerUrl = modalContents.routerUrl;
    this.errorModal.modalShow();
  }
   /* =========================================================================================================================================
    Function to Submit form in Request Brochure
  ======================================================================================================================================= */
  leadGenSubmitApiCall() {
    this.submitLoading = true;
    let apiUrl_requestCall_Submit = '/secureleadapi/req-call';

    let dial_code:string = this.userCountryDetails.dial_code;
    this.requestCallFormStatus = false;
 

    let phoneNumber;
    if(!this.lead_gen_form.value.phone.replace(/ /g, "")) {
      phoneNumber="";
    } else {
      phoneNumber = dial_code+this.lead_gen_form.value.phone.replace(/ /g, "");
    }
   
    let requestData:any={};
    requestData = {
      // 'country': this.lead_gen_form.value.country ? this.lead_gen_form.value.country : '',
      'area': (this.userArea) ? this.userArea : '',
      'email': this.lead_gen_form.value.email.trim(),
      'firstname': this.lead_gen_form.value.firstname.trim(),
      'lastname': this.lead_gen_form.value.lastname.trim(),
      'phone': phoneNumber,
      'zipcode': this.lead_gen_form.value.zipcode.trim(),
      'emailOpt': this.lead_gen_form.value.isAgreeToReceiveMail,
      'phoneOpt': this.lead_gen_form.value.isAgreeToContact,
      'smsOpt': this.lead_gen_form.value.isAgreeToSms && this.isMobilebutton,
      'comment': this.lead_gen_form.value.comment.trim(),
      'type': 'call',
      'utmSource': this.cookies.get('utm_source'),
      'utmMedium': this.cookies.get('utm_medium'),
      'utmCampaign': this.cookies.get('utm_campaign'),
      'internalSource': this.cookies.get('int_source'),
      'internalCampaign': this.cookies.get('int_campaign'),
      'folId': this.cookies.get('fol_num')
    };
    if(!this.isRequestCallStatus) {
      requestData['comment'] = '';
      requestData['country'] = this.selectCountryCode;    
    } else {
      requestData['comment'] = this.lead_gen_form.value.comment? this.lead_gen_form.value.comment.trim(): '';
      requestData['country'] = this.lead_gen_form.value.country ? this.lead_gen_form.value.country : '';
    }
    if(requestData['country'] == 'US' || requestData['country'] == 'United States') {
      if (this.lead_gen_form.value.phone) {
        requestData['phoneOpt'] = true;
      } else {
        requestData['phoneOpt'] = false;
      }
    }
 
       /****************** Section RequestQuote Submission********************/
    this.rcycRequestCallService.requestCallFormData(apiUrl_requestCall_Submit, requestData, this.requestCallFormToken).subscribe(
      (response:any) => {
        if (response && response.code === 200) {
          if (!this.salesForceImplementation) {
            // Change The value of Flag (salesForceImplementation) in env to execute handling of post LeadGenForm submission to resco - and Discontinue W2L salesforce submit
 
            this.submitLoading = false;
            let leadId = (response.data && response.data.leadId) ? response.data.leadId : '';
            this.formSubmitFailStatus = false;
            this.router.navigate(['/complete'], { queryParams: { success: 'rac','lead_id':leadId } });
            ga('create', 'UA-114428670-1', 'auto');
            ga('require', 'ecommerce', 'ecommerce.js');
            ga('ecommerce:addItem', {
              'id': leadId,
              'name':'rac'
            });
            ga('ecommerce:send');
            ga('send','pageview');
          } else if ( response && ( response.code === 403 || response.code === 401 || response.code === 400 || response.code === 500 ) ) {
 
            this.formSubmitFailStatus = true;
            if (response.status == 407 && response.code === 500) {
 
              this.requestCallFormStatus = true;
              this.formSubmitFailStatus = false;
              this.tokenMessage = this.rcycFormTokenService.tokenErrorMsg;
              this.sessionExpiredMessages=this.drupalErrorMessages.field_form_session_expired;
            }
            this.reqCallConstructorFn();
            this.getUserAreaCode();
          // window.scrollTo(0, 0);
          document.querySelector('.page-title').scrollIntoView();
          }
        }
      }, err => {
 
        console.log(err);
    });
 
    if (this.salesForceImplementation) {      
      // Salesforce Web to Lead - W2L START
 
      const salesForceRequestData: SalesForceFormData = {
        'lead_source' : 'Website',
        'Lead_Type__c' : 'Request a Call',
        'first_name' : this.lead_gen_form.value.firstname.trim(),
        'last_name' : this.lead_gen_form.value.lastname.trim(),
        'phone' : phoneNumber,
        'email' : this.lead_gen_form.value.email.trim(),
        'zip' : this.lead_gen_form.value.zipcode.trim(),
        'area' : (this.userArea) ? this.userArea : '',
        'smsOpt': (this.lead_gen_form.value.isAgreeToSms && this.isMobilebutton) ? 'Yes' : 'No Response',
        'Phone_Opt_In__c': (this.lead_gen_form.value.isAgreeToContact == true) ? 'Yes' : 'No Response',
        'Email_Opt_In__c' : (this.lead_gen_form.value.isAgreeToReceiveMail == true ) ? 'yes' : 'No Response',
        'UTM_Source__c' : this.cookies.get('utm_source'),
        'UTM_Medium__c' : this.cookies.get('utm_medium'),
        'UTM_Campaign__c' : this.cookies.get('utm_campaign'),
        'INT_Source__c' : this.cookies.get('int_source'),
        'INT_Campaign__c' : this.cookies.get('int_campaign'),
      }
      if(!this.isRequestCallStatus) {
        salesForceRequestData['Comments__c'] = '';
        salesForceRequestData['country_code'] = this.selectCountryCode;    
      } else {
        salesForceRequestData['Comments__c'] = this.lead_gen_form.value.comment? this.lead_gen_form.value.comment.trim(): '';
        salesForceRequestData['country_code'] = this.lead_gen_form.value.country ? this.lead_gen_form.value.country : '';
      }
      if(salesForceRequestData['country_code'] == 'US' || salesForceRequestData['country_code'] == 'United States') {
        if (this.lead_gen_form.value.phone) {
          salesForceRequestData['Phone_Opt_In__c'] = 'Yes';
        } else {
          salesForceRequestData['Phone_Opt_In__c'] = 'No Response';
        }
      }
     
      this.rcycHiddenSalesforceFormService.generateFormW2L(salesForceRequestData, 'rac');
 
      // Salesforce Web to Lead - W2L END
    }
   
  }
  /* ==================================================================================================================
    Function to get Area code on Country Select
  =====================================================================================================================*/
  getAreaCodeOnCountrySelect(cntrycode) {
    // console.log(cntrycode, 'ewtwwe');
    if (cntrycode) {
      let apiUrlD = '/api/v1/countryphone/' + cntrycode + '?_format=json';
      this.rcycHomeService.home(apiUrlD).subscribe(
        (response:any=[]) => {
          this.rcycCommonService.userAreaDetails(response);          
          this.getUserAreaCode();
        },
        err => {
          console.log('Error in Getting Area', err);
        }
      );
    }
  }
 
 
  /* ===================================================
     Function for zipcode format
   =======================================================*/
   checkZipcodeFormat(input) {
 
    let countryCode=this.lead_gen_form.get('country').value;
    if(!this.isRequestCallStatus) {
      const countryName=this.lead_gen_form.get('country').value;
      countryCode=this.countryListArray.filter(p => p.name === countryName)[0]?.code;
    }
    if(countryCode&&countryCode.toUpperCase()==="US") {
 
      if(isNaN(input.data)) {
        input.srcElement.value=input.srcElement.value.replace(input.data,'');
      }
      const position=input.target.selectionStart;
      let value=input.srcElement.value;
      value=value.replace(/-/g,'');
      if(value.length>5)  {
        input.srcElement.value=[value.slice(0, 5), '-', value.slice(5)].join('');
      }
      this.lead_gen_form.get('zipcode').setValue(input.srcElement.value);
      input.target.selectionStart=(position>5)?position+1:position;
      input.target.selectionEnd=(position>5)?position+1:position;
    } else if(countryCode&&countryCode.toUpperCase()==="CA") {
 
      if (input.data && input.data == input.data.toLowerCase()){
        input.srcElement.value=input.srcElement.value.replace(input.data,input.data.toUpperCase());
      }
      let value=input.srcElement.value;
      value=value.replace(' ','');
      if(value.length>3)  {
        input.srcElement.value=[value.slice(0, 3), ' ', value.slice(3)].join('');
        this.lead_gen_form.get('zipcode').setValue(input.srcElement.value);
      }
      if(this.lead_gen_form.get('zipcode').value.length>7){
        this.lead_gen_form.get('zipcode').setValue(this.lead_gen_form.get('zipcode').value.substring(0,7));
      }
    }
    else if(countryCode&&countryCode.toUpperCase()==="AF") {
 
      if (input.data && input.data == input.data.toLowerCase()){
        input.srcElement.value=input.srcElement.value.replace(input.data,input.data.toUpperCase());
      }
      let value=input.srcElement.value;
      value=value.replace(' ','');
      if(value.length>4)  {
        input.srcElement.value=[value.slice(0, 4), ' ', value.slice(4)].join('');
        this.lead_gen_form.get('zipcode').setValue(input.srcElement.value);
      }
      if(this.lead_gen_form.get('zipcode').value.length>7){
        this.lead_gen_form.get('zipcode').setValue(this.lead_gen_form.get('zipcode').value.substring(0,7));
      }
    }
    else if(countryCode&&countryCode.toUpperCase()==="PK") {
 
      if (input.data && input.data == input.data.toLowerCase()){
        input.srcElement.value=input.srcElement.value.replace(input.data,input.data.toUpperCase());
      }
      let value=input.srcElement.value;
      value=value.replace(' ','');
      if(value.length>5)  {
        input.srcElement.value=[value.slice(0, 5), ' ', value.slice(5)].join('');
        this.lead_gen_form.get('zipcode').setValue(input.srcElement.value);
      }
      if(this.lead_gen_form.get('zipcode').value.length>7){
        this.lead_gen_form.get('zipcode').setValue(this.lead_gen_form.get('zipcode').value.substring(0,7));
      }
    }
    else if(countryCode&&countryCode.toUpperCase()==="SD") {
 
      if (input.data && input.data == input.data.toLowerCase()){
        input.srcElement.value=input.srcElement.value.replace(input.data,input.data.toUpperCase());
      }
      let value=input.srcElement.value;
      value=value.replace(' ','');
      if(value.length>3)  {
        input.srcElement.value=[value.slice(0, 3), value.slice(3)].join('');
        this.lead_gen_form.get('zipcode').setValue(input.srcElement.value);
      }
      if(this.lead_gen_form.get('zipcode').value.length>7){
        this.lead_gen_form.get('zipcode').setValue(this.lead_gen_form.get('zipcode').value.substring(0,7));
      }
    }
    else if(countryCode&&countryCode.toUpperCase()==="ET") {
 
      if (input.data && input.data == input.data.toLowerCase()){
        input.srcElement.value=input.srcElement.value.replace(input.data,input.data.toUpperCase());
      }
      let value=input.srcElement.value;
      value=value.replace(' ','');
      if(value.length>4)  {
        input.srcElement.value=[value.slice(0, 4), ' ', value.slice(4)].join('');
        this.lead_gen_form.get('zipcode').setValue(input.srcElement.value);
      }
      if(this.lead_gen_form.get('zipcode').value.length>7){
        this.lead_gen_form.get('zipcode').setValue(this.lead_gen_form.get('zipcode').value.substring(0,7));
      }
    }
    else if(countryCode&&countryCode.toUpperCase()==="IR") {
 
      if (input.data && input.data == input.data.toLowerCase()){
        input.srcElement.value=input.srcElement.value.replace(input.data,input.data.toUpperCase());
      }
      let value=input.srcElement.value;
      value=value.replace(' ','');
      if(value.length>5)  {
        input.srcElement.value=[value.slice(0, 5), value.slice(5)].join('');
        this.lead_gen_form.get('zipcode').setValue(input.srcElement.value);
      }
      if(this.lead_gen_form.get('zipcode').value.length>12){
        this.lead_gen_form.get('zipcode').setValue(this.lead_gen_form.get('zipcode').value.substring(0,12));
      }
    }
    else if(countryCode&&countryCode.toUpperCase()==="NG") {
 
      if (input.data && input.data == input.data.toLowerCase()){
        input.srcElement.value=input.srcElement.value.replace(input.data,input.data.toUpperCase());
      }
      let value=input.srcElement.value;
      value=value.replace(' ','');
      if(value.length>3)  {
        input.srcElement.value=[value.slice(0, 3), value.slice(3)].join('');
        this.lead_gen_form.get('zipcode').setValue(input.srcElement.value);
      }
      if(this.lead_gen_form.get('zipcode').value.length>7){
        this.lead_gen_form.get('zipcode').setValue(this.lead_gen_form.get('zipcode').value.substring(0,7));
      }
    }
    else if(countryCode&&countryCode.toUpperCase()==="YE") {
 
      if (input.data && input.data == input.data.toLowerCase()){
        input.srcElement.value=input.srcElement.value.replace(input.data,input.data.toUpperCase());
      }
      let value=input.srcElement.value;
      value=value.replace(' ','');
      if(value.length>3)  {
        input.srcElement.value=[value.slice(0, 3), value.slice(3)].join('');
        this.lead_gen_form.get('zipcode').setValue(input.srcElement.value);
      }
      if(this.lead_gen_form.get('zipcode').value.length>7){
        this.lead_gen_form.get('zipcode').setValue(this.lead_gen_form.get('zipcode').value.substring(0,7));
      }
    }
    else if(countryCode&&countryCode.toUpperCase()==="IQ") {
 
      if (input.data && input.data == input.data.toLowerCase()){
        input.srcElement.value=input.srcElement.value.replace(input.data,input.data.toUpperCase());
      }
      let value=input.srcElement.value;
      value=value.replace(' ','');
      if(value.length>3)  {
        input.srcElement.value=[value.slice(0, 3), value.slice(3)].join('');
        this.lead_gen_form.get('zipcode').setValue(input.srcElement.value);
      }
      if(this.lead_gen_form.get('zipcode').value.length>7){
        this.lead_gen_form.get('zipcode').setValue(this.lead_gen_form.get('zipcode').value.substring(0,7));
      }
    }    
     else{
     
      let value= input.srcElement.value.replace(/  /g, ' ');
      this.lead_gen_form.get('zipcode').setValue(value.replace(/--/g, '-'));
    }
  }
 
  inputIconCloseClick(item) {
    // switch(item) {
    //   case 'firstname':
      this.lead_gen_form.get(item).setValue('');
    //   break;
 
    // }
  }
 
  specialCountryLiClick(item) {
    this.selectCountryClick(item);
    this.lead_gen_form.get('country').setValue(item.name);
    this.specialCountryInputClickStatus=false;
    this.specialCountryInputCloseBtnStatus=false;
    this.isSpecialCountrySelect=true;
    this.countryValueChangeFn(item.name);
  }
  specialCountryInputCloseClick() {
    this.lead_gen_form.get('country').setValue('');
    this.specialCountryInputCloseBtnStatus=false;
    this.countryValueChangeFn(this.lead_gen_form.get('country').value);
  }

  onChange($event) {
      if ($event.target.value === 'AT' || $event.target.value === 'BE' || $event.target.value === 'BG' || $event.target.value ==='HR' || $event.target.value === 'CY' || $event.target.value === 'CZ' || $event.target.value === 'DK' || $event.target.value === 'EE'
        || $event.target.value === 'FI' || $event.target.value === 'FR' || $event.target.value === 'DE' || $event.target.value === 'GR' || $event.target.value === 'HU' || $event.target.value === 'IT' || $event.target.value === 'LV' || $event.target.value === 'LT'
        || $event.target.value === 'LU' || $event.target.value === 'MT' || $event.target.value === 'NL' || $event.target.value === 'PL' || $event.target.value === 'PT' || $event.target.value === 'RO' || $event.target.value === 'SK' || $event.target.value === 'SI'
        || $event.target.value === 'ES' || $event.target.value === 'SE' || $event.target.value === 'GB' || $event.target.value === 'CH' || $event.target.value === 'BH' || $event.target.value === 'IL' || $event.target.value === 'QA' || $event.target.value === 'TR'
        || $event.target.value === 'KE' || $event.target.value === 'MU' || $event.target.value === 'NG' || $event.target.value === 'ZA' || $event.target.value === 'UG' || $event.target.value === 'JP' || $event.target.value === 'KR' || $event.target.value === 'NZ'
        || $event.target.value === 'AR' || $event.target.value === 'BR' || $event.target.value === 'UY' || $event.target.value === 'CA' || $event.target.value === 'IE'
      ) {
        this.showPhoneopt = true;
      } else {
        this.showPhoneopt = false;
      }
      this.countryValueChangeFn($event.target.value)
  }
 
  countryValueChangeFn(val) {
 
      if(!this.isRequestCallStatus) {      
        if (val) {
          val=this.countryListArray.filter(p => p.name === val)[0].code;
          this.selectCountryCode=val;
        }            
      }
      this.zipcodeReqStatusDynamic = false;
      let zipCodeValidation: any;
      this.zipcodeMaxLength=10;
      if(val)
      {
        this.getAreaCodeOnCountrySelect(val);
        this.userCountryDetails=Object.assign({},this.formttedPhoneList[val]);
        this.userCountryDetails.ph_length = Number(this.formttedPhoneList[val].ph_length) + 5
        this.lead_gen_form.get('phone').setValue('');
        this.lead_gen_form.get('countryCode').setValue(val);
        this.lead_gen_form.get('dialCode').setValue(this.formttedPhoneList[val].dial_code);
      }
      else{
        this.getAreaCodeOnCountrySelect(this.permanentGeoLocation.code);
        this.userCountryDetails = Object.assign({},this.permanentGeoLocation);
        this.userCountryDetails.ph_length = Number(this.permanentGeoLocation.ph_length);
      }
      if (val === 'US') {
        zipCodeValidation = /^[0-9]{5}(?:-[0-9]{4})?$/;
        this.lead_gen_form.get('isAgreeToContact').setValue(false);
        this.lead_gen_form.get('isAgreeToReceiveMail').setValue(true);
        this.zipcodeReqStatusDynamic = true;
        this.showPhoneopt = false;
        this.showTermUS = true;
        this.phNumReqStatusDynamic = true;
        this.lead_gen_form.get('phone').setValidators([ValidatePhoneNumber,Validators.required]);
        this.lead_gen_form.get('phone').updateValueAndValidity();
      } else {
        this.showTermUS = false;
        this.phNumReqStatusDynamic = false;
        this.lead_gen_form.get('phone').setValidators([ValidatePhoneNumber]);
        this.lead_gen_form.get('phone').updateValueAndValidity();
        if (val === 'CA') {
          this.zipcodeMaxLength=7;
          zipCodeValidation = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
          this.zipcodeReqStatusDynamic = true;          
        }
        else if  (val === 'AF'){
          this.zipcodeMaxLength=5;
          zipCodeValidation = /^(1[0-9]|2[0-9]|3[0-9]|4[0-3])(0[1-9]|[1-9][0-9])$/;
          this.zipcodeReqStatusDynamic = true;
        }
        else if  (val === 'PK'){
          this.zipcodeMaxLength=5;
          zipCodeValidation = /^([1-9][0-9]|[1-8][0-9]|9[0-8])([0-9]{3})$/;
          this.zipcodeReqStatusDynamic = true;
        }
        else if  (val === 'SD'){
          this.zipcodeMaxLength=5;
          zipCodeValidation = /^([1-9]{2})([0-9]{1})([0-9]{2})$/;
          this.zipcodeReqStatusDynamic = true;
        }
        else if  (val === 'ET'){
          this.zipcodeMaxLength=4;
           zipCodeValidation = /^([1-9]{1})([0-9]{1})([0-9]{2})$/;
          this.zipcodeReqStatusDynamic = true;
        }
        else if  (val === 'IR'){
          this.zipcodeMaxLength=10;
         zipCodeValidation = /\b(?!(\d)\1{3})[13-9]{4}[1346-9][0-9]{5}\b/;
          this.zipcodeReqStatusDynamic = true;
        }
        else if  (val === 'NG'){
          this.zipcodeMaxLength=5;
          zipCodeValidation = /^([1-9]{2})([0-9]{1})([0-9]{2})$/;
          this.zipcodeReqStatusDynamic = true;
        }
        else if  (val === 'YE'){
          this.zipcodeMaxLength=5;
          zipCodeValidation = /^([1-9]{2})([0-9]{1})([0-9]{2})$/;
          this.zipcodeReqStatusDynamic = true;
        }
        else if  (val === 'IQ'){
          this.zipcodeMaxLength=5;
          zipCodeValidation = /^([1-9]{2})([0-9]{1})([0-9]{2})$/;
          this.zipcodeReqStatusDynamic = true;
        }
        this.lead_gen_form.get('isAgreeToContact').setValue(false);
        this.lead_gen_form.get('isAgreeToReceiveMail').setValue(false);
      }  
      if (zipCodeValidation) {
        this.lead_gen_form.get('zipcode').setValidators([Validators.required, Validators.pattern(zipCodeValidation)]);
        this.lead_gen_form.get('zipcode').updateValueAndValidity();
      } else {
        this.lead_gen_form.get('zipcode').setValidators(null);
        this.lead_gen_form.get('zipcode').updateValueAndValidity();
      }
  }
 
  // Lead Gen Btn Submit
  leadGenSubmit() {
    this.specialOfferFormErrorMsg='';
    let honeyField = <HTMLInputElement> document.getElementById('thirdNameId');
    if(this.isRequestCallStatus && !honeyField.value) {
      this.leadGenSubmitApiCall();
    } else {      
      if(
        this.lead_gen_form.controls.firstname.invalid||
        this.lead_gen_form.controls.lastname.invalid||
        this.lead_gen_form.controls.country.invalid||
        this.lead_gen_form.controls.zipcode.invalid||
        this.lead_gen_form.controls.email.invalid||
        (this.lead_gen_form.controls.phone.errors && this.lead_gen_form.controls.phone.errors.required) ||
        (
          this.lead_gen_form.controls.phone&&this.lead_gen_form.controls.phone.errors&&
          this.lead_gen_form.controls.phone.errors.phoneIncorrect!=false
        )||
        !this.isSpecialCountrySelect||
        !this.userArea ||
        this.showPhoneOptRequiredText
        ) {          
          this.specialOfferFormErrorMsg='Please fill all mandatory fields';
          document.querySelector('.page-title').scrollIntoView();
      } else {        
        this.leadGenSubmitApiCall();
      }
    }
   
  }
  getMyProfileDetails() {
    const param = {
    };
    const apiUrl = '/secureapi/getMyProfileDetails';
    this.rcycInternalApisService.postReservationFormWithAuth(apiUrl, param).subscribe(
      res  => {
        const data: any = res;
        this.lead_gen_form.patchValue({
          'firstname' : data.data.forename,
          'lastname' : data.data.surname,
          'country': data.data.country,
          'zipcode' : data.data.postalCode,
          'email' : data.data.email1
        });
        if (data.data.phone1) {
          let phoneWOdialcode=data.data.phone1.replace(this.userCountryDetails.dial_code, '');
          this.lead_gen_form.patchValue({ 'phone': phoneWOdialcode });    
 
          if(this.lead_gen_form.controls.phone.errors.getPhoneCountryCode) {            
            let getPhoneCountryCode=this.lead_gen_form.controls.phone.errors.getPhoneCountryCode;
            this.lead_gen_form.get('countryCode').setValue(getPhoneCountryCode);
            this.lead_gen_form.get('dialCode').setValue(this.formttedPhoneList[getPhoneCountryCode].dial_code);
            this.userCountryDetails.dial_code = this.formttedPhoneList[getPhoneCountryCode].dial_code;
            this.userCountryDetails.code = getPhoneCountryCode;
            this.userCountryDetails.flagUrl= this.formttedPhoneList[getPhoneCountryCode].flagUrl;
            this.userCountryDetails.defaultFormat = this.formttedPhoneList[getPhoneCountryCode].defaultFormat;
            this.userCountryDetails.defaultPhoneNumber = this.formttedPhoneList[getPhoneCountryCode].defaultPhoneNumber;
            let phoneWOdialcodeSecond=data.data.phone1.replace(this.formttedPhoneList[getPhoneCountryCode].dial_code, '');
            this.lead_gen_form.patchValue({ 'phone': phoneWOdialcodeSecond });  
          }
        }
      },
      err=>{
        console.log(err)
      })
    }
    phoneValueChangeFn() {
      if (this.lead_gen_form.value.phone) {
        if (this.lead_gen_form.value.isAgreeToContact = false) {
          this.showPhoneOptRequiredText = false;
        }
      } else {
        this.showPhoneOptRequiredText = false;
      }
    }
    phoneOptValueChanged() {
      if (this.lead_gen_form.value.isAgreeToContact) {
        this.lead_gen_form.get('phone').setValidators([Validators.required,ValidatePhoneNumber]);
        this.phNumReqStatusDynamic = true;
        this.showPhoneOptRequiredText = false;
      }
      else {
        this.lead_gen_form.get('phone').setValidators([ValidatePhoneNumber]);
        this.phNumReqStatusDynamic = false;
        if (this.lead_gen_form.value.phone) {
          this.showPhoneOptRequiredText = true;
        }
      }
      this.lead_gen_form.get('phone').updateValueAndValidity();
    }
 
    getDrupalErrorMessages() {
      const apiUrl = "/api/v1/errormessages?_format=json";
      this.rcycRequestCallService.requestACallDrupal(apiUrl).subscribe(
        (response:any) => {
          if (response && response.length) {  
            this.drupalErrorMessages = response[0];
          }
        },
        err => {
          console.log('Drupal Call Api error');
          console.log(err);
        }
      );
    }
 
}