<div class="bg-white">
  <div class="container">
    <app-rcyc-section-content *ngIf="data?.topContent" class="lg:flex items-center" variant="dark" heading="h2" [pageName]="pageName"
      [data]="data?.topContent" [ngClass]="{'xl:w-1/2': data?.topContent?.description && pageName==='home'}"></app-rcyc-section-content>
  </div>

  <div class="relative overflow-hidden lg:overflow-visible" [ngClass]="{'mt-6 lg:mt-[48px]': data?.topContent?.description && pageName==='home'}">
    <ngx-slick-carousel [id]="carouselId" *ngIf="data?.items" [class]="'carousel-' + variant"
      #slickModal="slick-carousel" [config]="sliderConfig[variant]" (init)="getSliderPosition($event)"
      (resize)="getSliderPosition($event)" (afterChange)="getSliderPosition($event)">
      <div ngxSlickItem *ngFor="let item of data?.items let j=index" class="slide px-[12px] lg:px-[24px]" [ngClass]="{
              'odd:mt-16 even:mt-1': variant==='editorial',
              'image-slider-fix': variant==='barSmall',
           }">
        <!-- Bar-->
        <div *ngIf="variant==='barSmall'"
          class="relative overflow-hidden min-h-[414px] sm:min-h-[414px] p-8 lg:p-16 transition-all duration-500 flex items-end inner-slide lg:aspect-video"
          [ngClass]="pageName === 'voyage' ? 'small-min-h': ''">
          <img *ngIf="item?.field_tile_image" [src]="item?.field_tile_image" [title]="item?.field_tile_title" alt=""
            class="background-image fit-image w-auto">
          <div class="background-image"
            style="background: linear-gradient(54.67deg, rgba(16, 32, 58, 0.75) 20.01%, rgba(16, 32, 58, 0) 56.82%);">
          </div>
          <app-rcyc-section-content variant="light" sliderVariant="barSmall" heading="h3"
            class="z-10 relative transition-all duration-1000 inner-content lg:max-w-[50%]"
            [data]="getSlideData(item, j)"></app-rcyc-section-content>
        </div>
        <!-- Full Image Background Tile -->
        <app-rcyc-full-img-tile *ngIf="variant ==='full_image'" [data]="item" [portCode]="data?.portCode" [pageName] = "pageName"></app-rcyc-full-img-tile>

        <!-- Featured -->
        <app-rcyc-card-featured [additionalCostInfo]="additionalCostInfo" *ngIf="variant==='featured'" [pageName]="pageName"
          variant="featured" [data]="item" parent="slider" [currencyCode]="currencyCode"></app-rcyc-card-featured>

        <!-- Experience-->
        <div *ngIf="variant==='experience'"
          class="transition-all duration-500 flex flex-col inner-slide lg:max-w-[276px] cursor-pointer"
          (click)="onSlideClick(item)">
          <app-rcyc-card-experience *ngIf="item" [variant]="'editorial'" [isMobile]="isMobile"
            [data]="experienceData(item, j)"></app-rcyc-card-experience>
        </div>

        <!-- Editorial Hub-->
        <div *ngIf="variant==='hub'"
          class="transition-all duration-500 flex flex-col inner-slide lg:max-w-[276px] cursor-pointer lg:pr-[18px] pr-[24px] "
          (click)="onSlideClick(item)">
        <app-rcyc-card-experience *ngIf="item" [variant]="'hub'"  [isMobile]="isMobile" 
          [data]="hubData(item, j)"></app-rcyc-card-experience>
        </div>

        <!-- Editorial -->
        <div *ngIf="variant==='editorial'"
          class="transition-all duration-500 flex flex-col inner-slide lg:max-w-[276px] cursor-pointer"
          (click)="onSlideClick(item)">
          <app-rcyc-card-experience *ngIf="item" [variant]="variant" [topcontent]="data?.topContent?.title"
            [data]="getEditorialData(item, j)"
            (clearCompareValueEmitter)="clearCompareValue($event)"></app-rcyc-card-experience>
        </div>

        <!-- Card Slider-->
        <app-rcyc-offer-card *ngIf="variant==='cardSlider'" variant="cardSlider" [pageName]="pageName" [data]="item" >
        </app-rcyc-offer-card>
      </div>
    </ngx-slick-carousel>

    <!-- Workaround for broken focusOnSelect when more than one slide in view -->
    <!-- <div class="absolute top-0 left-0 h-full w-[70px] cursor-pointer" (click)="slickModal.slickPrev()"
      title="Previous slide"></div>
    <div class="absolute top-0 right-0 h-full w-[70px] cursor-pointer" (click)="slickModal.slickNext()"
      title="Next slide"></div> -->
      <div [ngClass]="{'w-full container max-w-[552px] slider-nav-lg mt-8' : showSliderControl, 'display-none': !showSliderControl}">
      <div [ngClass]="{'display-none': !isMobile && data?.topContent?.ShowSlider}">
        <div class="flex justify-center gap-3">
          <div class="cursor-pointer flex leading-8" (click)="slickModal.slickPrev()"><img
              src="assets/img/arrow_left_small.svg" alt=""> PREVIOUS</div>
          <div [class]="carouselId + '-dots'" class="relative flex items-center grow lg:max-w-[375px] justify-center">
          </div>
          <div class="cursor-pointer flex leading-8" (click)="slickModal.slickNext()">NEXT <img
              src="assets/img/arrow_right_small.svg" alt=""></div>
        </div>
        <div class="flex justify-center mt-4" *ngIf="showSliderControl">
          {{page}} / {{nrOfPages}}
        </div>
      </div>
    </div>
  </div>
</div>