import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { first, forkJoin, of, switchMap } from 'rxjs';
import { RcycHomeService } from '../rcyc-home/rcyc-home.service';
import { RcycCommonService } from '../../rcyc-services/rcyc-common/rcyc-common.service';
import { AppConfig } from '../../rcyc-config/config';
import { RcycInternalApisService } from '../../rcyc-services/rcyc-internal-apis/rcyc-internal-apis.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { RcycHiddenSalesforceFormService } from '../../rcyc-services/rcyc-salesforce/rcyc-hidden-salesforce-form.service'
import { SalesForceFormData } from '../../rcyc-services/rcyc-salesforce/salesforce-form-data.interface';
import { RcycFormTokenService } from '../../rcyc-services/rcyc-form-token/rcyc-form-token.service';
import { Router } from '@angular/router';
import { RcycRequestCallService } from '../rcyc-request-call/rcyc-request-call.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { SharedServiceService } from '../../rcyc-services/rcyc-shared/shared-service.service';
import { RcycNewFindACruiseService } from '../rcyc-new-find-a-cruise/rcyc-new-find-a-cruise.service';

declare let ga: Function;

@Component({
  selector: 'app-rcyc-offers-programs-landing',
  templateUrl: './rcyc-offers-programs-landing.component.html',
  styleUrls: ['./rcyc-offers-programs-landing.component.css']
})
export class RcycOffersProgramsLandingComponent implements OnInit {
  IMG_BASE_URL: any = AppConfig.IMG_BASE_URL;

  salesForceImplementation: boolean = AppConfig.salesForceImplementation;
  apiResponseB: any[];
  apiResponseC: any[];
  apiResponseD: any[];
  apiResponseE: any;
  apiResponseF: any[];
  countryListArray: any;
  UserDetails: any;
  leadGenFform: any;
  termsData: any;
  showTermUS: boolean = true;
  submitLoading: boolean = false;
  submitSuccessful: boolean = false;
  showPhoneopt = false;
  apiUrl_eNewsLetterSignup: any;
  selectedCountryCode: any;
  countryErr = false;
  apiUrlF: any;
  signUpStatus = false;
  apiResponseFTemp: any = [];
  eNewsletterSignupFormToken: any;
  eNewsletterSignupFormTokenTemp: any;
  sessionExpiredMessages: any;
  drupalErrorMessages: any;
  tokenMessage: any;
  formSubmitFailStatus = false;
  offerFormStatus = false;
  eNewsletterSignupFormResponse: any;
  userArea = '';
  userAreaDetails: any;
  apiResponseG: any;
  isLargeScreen: boolean;
  viewValue: string;
  isSmallScreen: boolean;
  apiResponseH: any;
  apiResponseI: any;
  infoCommonMsg: any;
  additionalCostInfo: string = null;
  currencyCode: string = null;

  constructor(
    private rcycHomeService: RcycHomeService,
    private rcycCommonService: RcycCommonService,
    private http: HttpClient,
    private rcycInternalApisService: RcycInternalApisService,
    private formBuilder: UntypedFormBuilder,
    private cookies: CookieService,
    private rcycHiddenSalesforceFormService: RcycHiddenSalesforceFormService,
    private rcycFormTokenService: RcycFormTokenService,
    private rcycRequestCallService: RcycRequestCallService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private sharedServiceService: SharedServiceService,
    private rcycFindACruiseService: RcycNewFindACruiseService,
  ) { }


  ngOnInit() {
    this.breakpointObserver.observe([Breakpoints.Medium, Breakpoints.Large])
      .subscribe(result => {
        this.isLargeScreen = result.matches;
        if (this.isLargeScreen)
          this.viewValue = 'desktop';

      });
    this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small])
      .subscribe(result => {
        this.isSmallScreen = result.matches;
        if (this.isSmallScreen)
          this.viewValue = 'mobile';

      });

    this.apiResponseD = [];
    this.leadGenFform = this.formBuilder.group({
      firstname: ['', [Validators.required, Validators.pattern('[a-zA-ZÀ-ÿ-.\'_ ]*')]],
      lastname: ['', [Validators.required, Validators.pattern('[a-zA-ZÀ-ÿ-.\'_ ]*')]],
      email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-.]{2,})+$')]],
      country: ['', Validators.required],
      isAgreeToReceiveMail: [false],
    });
    // Subscriber to get User Area
    this.rcycCommonService.userAreaCode.subscribe((userAreaDetails) => {
      if (userAreaDetails.length) {
        this.userAreaDetails = userAreaDetails[0];
        this.userArea = this.userAreaDetails.field_area_code;
      }
    });

    const cruiseData = this.rcycCommonService.getSessionData('findCruise');
    this.currencyCode = cruiseData?.data?.currencyCode;

    this.getUserDetails();
    this.getContent();
    this.rcycSpecialOffersContent();
    this.image();
    this.getSignUpFormContent();
    this.getAvailableProgramsData();
    this.getInfoCommonMsg();
  }

  image() {
    let apiUrlB = '/api/v1/banners/offersandprograms/a?_format=json';
    this.rcycRequestCallService.requestACallDrupal(apiUrlB).subscribe(
      (response: any) => {
        if (response && response.length) {
          this.apiResponseG = response[0];
        }
      },
      err => {
        console.log('Section B Request Call Api B error');
        console.log(err);
      }
    );
  }

  getSignUpFormContent() {
    const apiUrlH = '/api/v1/offersandprograms/content/b?_format=json';
    this.rcycHomeService.home(apiUrlH).subscribe(
      response => {
        const apiResponseHTemp: any = response;
        if (apiResponseHTemp && apiResponseHTemp.length > 0) {
          const res = Object.keys(response).map(function (key) {
            return response[key];

          });
          if (res.length) {
            this.apiResponseH = res[0];
          }
        }
      },
      err => { }

    );
  }

  getAvailableProgramsData() {
    const apiUrlI = '/api/v1/offersandprograms/content/c?_format=json';
    this.rcycHomeService.home(apiUrlI).subscribe(
      response => {
        const apiResponseITemp: any = response;
        if (apiResponseITemp && apiResponseITemp.length > 0) {
          const res = Object.keys(response).map(function (key) {
            return response[key];

          });
          if (res.length) {
            this.apiResponseI = res[0];
          }
        }
      },
      err => { }

    );
  }

  getUserDetails() {
    const tempUserDetails = this.rcycCommonService.getData('UserDetails');
    if (tempUserDetails) {
      this.UserDetails = tempUserDetails;
    } else {
      this.rcycCommonService.getUser_Details().subscribe(
        response => {
          this.UserDetails = response;
          this.rcycCommonService.setData('UserDetails', this.UserDetails);
        }
      );
    }
  }

  /* =====================================================
       Click Function countryselect -Select country name from country region
     ======================================================== */
  countryselect() {
    const countryName = this.leadGenFform.value.country;
    if (countryName && countryName !== '0') {
      this.selectedCountryCode = this.countryListArray.filter(p => p.name === countryName)[0].code;
      this.countryErr = false;
    } else {
      this.countryErr = true;
    }
    if (this.selectedCountryCode) {
      this.getAreaCodeOnCountrySelect(this.selectedCountryCode);
    }
  }

  /* ==================================================================================================================
  Function to get Area code on Country Select
  =====================================================================================================================*/
  getAreaCodeOnCountrySelect(cntrycode) {
    if (cntrycode) {
      this.apiUrlF = '/api/v1/countryphone/' + cntrycode + '?_format=json';
      this.rcycHomeService.home(this.apiUrlF).subscribe(
        response => {
          this.apiResponseFTemp = response;
          console.log(response, 'response');
          this.rcycCommonService.userAreaDetails(response);
        },
        err => {
          console.log('Error in Getting Area', err);
        }
      );
    }
  }

  populateSignUp() {
    this.signUpStatus = !this.signUpStatus;
  }


  getContent() {
    // Get country list array 
    this.http.get('./assets/select_country.json').subscribe((data: any) => {
      this.countryListArray = data;
    });

    const apiUrlC = '/api/v1/requestquotegeneric/terms?_format=json';
    this.rcycInternalApisService.getDrupal(apiUrlC).subscribe(
      (res: any = []) => {
        if (res.length) {
          this.termsData = res[0];
          let message = this.termsData.field_term2.replace('<p>', "").replace("</p>", "");
          let newMessage = `<p> ${message}<span>*</span></p>`;
          this.termsData["field_term2_required"] = newMessage;
        }
      },
      err => {
        console.log('Section C Request Quote Api error');
        console.log(err);
      }
    );

    /******************Get Thumbnail images if not in cache********************/
    let drupalImageArray: any = this.rcycCommonService.getSessionData('drupalImageArray');
    if (drupalImageArray) {
      this.getFeaturedVoyageData();
    } else {
      const Api_Url = '/api/v1/voyages/thumbnails?_format=json';
      this.rcycFindACruiseService.getCruiseImageDrupal(Api_Url).subscribe(
        (response: any) => {
          this.rcycCommonService.setSessionData('drupalImageArray', response);
          this.getFeaturedVoyageData();
        },
        (err) => {
          console.log('Error on Getting Cruise Images');
          console.log(err);
        },
      );
    }
  }

  /******************Get featured voyage slider data********************/
  getFeaturedVoyageData() {
    const apiUrlD = '/api/v1/featured-voyages/home/d?_format=json';
    this.rcycHomeService.home(apiUrlD).subscribe(
      response => {
        const apiResponseDTemp: any = response;
        if (apiResponseDTemp && apiResponseDTemp.length) {
          apiResponseDTemp.map((item, i) => {
            this.rcycHomeService.get_voyage(item.field_com_voyage_id, this.UserDetails?.country_code).subscribe(response => {
              let data = { ...response['data'], ...apiResponseDTemp[i], slider: [] };
              this.rcycCommonService.getSessionData('drupalImageArray')?.map((image, j) => {
                if (item.field_com_voyage_id === image.field_voyages) {
                  data.slider[0] = [this.rcycCommonService.getSessionData('drupalImageArray')[j].field_voyage_thumbnail]
                }
              })
              this.rcycCommonService.drupalApisGet('/api/v1/voyages/' + item.field_com_voyage_id + '/tab/itineraryoverview?_format=json').subscribe(
                response => {
                  const res = Object.keys(response).map(function (key) {
                    return response[key];
                  });
                  if (res.length) {
                    data.slider[1] = res[0].field_voyage_image;
                  }
                },
                err => {
                  console.log(err);
                },
              );
              // Get data for each voyage
              this.rcycCommonService.drupalApisGet('/api/v1/voyage-card-slider/' + data.field_com_voyage_id + '?_format=json').subscribe(
                response => {
                  const res = Object.keys(response).map(function (key) {
                    return response[key];
                  });
                  if (res.length) {
                    data.slider = [...data.slider, ...res[0].field_com_images.split(', ')];
                    data.highlight = res[0].field_com_highlight_text
                  }
                },
                err => { },
              );
              this.apiResponseD.push(data);
            })
          });
        }
      },
      err => { },
    );
  }

  rcycSpecialOffersContent() {
    /****************** Section Metatag********************/
    const apiUrlMetatag = '/api/v1/meta/offersandprograms?_format=json';
    this.rcycCommonService.getMetaTags(apiUrlMetatag);

    /****************** Section D********************/
    const apiUrlD = '/api/v1/offers?_format=json';
    this.rcycInternalApisService.getDrupal(apiUrlD).subscribe(
      response => {
        const apiResponseBTemp: any = response;
        if (apiResponseBTemp && apiResponseBTemp.length) {
          const resArray = [];
          for (const index of Object.keys(apiResponseBTemp)) {
            const factor = apiResponseBTemp[index];
            if (factor.field_image) {
              factor.field_image = this.IMG_BASE_URL + factor.field_image;
              const imgTitle = this.rcycCommonService.imageNameAltSplit(factor.field_image);
              factor.altTextSOImg = factor.title + ' ' + imgTitle;
              factor.titleTextSOImg = factor.title + ' ' + imgTitle;
            } else {
              factor.altTextSOImg = factor.title;
              factor.titleTextSOImg = factor.title;
            }
            const offerkey = factor.field_url_key.toLowerCase();
            // factor.offerUrl_new = '/offer-' + offerkey + '-program';
            factor.offerUrl_new = '/' + offerkey;
            factor.field_summary = factor?.field_summery;
            let voyageIdArray = [];
            if (factor.field_voyages) {
              voyageIdArray = factor.field_voyages.split('###');
              factor.voyageCount = voyageIdArray.length;
            }
            resArray.push(factor);
            if (parseInt(index, 10) + 1 === apiResponseBTemp.length) {
              this.apiResponseB = resArray;
            }
          }
        }
      },
      err => {
        console.log('special Offers D err');
        console.log(err);
      }
    );

    /****************** Section E********************/
    const apiUrlE = '/api/v1/offersandprograms/content/e?_format=json';
    this.rcycInternalApisService.getDrupal(apiUrlE).subscribe(
      response => {
        const apiResponseETemp: any = response;
        if (apiResponseETemp && apiResponseETemp.length) {
          this.apiResponseE = apiResponseETemp[0];
        }
      },
      err => {
        console.log('special Offers E err');
        console.log(err);
      }
    );
  }

  onChange($event) {
    if ($event.target.value === 'AT' || $event.target.value === 'BE' || $event.target.value === 'BG' || $event.target.value === 'HR' || $event.target.value === 'CY' || $event.target.value === 'CZ' || $event.target.value === 'DK' || $event.target.value === 'EE'
      || $event.target.value === 'FI' || $event.target.value === 'FR' || $event.target.value === 'DE' || $event.target.value === 'GR' || $event.target.value === 'HU' || $event.target.value === 'IT' || $event.target.value === 'LV' || $event.target.value === 'LT'
      || $event.target.value === 'LU' || $event.target.value === 'MT' || $event.target.value === 'NL' || $event.target.value === 'PL' || $event.target.value === 'PT' || $event.target.value === 'RO' || $event.target.value === 'SK' || $event.target.value === 'SI'
      || $event.target.value === 'ES' || $event.target.value === 'SE' || $event.target.value === 'GB' || $event.target.value === 'CH' || $event.target.value === 'BH' || $event.target.value === 'IL' || $event.target.value === 'QA' || $event.target.value === 'TR'
      || $event.target.value === 'KE' || $event.target.value === 'MU' || $event.target.value === 'NG' || $event.target.value === 'ZA' || $event.target.value === 'UG' || $event.target.value === 'JP' || $event.target.value === 'KR' || $event.target.value === 'NZ'
      || $event.target.value === 'AR' || $event.target.value === 'BR' || $event.target.value === 'UY' || $event.target.value === 'CA' || $event.target.value === 'IE'
    ) {
      this.showPhoneopt = true;
    } else {
      this.showPhoneopt = false;
    }
  }
  eNewsletterSignupSubmit() {
    this.offerFormStatus = false;
    this.eNewsletterSignupSubmitApiCall();
  }
  eNewsletterSignupSubmitApiCall() {
    this.submitLoading = true;
    this.apiUrl_eNewsLetterSignup = '/secureleadapi/e-newsletter';
    /****************** Section Ebrochure Submission********************/
    const data = {
      'firstName': this.leadGenFform.value.firstname,
      'lastName': this.leadGenFform.value.lastname,
      'country': this.selectedCountryCode,
      'email': this.leadGenFform.value.email,
      'area': this.userArea,
      'utmSource': this.cookies.get('utm_source'),
      'utmMedium': this.cookies.get('utm_medium'),
      'utmCampaign': this.cookies.get('utm_campaign'),
      'internalSource': this.cookies.get('int_source'),
      'internalCampaign': this.cookies.get('int_campaign'),
      'folId': this.cookies.get('fol_num')
    };
    this.rcycInternalApisService.postSecureEbrochureForm(this.apiUrl_eNewsLetterSignup, data, this.eNewsletterSignupFormToken).subscribe(
      response => {
        if (!this.salesForceImplementation) {
          this.submitLoading = false;
          this.eNewsletterSignupFormResponse = response;
          const res = this.eNewsletterSignupFormResponse;

          if (res && res.code === 200) {
            let leadId = (res.data && res.data.leadId) ? res.data.leadId : '';
            this.router.navigate(['/complete'], { queryParams: { success: 'sosignup', 'lead_id': leadId } });
            ga('create', 'UA-114428670-1', 'auto');
            ga('require', 'ecommerce', 'ecommerce.js');
            ga('ecommerce:addItem', {
              'id': leadId,
              'name': 'sosignup'
            });
            ga('ecommerce:send');
            ga('send', 'pageview')
          } else if (res && (res.code === 403 || res.code === 401 || res.code === 400 || res.code === 500)) {

            this.formSubmitFailStatus = true;
            if (res && res.status == 407) {

              this.offerFormStatus = true;
              this.formSubmitFailStatus = false;
              this.tokenMessage = this.rcycFormTokenService.tokenErrorMsg;
              this.sessionExpiredMessages = this.drupalErrorMessages.field_form_session_expired;
            }
            this.eNewsletterSignupFormTokenFn();
            window.scrollTo(0, 0);
          }
        }
      }, err => {
        console.log(err);
        // this.succmsg = false;
      }
    );

    if (this.salesForceImplementation) {
      // Salesforce Web to Lead - W2L START

      const salesForceRequestData: SalesForceFormData = {
        'lead_source': 'Website',
        'Lead_Type__c': 'Special Offers Sign-Up',
        'first_name': this.leadGenFform.value.firstname,
        'last_name': this.leadGenFform.value.lastname,
        'country_code': this.selectedCountryCode,
        'email': this.leadGenFform.value.email,
        'area': (this.userArea) ? this.userArea : '',
        'UTM_Source__c': this.cookies.get('utm_source'),
        'UTM_Medium__c': this.cookies.get('utm_medium'),
        'UTM_Campaign__c': this.cookies.get('utm_campaign'),
        'INT_Source__c': this.cookies.get('int_source'),
        'INT_Campaign__c': this.cookies.get('int_campaign'),
      }

      this.rcycHiddenSalesforceFormService.generateFormW2L(salesForceRequestData, 'sosignup');

      // Salesforce Web to Lead - W2L END
    }
  }

  /* ========================================================
   Funtion for creating token for e-newsletter signup
  * ========================================================*/
  eNewsletterSignupFormTokenFn() {
    this.rcycFormTokenService.formTokenService().subscribe(
      response => {

        this.eNewsletterSignupFormTokenTemp = response;
        const res = this.eNewsletterSignupFormTokenTemp;

        if (res && res.code === 200 && res.data.logInfo) {

          this.eNewsletterSignupFormToken = res.data.logInfo;
        }
      },
      err => {

        console.log('err  formTokenService E-BROCHURE');
        console.log(err);
      }
    );
  }

  getDrupalErrorMessages() {
    const apiUrl = "/api/v1/errormessages?_format=json";
    this.rcycInternalApisService.getDrupal(apiUrl).subscribe(
      (response: any) => {
        if (response && response.length) {
          this.drupalErrorMessages = response[0];
        }
      },
    );
  }

  // Function to Clear Voyage Compare Data
  clearCompareValue(url: string) {
    this.rcycCommonService.removeData('findACruiseSearchCriteria');
    this.rcycCommonService.removeData('voyageCompareDetails');
    this.rcycCommonService.removeData('compareButtonPressed');
    this.router.navigate(['/' + url]);
  }

  /**
  * Get common info messages from drupal
  */
  getInfoCommonMsg() {
    this.rcycCommonService.currentInfoMessageSource$.pipe(
      switchMap((value) => value ? of(value) : this.sharedServiceService.getInfoCommonMsg()),
      first()
    ).subscribe((response) => {
      this.infoCommonMsg = response;
      this.rcycCommonService.setInfoMessageSource(response);
      this.setAdditionalCostInfo();
    });
  }

  /**
 * Set the additional cost info based on currency code
 */
  setAdditionalCostInfo() {
    const currencyFieldMap = {
      USD: 'field_usd_additional_cost_info',
      USM: 'field_usm_additional_cost_info',
      EUR: 'field_eur_additional_cost_info',
      GBP: 'field_gbp_additional_cost_info',
    };
    this.additionalCostInfo = this.infoCommonMsg?.[currencyFieldMap[this.currencyCode]];
  }
}
