
import {
  Component, OnInit, Renderer2, ViewEncapsulation,
  ChangeDetectorRef, ViewChild, AfterViewInit, ElementRef
} from '@angular/core';
import { RcycCommonService } from '../../rcyc-services/rcyc-common/rcyc-common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { RcycSpecialOffersLandingService } from './rcyc-special-offers-landing.service';
import { AppConfig } from '../../rcyc-config/config';
import { RcycReqcallModalComponent } from '../../rcyc-modals/rcyc-reqcall-modal/rcyc-reqcall-modal.component';
// import { NgxSmartModalService } from 'ngx-smart-modal';
import { DomSanitizer } from '@angular/platform-browser';
import { RcycGlobalMessagesService } from '../../rcyc-services/rcyc-global-messages/rcyc-global-messages.service';
import { RcycInternalApisService } from '../../rcyc-services/rcyc-internal-apis/rcyc-internal-apis.service';
import { SharedServiceService } from '../../rcyc-services/rcyc-shared/shared-service.service';
import { orderBy } from 'lodash';
import { first, of, switchMap } from 'rxjs';

@Component({
  selector: 'app-rcyc-special-offers-landing',
  templateUrl: './rcyc-special-offers-landing.component.html',
  styleUrls: ['./rcyc-special-offers-landing.component.css']
})
export class RcycSpecialOffersLandingComponent implements OnInit, AfterViewInit {

  IMG_BASE_URL: any = AppConfig.IMG_BASE_URL;
  IMG_CDNHOST_URL: any = AppConfig.IMG_CDNHOST_URL;

  apiUrlA: any;
  apiUrlABCD: any;
  apiUrlD: any;
  apiUrlBanner: any;

  apiResponseA: any;
  apiResponseABCD: any;
  apiResponseD: any;

  apiResponseATemp: any = [];
  apiResponseABCDTemp: any = [];
  apiResponseBannerTemp: any = [];

  apiResponseAStatus: any = false;
  apiResponseABCDStatus: any = false;
  apiResponseDStatus: any = false;
  apiResponseBannerStatus: any = false;

  apiResponseMap: any;
  apiUrlMetatag: any;
  apiUrlMultipleVoyages: any;

  voyageMapResponseTemp: any;

  splitVoyageIdArray: any = [];

  currentUrlPath: any;
  currentUrlPathAsArray: any = [];
  currentOfferKey: any;

  userCountryCode: any;
  UserDetails: any;

  pageNo = 1;

  specialOffersLandingArray: any;
  specialOffersLandingArrayList: any;
  compareClickStatus = false;
  gridClickStatus = false;
  listClickStatus = true;
  goBackClickStatus = false;
  comparelimitStatus = false;
  apiResponseMapStatus = false;
  apiUrlMap: any;
  allItemsOverview: any;

  lat = 40.730610;
  lng = -73.935242;

  compareCheckboxClickStatusId: any;
  compareCheckboxArray: any = [];

  pageTitle: any;
  pageContent: any;

  apiResponseElasticA: any;
  apiResponseElasticAStatus: any;
  offersAndProgramsApiResp: any;
  currentUserLocation: any;
  currentUserLocationNew: any;
  matchingCruisesLength: any;
  finalVoyageIdAndCallout: any;
  heroVideo: any;
  private allItemsOP: any = [];
  // pager object
  pager: any = {};
  // paged items
  pagedItems: any[];
  // array of all items to be paged

  geoApiResponse: any;
  feild: any;
  order: any;

  voyageMapPath: any;
  currentComponentName: any;

  currencyCode: any;
  reqCallQueryString1: any;
  snapshotUrl: any;
  currentUrlKey: any;
  compareLimitError: any = this.rcycGlobalMessagesService.compareLimitError;

  responseArray: any;
  drupalImageArray: any = [];
  selectedValue = 'DepartureDateAscending';
  initialDrupalCall = true;
  requestCallClickStatus = false;
  expandQueryParam: any;
  sortOrderParam: any;
  gridSelectedStatus = true;
  listSelectedStatus = false;
  specialOfferExpandFlag = false;
  gridFocus = false;
  listFocus = false;
  tabletype = '';
  onloadFlag: any = true;
  urlFirstPart: any = '';
  DataTableDefaultViewArray: any = [];
  DataTableDefaultView: any;
  @ViewChild('requestCallModal') requestCallModal: RcycReqcallModalComponent;
  loadMoreClickCount: number = 1;
  loadedItems: any = [];
  sortByClickStatus = false;
  offersListArray=[
    { 'url': 'https://cdndev1.ritzcarltonyachtcollection.com/2018-04/380x214_Marquee.jpg',
      'title': 'Marquee voyages',
      'content': 'From international sporting events to renowned cultural celebrations, these voyages immerse you into the world’s most thrilling events.'
    },
    { 'url': 'https://cdndev1.ritzcarltonyachtcollection.com/2019-09/Tile2021_380x214_1.jpg?F7ByTEQxFlibr2JaMkMhJo3Fwcr2L1Oj',
      'title': 'Announcing the 2021 season',
      'content': 'Our new 2021 season features celebrated yachting destinations, hidden coves and charming towns.'
    },
    { 'url': 'https://cdndev1.ritzcarltonyachtcollection.com/2019-09/2020_380x214.jpg?K3vCd160O.rp7TdTdiarEq0h6Q5uZMn2',
      'title': '2020 inaugural season',
      'content': 'Be among the first to set sail aboard The Ritz-Carlton Yacht Collection.'
    },
  ];
  loadMoreApiStatus=false;
  maxEachOuterHeight:number=0;
  maxEachVoyagenameHeight:number=0;
  maxEachDDFHeight:number=0;
  maxExtrafeeHeight:number=0;
  viewingVoyageId:string;
  PCFIToggleInfo: any={};
  PCFIVoyageIdArray: any=[];
  scriptExist: boolean = false;
  infoCommonMsg : any;
  currencyFieldMap = {
    USD: 'field_usd_additional_cost_info',
    USM: 'field_usm_additional_cost_info',
    EUR: 'field_eur_additional_cost_info',
    GBP: 'field_gbp_additional_cost_info',
  };

  constructor(

    private rcycCommonService: RcycCommonService,
    private renderer: Renderer2,
    private rcycSpecialOffersLandingService: RcycSpecialOffersLandingService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    // public ngModalService:NgxSmartModalService,
    private rcycGlobalMessagesService: RcycGlobalMessagesService,
    private elementRef: ElementRef,
    private rcycInternalApisService: RcycInternalApisService,
    private _SharedServiceService: SharedServiceService,
  ) {

    this.constructorDataFn();

  }

  ngOnInit() {
    this.getInfoCommonMsg();
    this.getPCFIToggleInfoFn();
    this.urlFirstPart = '';
    if (this.tabletype == 'list' || this.tabletype == 'grid') {
      this.onloadFlag = false;
      this.showDisplayClick(this.tabletype);
    }
    if (this.tabletype != 'list' && this.tabletype != 'grid' && this.tabletype != '') {
      this.router.navigate([this.activatedRoute.snapshot.url[0].path]);
    }
    if (this.tabletype == '') {
      this.getDataTableDefaultView();
    }

    this.specialOffersLandingNgOnInit();
  }

  ngAfterViewInit() {

    if (this.reqCallQueryString1 === 'true') {
      setTimeout(() => {
        // this.requestCallModal.modalOpen();
      }, 0);
    }

  }

  onChange(event) {

    //  console.log(this.optionsModel);
  }

  // Function to show which display grid/ list/ compare
  showDisplayClick(item) {
    if (item === 'compare') {
      if (this.compareCheckboxArray.length > 1 && this.compareCheckboxArray.length <= 3) {

        this.getMapImage();

        // Save Compare Array
        // // @@  this.rcycCommonService.setData('specialOfferPageVoyageCompareDetails', this.compareCheckboxArray);
      } else if (this.compareCheckboxArray.length <= 1) {

      } else {

        this.comparelimitStatus = true;
      }


    } else if (item === 'list') {
      this.compareCheckboxArray = [];
      this.compareClickStatus = false;
      this.listClickStatus = true;
      this.gridClickStatus = false;
      this.comparelimitStatus = false;
      // // @@ this.rcycCommonService.setData('specialOfferPageGridClickStatus', this.gridClickStatus);
      const topList = document.getElementById('listViewLinkId_upper');
      const bottomList = document.getElementById('listViewLink_lower');
      const topGrid = document.getElementById('gridViewLinkId_upper');
      const bottomGrid = document.getElementById('gridViewLink_lower');

      this.gridSelectedStatus = false;
      this.listSelectedStatus = true;
      if (topList) {
        topList.style.textDecoration = 'underline';
        // topList.style.color = 'black';
      }
      if (bottomList) {
        bottomList.style.textDecoration = 'underline';
        // topList.style.color = 'black';
      }
      if (topGrid) {
        topGrid.style.textDecoration = 'none';
      }
      if (bottomGrid) {
        bottomGrid.style.textDecoration = 'none';
      }
      if (this.onloadFlag) {
        this.router.navigate([this.activatedRoute.snapshot.url[0].path + '/' + item]);
      }
      this.onloadFlag = true;
    } else if (item === 'grid') {
      this.compareCheckboxArray = [];
      this.compareClickStatus = false;
      this.listClickStatus = false;
      this.gridClickStatus = true;
      this.comparelimitStatus = false;
      // // @@  this.rcycCommonService.setData('specialOfferPageGridClickStatus', this.gridClickStatus);
      const topGrid = document.getElementById('gridViewLinkId_upper');
      const bottomGrid = document.getElementById('gridViewLink_lower');
      const topList = document.getElementById('listViewLinkId_upper');
      const bottomList = document.getElementById('listViewLink_lower');

      this.gridSelectedStatus = true;
      this.listSelectedStatus = false;
      if (topGrid) {
        topGrid.style.textDecoration = 'underline';
        // topList.style.color = 'black';
      }
      if (bottomGrid) {
        bottomGrid.style.textDecoration = 'underline';
        // topList.style.color = 'black';
      }
      if (topList) {
        topList.style.textDecoration = 'none';
      }
      if (bottomList) {
        bottomList.style.textDecoration = 'none';
      }
      if (this.onloadFlag) {
        this.router.navigate([this.activatedRoute.snapshot.url[0].path + '/' + item]);
      }
      this.onloadFlag = true;
    } else if (item === 'goback') {

      this.compareClickStatus = false;
      this.compareCheckboxArray = [];
      this.comparelimitStatus = false;

      // Remove CompareButtpn Pressed value and compared Details
      // // @@ this.rcycCommonService.removeData('specialOfferPageCompareButtonPressed');
      // // @@ this.rcycCommonService.removeData('specialOfferPageVoyageCompareDetails');
      const specialOfferPageDetails = this.rcycCommonService.getSessionData('specialOfferPageDetails');
      if (specialOfferPageDetails) {
        delete specialOfferPageDetails.specialOfferPageCompareButtonPressed;
        delete specialOfferPageDetails.specialOfferPageVoyageCompareDetails;
        this.rcycCommonService.setSessionData('specialOfferPageDetails', specialOfferPageDetails);
      }
    }

  }
  /* =======================================
  Function to call constructorDataFn
========================================== */
  constructorDataFn() {
    this.activatedRoute.params.subscribe(params => {

      if (params['offer-x-program/:tabletype']) {
        this.tabletype = params['offer-x-program/:tabletype']; //grid or list
      }
      else {
        this.tabletype = '';
      }
    });



    // getting query string
    this.activatedRoute.queryParams.subscribe(params => {

      this.reqCallQueryString1 = params['popover'];
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.expandQueryParam = params['expand'];
    });

    // Changing subheader A value
    // this.rcycCommonService.changeSubHeaderDataArray_A({});
    // Current subHeader Status
    this.rcycCommonService.changeSubHeaderStatus(true);

    this.currentComponentName = 'RcycSpecialOffersLandingComponent';
    // change changeCurrentComponent
    this.rcycCommonService.changeCurrentComponent(this.currentComponentName);

    // this.currentUrlPathAsArray= this.activatedRoute.snapshot.url;
    /*this.currentUrlPath = this.router.url;
    this.currentUrlPathAsArray = this.currentUrlPath.split('/');
    this.currentUrlPathAsArray.shift();*/
    this.snapshotUrl = this.activatedRoute.snapshot as any;
    this.currentUrlPathAsArray = this.snapshotUrl._urlSegment.segments;
    this.currentUrlKey = this.currentUrlPathAsArray[0].path;

    // let splittedData = this.currentUrlKey.replace(/^offer-/, '');
    // splittedData = splittedData.replace(/-program$/, '');
    // this.currentOfferKey = splittedData;
    this.currentOfferKey = this.currentUrlPathAsArray[0].path;

    if (this.expandQueryParam === 'true') {
      this.requestCallClickStatus = true;
      this.specialOfferExpandFlag = true;
    }

    if (!this.rcycCommonService.getData('UserDetails')) {

      this.rcycCommonService.getUser_Details().subscribe(
        response => {

          this.geoApiResponse = response;
          this.rcycSpecialOffersLandingContent();
        },
        err => {

          console.log('Geo SOL APi err');
          this.rcycSpecialOffersLandingContent();
        }
      );
    } else {

      this.geoApiResponse = this.rcycCommonService.getData('UserDetails');
      this.rcycSpecialOffersLandingContent();

    }

    // Get Image From Drupal
    // this.getCruiseImageDrupal();



  }
  /* ===========================================
    Function to get Map Image in compare click
  ============================================== */
  getMapImage() {

    this.compareCheckboxArray.map((factor) => {
      this.apiUrlMap = '/api/v1/voyages/' + factor.voyageId + '/tab/itineraryoverview?_format=json ';
      this.rcycInternalApisService.getDrupal(this.apiUrlMap).subscribe(
        response => {

          this.voyageMapResponseTemp = response;
          if (this.voyageMapResponseTemp && this.voyageMapResponseTemp.length) {

            const res = Object.keys(response).map(function (key) {
              return response[key];
            });
            if (res.length) {

              for (const index of Object.keys(res)) {
                const factor_1 = res[index];
                factor.voyageMapImgUrl = this.IMG_BASE_URL + factor_1.field_voyage_image;
                if (factor_1.field_voyage_image) {

                  const imgTitle = this.rcycCommonService.imageNameAltSplit(factor_1.field_voyage_image);
                  factor.altTextVoyageMap = imgTitle;
                  factor.titleTextVoyageMap = imgTitle;
                } else {

                  factor.altTextVoyageMap = ' ';
                  factor.titleTextVoyageMap = ' ';
                }
                this.compareClickStatus = true;
              }

            }
          }
        },
        err => {
          console.log(err);
          this.compareClickStatus = true;

        }
      );
    });
  }
  getDataTableDefaultView() {
    const Api_Url = '/api/v1/cruisepageview?_format=json';

    this.rcycInternalApisService.getDrupal(Api_Url).subscribe(
      (response) => {
        this.DataTableDefaultViewArray = response;

        if (this.DataTableDefaultViewArray && this.DataTableDefaultViewArray.length) {

          this.DataTableDefaultViewArray.map((viewArray) => {
            if (viewArray.field_page_group == "specialofferslanding")
              this.DataTableDefaultView = viewArray.field_view_types;
            if (!this.DataTableDefaultView && viewArray.name == "Default")
              this.DataTableDefaultView = viewArray.field_view_types;
          });
          this.setLayoutViewStyle();
        }
      },
      (err) => {
        console.log('Error on Getting Cruise Images');
        console.log(err);
      }
    );
  }
  setLayoutViewStyle() {
    if (this.tabletype == "") {
      if (this.DataTableDefaultView == 'list' || this.DataTableDefaultView == 'grid') {
        this.onloadFlag = false;
        this.showDisplayClick(this.DataTableDefaultView);
      }
    }
  }
  compareCheckboxClick(event, item, index, viewMode) {

    let viewModeId;
    let checkboxId;
    if (viewMode === 'grid') {
      viewModeId = 'eachGridItemId_' + index;
      checkboxId = 'checkbox_' + index;
    } else if (viewMode === 'list') {
      viewModeId = 'eachListItemId_' + index;
      checkboxId = 'checkbox_' + index;
    }
    const elem: Element = document.getElementById(viewModeId);

    if (event.target.checked === true) {
      this.compareCheckboxArray.push(item);
      // this.renderer.setElementClass(elem, 'active', true);
      this.renderer.addClass(elem, 'active');

    } else {
      this.removecompareCheckboxItem(item);
      // this.renderer.setElementClass(elem, 'active', false);
      this.renderer.removeClass(elem, 'active');
    }

  }

  // Fuction to remove item from compare array

  removecompareCheckboxItem(item) {
    this.compareCheckboxArray.splice(this.compareCheckboxArray.indexOf(item), 1);
    if (this.compareCheckboxArray.length <= 3) {
      this.comparelimitStatus = false;
    }
  }


  /* ===================================================
  Function to call all initial special offers APIs
======================================================*/

  rcycSpecialOffersLandingContent() {

    /****************** Section Metatag********************/
    this.apiUrlMetatag = '/api/v1/offers/details/' + this.currentOfferKey + '?_format=json';

    this.rcycCommonService.getMetaTags(this.apiUrlMetatag);

    /****************** Section A********************/
    // this.apiUrlA = '/api/v1/specialofferslanding/header/a?_format=json';
    // this.rcycInternalApisService.getDrupal(this.apiUrlA).subscribe(
    //   response => {

    //     this.apiResponseATemp = response;
    //     if (this.apiResponseATemp && this.apiResponseATemp.length) {

    //       // var res= Object.values(response);
    //       const res = Object.keys(response).map(function (key) {
    //         return response[key];
    //       });
    //       if (res.length) {

    //         this.apiResponseAStatus = true;
    //         this.apiResponseA = res[0];

    //         // Changing subheader A value
    //         this.rcycCommonService.changeSubHeaderDataArray_A(this.apiResponseA);
    //       } else {

    //         // Changing subheader A value
    //         this.rcycCommonService.changeSubHeaderDataArray_A([]);
    //       }
    //     } else {

    //       // Changing subheader A value
    //       this.rcycCommonService.changeSubHeaderDataArray_A([]);
    //     }

    //   },
    //   err => {
    //     console.log(' special Offers A err');
    //     console.log(err);
    //   }
    // );

    /****************** Section Banner Image *******************/

    this.apiUrlBanner = '/api/v1/banners/offers-programs/' + this.currentOfferKey + '/b?_format=json';
    this.rcycInternalApisService.getDrupal(this.apiUrlBanner).subscribe(
      (response: any=[]) => {
        // this.apiResponseBannerTemp = response;
        // if (this.apiResponseBannerTemp && this.apiResponseBannerTemp.length) {
        //   const res = Object.keys(response).map(function (key) {
        //     return response[key];
        //   });
          const resArray = [];
          if (response.length) {
            this.responseArray = response;
            this.apiResponseBannerStatus = true;
          }
        //}
      },
      err => {
        console.log(err);
      }
    );

    /****************** Section ABCD*******************/
    this.apiUrlABCD = '/api/v1/offers/details/' + this.currentOfferKey + '?_format=json';
    // this.apiUrlABCD = '/api/v1/banners/offers-programs/' + this.currentOfferKey + '/b?_format=json';
    this.rcycInternalApisService.getDrupal(this.apiUrlABCD).subscribe(
      (response: any=[]) => {

        // this.apiResponseABCDTemp = response;
        // if (this.apiResponseABCDTemp && this.apiResponseABCDTemp.length) {

        //   // var res = Object.values(response);
        //   const res = Object.keys(response).map(function (key) {
        //     return response[key];
        //   });

          const resArray = [];
          let splitVoyageIds;
          let splitCallOutTexts;
          if (response.length) {
            this.rcycCommonService.changeSubHeaderDataArray_A({title: response[0].field_header_title});
            for (const index of Object.keys(response)) {
              const factor = response[index];
              if (factor.field_external_script) {
                const extScript = this.convertSpecialCharacters(factor.field_external_script);
                this.extractScriptValuesFromHTML(extScript);
              }
              if (factor.field_images) {

                factor.field_images_srcset = this.IMG_BASE_URL + factor.field_images +
                  ' 1600w, ' + this.IMG_BASE_URL + factor.field_image_1440px + ' 1000w, '
                  + this.IMG_BASE_URL + factor.field_image_768px + ' 800w, ' +
                  this.IMG_BASE_URL + factor.field_image_375px + ' 500w';
                factor.field_images_src1 = this.IMG_BASE_URL + factor.field_images;
                factor.field_images_src2 = this.IMG_BASE_URL + factor.field_image_1440px;
                factor.field_images_src3 = this.IMG_BASE_URL + factor.field_image_768px;
                factor.field_images_src4 = this.IMG_BASE_URL + factor.field_image_375px;
                factor.field_images = this.IMG_BASE_URL + factor.field_images;
                const imgTitle = this.rcycCommonService.imageNameAltSplit(factor.field_images);

                factor.altTextBannerImg = factor.title + ' ' + imgTitle;
                factor.titleTextBannerImg = factor.title + ' ' + imgTitle;
              } else {

                factor.altTextBannerImg = factor.title;
                factor.titleTextBannerImg = factor.title;
              }
              const voyageIdArray = [];
              const voyageCalloutTextArray = [];
              const finalVoyageCalloutId = {};

              if (factor.field_voyages && factor.field_callout_text) {

                splitVoyageIds = factor.field_voyages;
                splitVoyageIds = splitVoyageIds.split('###');
                this.splitVoyageIdArray = splitVoyageIds;

                splitCallOutTexts = factor.field_callout_text;
                splitCallOutTexts = splitCallOutTexts.split('###');

                for (let i = 0; i < splitVoyageIds.length; i++) {

                  finalVoyageCalloutId[splitVoyageIds[i]] = splitCallOutTexts[i];
                  if (i + 1 === splitVoyageIds.length) {
                    this.finalVoyageIdAndCallout = finalVoyageCalloutId;
                  }
                }

                factor.voyageCount = voyageIdArray.length;                
              }
              resArray.push(factor);
              if (parseInt(index, 10) + 1 === response.length) {

                this.apiResponseABCD = resArray[0];
                this.apiResponseABCDStatus = true;
                if(this.apiResponseABCD.field_form_type) {
                  this.apiResponseABCD.field_form_type = this.apiResponseABCD.field_form_type.toUpperCase().replace(/\s/g,'');
                }
                if (this.apiResponseABCD.field_video) {
                  this.rcycCommonService.checkHeroVideoUrl(this.apiResponseABCD.field_video).subscribe(value => {
                    if (value.status === 200) {
                      this.heroVideo = this.sanitizer.bypassSecurityTrustResourceUrl
                        (this.apiResponseABCD.field_video + '?autoplay=1&amp;background=1&amp;loop=1');
                    }
                  });
                }

                if (this.splitVoyageIdArray.length) {

                  this.multipleVoyageElasticApiCall();
                }
              }
            }
          }
        // }


      },
      err => {

        console.log(' special Offers Landing ABCD err');
        console.log(err);
      }
    );



    /****************** Section D********************/

    /* this.apiUrlD= "/api/v1/specialofferslanding/content/d?_format=json";
  this.rcycInternalApisService.specialOffersLandingDrupalApiGet(this.apiUrlD).subscribe(
    response => {

      // var res= Object.values(response);
      var res= Object.keys(response).map(function(key) {
                    return response[key];
                });
      if(res.length) {

        this.apiResponseDStatus = true;
        this.apiResponseD = response[0];
      }

    },
    err => {
      console.log(err);
    }
  );*/

  }


  /* =========================================================
  Function to call multiple voyages elastic api call
============================================================ */

  multipleVoyageElasticApiCall() {
    this.rcycCommonService.getAllCallOut('special', this.rcycCommonService.getNid());
    this.apiUrlMultipleVoyages = '/api/voyage/list';
    let params;
    const geoCountryCode = this.geoApiResponse;
    if (geoCountryCode) {
      // params.countryCode= this.currentUserLocationNew.countryCode;
      params = {

        apiUrl: this.apiUrlMultipleVoyages,
        countryCode: geoCountryCode.country_code,
        voyageIds: this.splitVoyageIdArray,
      };
    } else {
      // params.countryCode= "US";
      params = {

        apiUrl: this.apiUrlMultipleVoyages,
        countryCode: 'US',
        voyageIds: this.splitVoyageIdArray,
      };
    }

    this.rcycSpecialOffersLandingService.multipleVoyageElasticApi(params).subscribe(
      response => {

        this.offersAndProgramsApiResp = response;

        if (this.offersAndProgramsApiResp && this.offersAndProgramsApiResp.code === 200) {
          const res = this.offersAndProgramsApiResp.data;
          if (res.length) {

            const resArray = [];
            for (const index of Object.keys(res)) {

              const factor = res[index];
              const voyageEmbarkPort_new = factor.voyageEmbarkPort.replace(/[^a-zA-Z-]/g, '').replace(/\s/g, '').toLowerCase();
              const voyageDisembarkPort_new = factor.voyageDisembarkPort.replace(/[^a-zA-Z-]/g, '').replace(/\s/g, '').toLowerCase();

              factor.itineraryUrl = '/' + factor.voyageUrlPath + '/' +
                voyageEmbarkPort_new + '-to-' + voyageDisembarkPort_new +
                '-' + factor.voyageId + '/itinerary';
              factor.voyageUrl_new_offer = '/' + factor.voyageUrlPath + '/' +
                voyageEmbarkPort_new + '-to-' + voyageDisembarkPort_new +
                '-' + factor.voyageId + '/offers-programs';
              const callOutTextValue = this.finalVoyageIdAndCallout;
              for (const key of Object.keys(callOutTextValue)) {

                if (factor.voyageId === key) {

                  factor.callOutTextNew = callOutTextValue[key];
                }
              }

              // factor.voyageImagePath= this.IMG_BASE_URL+"/sites/default/files/thumbnails/"+factor.voyageId+".jpg";
              factor.voyageImagePath = this.IMG_CDNHOST_URL + '/thumbnails/' + factor.voyageId + '.jpg';
              factor.nights = factor.nights;

              factor.altTextVoyageImg = factor.voyageName + ' ' + factor.voyageId;
              factor.titleTextVoyageImg = factor.voyageName + ' ' + factor.voyageId;

              factor.pcfiToggleStatus=false;
              if( this.PCFIVoyageIdArray.includes(factor.voyageId) ) {
                factor.pcfiToggleStatus=true;
              } 
              if(factor.voyageId) {
                factor.callOutText = this.rcycCommonService.getOffersVoyageSortedOutField(factor.voyageId); 
              }    
              resArray.push(factor);
              if (parseInt(index, 10) + 1 === res.length) {
               
                this.apiResponseElasticAStatus = true;

                this.matchingCruisesLength = resArray.length;
                this.allItemsOP = resArray;
                if (this.initialDrupalCall) {
                  this.getCruiseImageDrupal();
                }
                this.filterByVoyageId();
                const prevUrlForBackButton = this.rcycCommonService.getSessionData('backToResultVoyageListPrevUrl');
                if (prevUrlForBackButton) {

                  const specialOfferPageDetails = this.rcycCommonService.getSessionData('specialOfferPageDetails');
                  let pager;
                  if (specialOfferPageDetails && specialOfferPageDetails.specialOffersPagePager) {
                    pager = specialOfferPageDetails.specialOffersPagePager;
                  }
                  if (pager) {

                    let sortDetail;
                    if (specialOfferPageDetails && specialOfferPageDetails.specialOffersPageSortOrder) {
                      sortDetail = specialOfferPageDetails.specialOffersPageSortOrder;
                    }


                    this.setPage(pager.currentPage, 8);
                    if (sortDetail) {

                      this.selectedValue = sortDetail.selectedValue;
                      this.sortBy(this.selectedValue, '');
                    }
                    // Remove Pager Once Loaded from Back Buton
                    this.rcycCommonService.removeData('specialOffersPagePager');                    
                  }

                } else {

                  this.rcycCommonService.removeData('specialOffersPagePager');
                  this.rcycCommonService.removeData('specialOffersPageSortOrder');
                  this.setPage(1, 8);
                }
                if(window.innerWidth>560) {
                  let timeOut;
                  clearTimeout(timeOut);
                  timeOut = setTimeout(() => {
            
                    this.eachOuterOfferLandingHeightAlign();
                  },3000);                  
                }

              }
            }
          }
        }
      },
      err => {

        console.log(' special Offers Landing Multiple voyage Elastic api call err');
        console.log(err);
      }
    );

  }

  /* =======================
       Pagination part
  ========================== */

  setPage(page: number, pageSize: number) {    
    if (page < 1 || page > this.pager.totalPages) {

      return;
    }

    // get pager object from service
    this.pager = this.rcycCommonService.getPager(this.allItemsOP.length, page, pageSize);
    let sortDetail;
    const specialOfferPageDetail = this.rcycCommonService.getSessionData('specialOfferPageDetails');
    if (specialOfferPageDetail && specialOfferPageDetail.specialOffersPageSortOrder) {
      sortDetail = specialOfferPageDetail.specialOffersPageSortOrder;
      specialOfferPageDetail.specialOffersPagePager = this.pager;
      this.rcycCommonService.setSessionData('specialOfferPageDetails', specialOfferPageDetail);
    }

    if (sortDetail) {
      this.sortData(this.allItemsOP, sortDetail.feild, sortDetail.order);
      const specialOfferPageDetailes = this.rcycCommonService.getSessionData('specialOfferPageDetails');
      let pager;
      if (specialOfferPageDetail && specialOfferPageDetailes.specialOffersPagePager) {
        pager = specialOfferPageDetail.specialOffersPagePager;
      }
      if (pager) {
        this.sortData(this.allItemsOP, this.feild, this.order);
        this.pagedItems = this.allItemsOP.slice(pager.startIndex, pager.endIndex + 1);
      }
    } else {
      this.sortData(this.allItemsOP, this.feild, this.order);
      this.pagedItems = this.allItemsOP.slice(this.pager.startIndex, this.pager.endIndex + 1);
    }

    let gridClickStatus;
    const specialOfferPageDetails = this.rcycCommonService.getSessionData('specialOfferPageDetails');
    if (specialOfferPageDetails && specialOfferPageDetails.specialOfferPageGridClickStatus) {
      gridClickStatus = specialOfferPageDetails.specialOfferPageGridClickStatus;
      if (gridClickStatus) {
        this.gridClickStatus = true;
        this.listClickStatus = false;
      } else {
        this.gridClickStatus = this.gridClickStatus;
        this.listClickStatus = this.listClickStatus;
      }
    } else {
      this.gridClickStatus = this.gridClickStatus;
      this.listClickStatus = this.listClickStatus;
    }

    this.settingCruiseListArrayLoad();
    
    this.loadMoreApiStatus=false;  
    
  }

  /* =========================
       SortBy Departure Date
  ============================ */

  sortBy(value, param) {
    this.sortByClickStatus = true;
    this.compareCheckboxArray = [];
    const elements = this.elementRef.nativeElement.querySelectorAll('.eachOuter.active');
    if (elements.length) {
      elements.forEach(function (el) {
        el.classList.remove('active');
      });
    }

    const compareCheckBox = this.elementRef.nativeElement.querySelectorAll('.compare_checkbox');
    if (compareCheckBox.length) {
      compareCheckBox.forEach(function (el) {
        el.checked = false;
      });
    }
    this.selectedValue = value;
    this.feild = 'voyageStartDate';
    this.order = 'ASC';

    switch (value) {

      case 'DepartureDateAscending':

        this.feild = 'voyageStartDate';
        this.order = 'ASC';
        break;

      case 'DepartureDateDescending':

        this.feild = 'voyageStartDate';
        this.order = 'DESC';
        break;

      case 'PricelowTohigh':

        this.feild = 'startingPrice';
        this.order = 'ASC';
        break;

      case 'PricehighTolow':

        this.feild = 'startingPrice';
        this.order = 'DESC';
        break;

      case 'DurationshortestTolongest':

        this.feild = 'nights';
        this.order = 'ASC';
        break;

      case 'DurationlongestToshortest':

        this.feild = 'nights';
        this.order = 'DESC';
        break;


    }

    const sortOrderParam = {
      feild: this.feild,
      order: this.order,
      selectedValue: value
    };

    this.sortOrderParam = sortOrderParam;

    const specialOfferPageDetails = this.rcycCommonService.getSessionData('specialOfferPageDetails');
    if (param === 'input') {
      if (specialOfferPageDetails && specialOfferPageDetails.specialOffersPagePager) {
        delete specialOfferPageDetails.specialOffersPagePager;
        this.rcycCommonService.setSessionData('specialOfferPageDetails', specialOfferPageDetails);
      }
    }
    let pager;
    if (specialOfferPageDetails && specialOfferPageDetails.specialOffersPagePager) {
      pager = specialOfferPageDetails.specialOffersPagePager;
    }
    if (pager) {
      this.setPage(pager.currentPage, 8);
    } else {
      this.setPage(1, 8);
    }
  }

  /* ======================
      Sort data function
  ========================== */
  sortData(arr, prop, order) {

    let key1, key2;
    switch (prop) {

      case 'startingPrice':

        if (order === 'DESC') {
          if (arr && arr.length) {
            const feeCurrency = arr[0].feeCurrency;
            arr.sort(function (keys1, keys2) {
              return keys2['startingPriceMap'][feeCurrency] - keys1['startingPriceMap'][feeCurrency];
            });
          }
        } else {
          if (arr && arr.length) {
            const feeCurrency = arr[0].feeCurrency;
            arr.sort(function (keys1, keys2) {
              return keys1['startingPriceMap'][feeCurrency] - keys2['startingPriceMap'][feeCurrency];
            });
          }
          // arr.sort(function (keys1, keys2) {
          //   return keys1[prop] - keys2[prop];
          // });
        }
        break;

      case 'nights':

        arr.sort(function (a, b) {
          // key1 = parseInt(a[prop].split(' ')[0], 10);
          // key2 = parseInt(b[prop].split(' ')[0], 10);
          key1 = parseInt(a[prop]);
          key2 = parseInt(b[prop]);
          if (order === 'DESC') {
            return key2 - key1;
          } else {
            return key1 - key2;
          }
        });
        break;

      case 'voyageStartDate':

        arr.sort(function (a, b) {
          if (order === 'DESC') {
            key1 = new Date(a[prop]);
            key2 = new Date(b[prop]);
            return key2 - key1;
          } else {
            key1 = new Date(a[prop]);
            key2 = new Date(b[prop]);
            return key1 - key2;
          }
        });
        break;

      default:
        break;
    }

    // return arr;
    this.allItemsOP = arr;
  }

  /* ===========================
      Request call click
  =============================== */
  requestCallClick() {
    // this.router.navigate(['.'], {
    //   relativeTo: this.activatedRoute,
    //   queryParams: {
    //     popover: 'true'
    //   }
    // });
    // this.requestCallModal.modalOpen();
    this.requestCallClickStatus = !this.requestCallClickStatus;
    this.specialOfferExpandFlag = true;

    setTimeout(function () {
      // const reqCallElem = this.elementRef.nativeElement.querySelector('#rcyc-request-call .l-container');
      // const reqCallElem = document.querySelector('#rcyc-request-call .l-container') as HTMLElement;
      // console.log(reqCallElem);
      // reqCallElem.insertAdjacentHTML('afterbegin',
      // '<button type="button" class="reqCallCloseBtn" (click)="reqCallCloseBtnClick()" aria-label="Close this box">' +
      // '×' +
      // '</button>');
    }, 1000);
  }

  /* ===========================
      Request call close button click
  =============================== */

  reqCallCloseBtnClick() {

    this.requestCallClickStatus = false;
  }

  // Function to get Drupal API
  getCruiseImageDrupal() {
    let drupalImageArray: any = this.rcycCommonService.getSessionData('drupalImageArray');
    if (drupalImageArray) {
      this.filterByVoyageId();
    } else {
      const Api_Url = '/api/v1/voyages/thumbnails?_format=json';
      this.rcycInternalApisService.getDrupal(Api_Url).subscribe(
        (res: any = []) => {
          if (res.length) {
            this.rcycCommonService.setSessionData('drupalImageArray', res);
            this.filterByVoyageId();
          }
        },
        (err) => {
          console.log('Error Occured on Getting drupal images');
          console.log(err);
        });
    }
  }

  // Function to filter based on voyageId
  filterByVoyageId() {
    this.drupalImageArray = this.rcycCommonService.getSessionData('drupalImageArray');
    if (this.drupalImageArray.length) {
      this.initialDrupalCall = false;
    }
    if (this.allItemsOP.length && this.drupalImageArray.length) {
      this.allItemsOP.filter((item, index1) => {
        this.drupalImageArray.filter((data, index2) => {
          if (item.voyageId === data.field_voyages) {
            // Set drupal image url
            this.allItemsOP[index1].voyageImagePath = data.field_voyage_thumbnail;
          }
        });
      });
    }
  }

  // Function to set Previous Url For Voyage Page Back Btn
  setPreviousUrlForVoyagePageBack(url, item) {
    // this.rcycCommonFindACruiseService.setPreviousUrl(this.router.url);
    this.rcycCommonService.setSessionData('backToResultVoyageListPrevUrl', this.router.url);
    const specialOfferPageDetails = {
      'specialOfferPageVoyageCompareDetails': this.compareCheckboxArray,
      'specialOfferPageGridClickStatus': this.gridClickStatus,
      'specialOfferPageCompareButtonPressed': this.compareClickStatus,
      'specialOffersPagePager': this.pager,
      'specialOffersPageSortOrder': this.sortOrderParam
    };
    this.rcycCommonService.setSessionData('specialOfferPageDetails', specialOfferPageDetails);
    if (item) {

    } else {
      this.currentUrlPathAsArray = url.split('/');
      this.currentUrlPathAsArray.shift();
      this.router.navigate([ '/' + this.currentUrlPathAsArray[0] + '/' + this.currentUrlPathAsArray[1] ]);
    }
  }

  specialOffersLandingNgOnInit() {
    this.rcycCommonService.removeSessionData('voyageBackButtonPressed');
    const prevUrlForBackButton = this.rcycCommonService.getSessionData('backToResultVoyageListPrevUrl');
    if (prevUrlForBackButton) {
      const specialOfferPageDetails = this.rcycCommonService.getSessionData('specialOfferPageDetails');
      if (specialOfferPageDetails && specialOfferPageDetails.specialOfferPageCompareButtonPressed) {
        this.compareClickStatus = true;
        if (specialOfferPageDetails && specialOfferPageDetails.specialOfferPageVoyageCompareDetails &&
          specialOfferPageDetails.specialOfferPageVoyageCompareDetails.length) {
          const compareCheckboxArray = specialOfferPageDetails.specialOfferPageVoyageCompareDetails;
          this.compareCheckboxArray = compareCheckboxArray;
        }
      } else {
        this.compareClickStatus = false;
      }
    } else {
      const specialOfferPageDetails = this.rcycCommonService.getSessionData('specialOfferPageDetails');
      if (specialOfferPageDetails) {
        this.rcycCommonService.removeSessionData('specialOfferPageDetails');
      }
    }
  }

  focusSortByDropdown(event, action, position) {
    if (position === 'top') {
      if (action === 'focus') {
        document.getElementById('specialSelectTopOuter').style.boxShadow = '0 0 5px black';
        document.getElementById('specialSelectTopOuter').style.border = '1px solid black !important';
      } else if (action === 'focusout') {
        document.getElementById('specialSelectTopOuter').style.boxShadow = null;
        document.getElementById('specialSelectTopOuter').style.border = null;
      }
    } else if (position === 'bottom') {
      if (action === 'focus') {
        document.getElementById('specialSelectBottomOuter').style.boxShadow = '0 0 5px black';
        document.getElementById('specialSelectBottomOuter').style.border = '1px solid black !important';
      } else if (action === 'focusout') {
        document.getElementById('specialSelectBottomOuter').style.boxShadow = null;
      }
    }
  }

  /* =============================================================================
      Function on Grid - List Focus
   ===============================================================================*/
  gridListFocus(event, type, position) {
    if (position === 'top') {
      if (type === 'grid') {
        event.target.style.textDecoration = 'underline';
        // this.gridSelectedStatus = false;
        // this.listSelectedStatus = false;

        this.gridFocus = true;
        this.listFocus = false;

      } else if (type === 'list') {
        event.target.style.textDecoration = 'underline';

        // this.gridSelectedStatus = false;
        // this.listSelectedStatus = false;

        this.listFocus = true;
        this.gridFocus = false;

      }
    } else if (position === 'bottom') {
      if (type === 'grid') {
        // if (this.gridClickStatus) {
        event.target.style.textDecoration = 'underline';
        // }
        // this.gridSelectedStatus = false;
        // this.listSelectedStatus = false;

        this.gridFocus = true;
        this.listFocus = false;

      } else if (type === 'list') {
        // if (this.listClickStatus) {
        event.target.style.textDecoration = 'underline';
        // }
        // this.gridSelectedStatus = false;
        // this.listSelectedStatus = false;

        this.listFocus = true;
        this.gridFocus = false;
      }
    }
  }

  /* =============================================================================
  Function on Grid - List Mouse Enter
  ===============================================================================*/
  gridListMouseOver(event) {
    event.target.style.textDecoration = 'underline';
  }
  /* =============================================================================
  Function on Grid - List Mouse Leave
  ===============================================================================*/
  gridListMouseLeave(event, type, position) {

    if (position === 'top') {

      if (type === 'grid') {
        if (this.gridFocus) {

        } else {
          event.target.style.textDecoration = 'none';
        }

      } else if (type === 'list') {
        if (this.listFocus) {

        } else {
          event.target.style.textDecoration = 'none';
        }
      }

    } else if (position === 'bottom') {

      if (type === 'grid') {
        if (this.gridFocus) {

        } else {
          event.target.style.textDecoration = 'none';
        }

      } else if (type === 'list') {
        if (this.listFocus) {

        } else {
          event.target.style.textDecoration = 'none';
        }
      }
    }
  }

  /* =============================================================================
  Function on Grid - List Mouse Enter
  ===============================================================================*/
  gridListFocusOut(event, type, position) {
    const topGrid = document.getElementById('gridViewLinkId_upper');
    const bottomGrid = document.getElementById('gridViewLink_lower');

    const topList = document.getElementById('listViewLinkId_upper');
    const bottomList = document.getElementById('listViewLink_lower');

    if (position === 'top') {
      if (type === 'grid') {

        if (topGrid) {
          topGrid.style.textDecoration = 'none';
        }
        if (bottomGrid) {
          bottomGrid.style.textDecoration = 'none';
        }

      } else {

        if (topList) {
          topList.style.textDecoration = 'none';
        }
        if (bottomList) {
          bottomList.style.textDecoration = 'none';
        }

      }
    } else if (position === 'bottom') {
      if (type === 'grid') {

        if (topGrid) {
          topGrid.style.textDecoration = 'none';
        }
        if (bottomGrid) {
          bottomGrid.style.textDecoration = 'none';
        }

      } else {

        if (topList) {
          topList.style.textDecoration = 'none';
        }
        if (bottomList) {
          bottomList.style.textDecoration = 'none';
        }

      }
    }
  }

  /* =============================================================================
    Function for Load more cruise
  ===============================================================================*/
  loadMoreCruise() {
    this.sortByClickStatus = false;
    this.loadMoreApiStatus=true;
    this.loadMoreClickCount++;
    const page = this.loadMoreClickCount;
        
    this.setPage(this.loadMoreClickCount, 8);

    if(window.innerWidth>560) {
      let timeOut;
      clearTimeout(timeOut);
      timeOut = setTimeout(() => {

        this.eachOuterOfferLandingHeightAlign();        
      },3000);     
    }
   // this.eachOuterOfferLandingHeightAlign();

    // this.searchdata = {
    //   sortField: this.sortFeild,
    //   sortOrder: this.sortOrder,      
    //   page: this.loadMoreClickCount,
    //   size: 4
    // };
    // this.voyageListElasticApiCall(this.searchdata);
  }

  eachOuterOfferLandingHeightAlign() {
   
    let maxEachVoyagenameHeight:number = 0;    
    if(document.querySelectorAll('.gridView .voyageName')) {
      Array.from(document.querySelectorAll('.gridView .voyageName') ).forEach(
        function(el) {
          // console.info(Math.max(el.scrollHeight, maxEachVoyagenameHeight));
          if (el.scrollHeight > maxEachVoyagenameHeight) {
            maxEachVoyagenameHeight = el.scrollHeight;
          }         
        }
      );    
      this.maxEachVoyagenameHeight=maxEachVoyagenameHeight;            
    }

    let maxEachDDFHeight:number = 0;    
    if(document.querySelectorAll('.gridView .eachRow-3')) {
      Array.from(document.querySelectorAll('.gridView .eachRow-3') ).forEach(
        function(el) {
          // console.info(Math.max(el.scrollHeight, maxEachDDFHeight));
          if (el.scrollHeight > maxEachDDFHeight) {
            maxEachDDFHeight = el.scrollHeight;
          }         
        }
      );    
      this.maxEachDDFHeight=maxEachDDFHeight;      
    }  
  }

  // Function to set Special Offer Landing cruise data on back to voyage click
  settingCruiseListArrayLoad() {
    
    const isBackToUrlDatas=this.rcycCommonService.getSessionData('backToResultVoyageListPrevUrl');
    const fullLoadedSearchResults= this.rcycCommonService.getSessionData('fullLoadedSearchResults');  

    if(isBackToUrlDatas&&fullLoadedSearchResults.cruiseListArray.length) {      
      const fullLoadedSearchResults=this.rcycCommonService.getSessionData('fullLoadedSearchResults');
      this.loadedItems=fullLoadedSearchResults.cruiseListArray;
      this.loadMoreClickCount=fullLoadedSearchResults.loadMoreClickCount;     

      this.viewingVoyageId= this.rcycCommonService.getSessionData('viewingVoyageId');      
      if(this.viewingVoyageId) {            
        let scrollToClassTimeOut;
        clearTimeout(scrollToClassTimeOut);
        scrollToClassTimeOut=setTimeout(() => {
          const scrollToClass='.eachOuter-grid-'+this.viewingVoyageId;    
          if(document.querySelector(scrollToClass)) {
            document.querySelector(scrollToClass).scrollIntoView();
          }                
        }, 1000);
      } 
    } else {      
      if(this.sortByClickStatus || !this.pagedItems.length) {
        this.loadedItems = this.pagedItems;
      } else {
        this.loadedItems = this.loadedItems.concat(this.pagedItems);
      }  
      const paramFullLoadedSearchResults={
        cruiseListArray: this.loadedItems,
        loadMoreClickCount: this.loadMoreClickCount
      }
      if(this.rcycCommonService.getSessionData('fullLoadedSearchResults')) {
        this.rcycCommonService.removeSessionData('fullLoadedSearchResults');
      }
      this.rcycCommonService.setSessionData('fullLoadedSearchResults', paramFullLoadedSearchResults);      
    }

    this.rcycCommonService.removeSessionData('backToResultVoyageListPrevUrl');
    this.rcycCommonService.removeSessionData('viewingVoyageId');
  }

  viewingVoyageClick(item) {
    this.viewingVoyageId=item.voyageId;
    this.rcycCommonService.setSessionData('viewingVoyageId', this.viewingVoyageId);
  }
  // Function to get drupal pcfi toggle datas
  getPCFIToggleInfoFn() {
    this._SharedServiceService.getPCFIToggleInfo().subscribe(
      (res)=>{            
        this.PCFIToggleInfo=res;
        if(res&&res.field_voyages) {
          this.PCFIVoyageIdArray=res.field_voyages.replace(/\s/g,'').split(",");
        }                          
      }
    )
  }

  /**
   * 
   * @param sourceHTML The Scrit that may contain special characters or encoded elements added through drupal.
   * @returns The plain text content extracted from the HTML snippet
   */
  convertSpecialCharacters(sourceHTML: string): string {
    const div = document.createElement('div');
    div.innerHTML = sourceHTML;
    return div.textContent || div.innerText;
  }

  /**
   * 
   * @param html External script added 
   */
  extractScriptValuesFromHTML(html: string): void {
    const divIdRegex = /<\s*div[^>]*id\s*=\s*["']?\s*([^"'\s>]+)\s*["']?\s*[^>]*><\s*\/\s*div\s*>/i;
    const scriptIdRegex = /<\s*script[^>]*id\s*=\s*["']?\s*([^"'\s>]+)\s*["']?\s*[^>]*>/i;
    const scriptSrcRegex = /<\s*script[^>]*src\s*=\s*["']?\s*([^"'\s>]+)\s*["']?\s*[^>]*>/i;

    const divIdMatch = html.match(divIdRegex);
    const scriptIdMatch = html.match(scriptIdRegex);
    const scriptSrcMatch = html.match(scriptSrcRegex);

    const scriptDivId = divIdMatch ? divIdMatch[1].trim() : '';
    const scriptId = scriptIdMatch ? scriptIdMatch[1].trim() : '';
    const scriptSource = scriptSrcMatch ? scriptSrcMatch[1].trim() : '';

    if (scriptDivId && scriptId && scriptSource) {
      this.addThirdPartyScript(scriptDivId, scriptId, scriptSource);
    }
  }
  
  /**
   * Function to add the script for specific offers and programs
   * @param sriptDivId Id of the div containing script
   * @param scriptId Id of Script
   * @param scriptSource Source of Script
   */
  addThirdPartyScript(sriptDivId, scriptId, scriptSource) {
    const script = this.renderer.createElement("script");
    script.defer = true;
    script.async = true;
    script.id = scriptId;
    script.src = scriptSource;
    script.setAttribute("data-tag", "");

    const div = this.renderer.createElement("div");
    div.id = sriptDivId;

    const targetDiv = document.getElementById("specialOffersAndProgramEternalScript");
    if (targetDiv) {
      this.scriptExist = true;
      this.renderer.appendChild(targetDiv, div);
      this.renderer.appendChild(targetDiv, script);
    }
  }
  
  /**
   * Get common info messages from drupal
   */
  getInfoCommonMsg() {
    this.rcycCommonService.currentInfoMessageSource$.pipe(
      switchMap((value) => value ? of(value) : this._SharedServiceService.getInfoCommonMsg()),
      first()
    ).subscribe((response) => {
      this.infoCommonMsg = response;
      this.rcycCommonService.setInfoMessageSource(response);
    });
  }
  
}
