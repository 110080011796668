import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RcycInternalApisService } from '../../rcyc-services/rcyc-internal-apis/rcyc-internal-apis.service';
import { fromEvent } from 'rxjs';
import { RcycCommonService } from '../../rcyc-services/rcyc-common/rcyc-common.service';

@Component({
  selector: 'app-rcyc-check-offer-program',
  templateUrl: './rcyc-check-offer-program.component.html',
  styleUrls: ['./rcyc-check-offer-program.component.css']
})
export class RcycCheckOfferProgramComponent implements OnInit {

  snapshotUrl: any;
  currentUrlPathAsArray: any;
  currentUrlKey: any;
  currentOfferKey: any;
  checkSpecialOfferContent: any=[];
  checkEBrochureUrlKey:string;
  checkEditorialHubArticleContent: any = [];
  currentUrlKeyInitial: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private rcycInternalApisService: RcycInternalApisService,
    private rcycCommonService: RcycCommonService,
    private router: Router,
  ) { 

    this.snapshotUrl = this.activatedRoute.snapshot as any;
    this.currentUrlPathAsArray = this.snapshotUrl._urlSegment.segments;
    if (this.currentUrlPathAsArray.length) {
      this.currentUrlKey = this.currentUrlPathAsArray[0].path;
    }
    this.currentUrlKeyInitial = this.currentUrlKey;

  }

  ngOnInit() {    
    this.drupalwithOfferKey();
  }

  drupalwithOfferKey() {

    let splittedData = this.currentUrlKey.replace(/^offer-/, '');
    splittedData = splittedData.replace(/-program$/, '');
    this.currentOfferKey = splittedData;
    /****************** Section ABCD*******************/
    const apiUrlABCD = '/api/v1/offers/details/' + this.currentOfferKey + '?_format=json';
    this.rcycCommonService.getMetaTags(apiUrlABCD);    
    this.rcycInternalApisService.getDrupal(apiUrlABCD).subscribe(
      (response: any=[]) => {
        if (response && response.length) { 
          this.checkSpecialOfferContent =  response;  
          fromEvent(window, 'popstate').subscribe(() => {
            window.location.reload();
          });      
        } else {
          this.editorialArticleUrlCheck();
        }
      },
      err => {
        this.editorialArticleUrlCheck();
        console.log('Check offers landing ABCD err');
        console.log(err);
      }
    );
  }

  editorialArticleUrlCheck() {
    /****************** Section ABCD*******************/
    const apiUrlABCD = '/api/v1/editorialarticle/details/' + this.currentUrlKeyInitial + '?_format=json';    
    this.rcycInternalApisService.getDrupal(apiUrlABCD).subscribe(
      (response: any=[]) => {
        if (response && response.length) { 
          this.checkEditorialHubArticleContent =  response;                      
        } else {
          this.brochureUrlCheck();
        }
      },
      err => {
        this.brochureUrlCheck();
        console.log('Check editorial article ABCD err');
        console.log(err);
      }
    );
  }


  brochureUrlCheck() {

    let eBrochureURL = window.location.pathname
    const apiUrlEBrochurePage = '/api/v1/ebrochure' + eBrochureURL + '?_format=json';
    this.rcycInternalApisService.getDrupal(apiUrlEBrochurePage).subscribe(
      (response: any=[]) => {
        if (response && response.length && response[0].field_url_key == eBrochureURL.split('/')[1] ) {
          this.checkEBrochureUrlKey = eBrochureURL.split('/')[1];
        } else {
          this.router.navigate(['/404']);
        }
      },
      err => {
        this.brochureUrlCheck();
        console.log('Check the error in ebrochure for',eBrochureURL );
        console.log(err);
      }
    );
  }


}
