<!-- RAQ Generic and Specific - Currently Implemented -->
<div *ngIf="isRequestQuoteStatus" id="rcyc-request-quote-bform" class="bodyContentOuter">
  <app-rcyc-hero-module *ngIf="apiResponseB" [responseArray]="apiResponseB"
  [pageName]="'requestquote'"></app-rcyc-hero-module>

  <div class="container lg:container-lg md:container-lg mb-7 lg:mb-10 mt-[40px]">
    <button *ngIf="previousUrlStatus && !voyagePageUrlStatus" (keyup.enter)="backToPrevPage()" (click)="backToPrevPage()" tabindex="0"
        class="unset-button text-primary hover:text-primary flex items-center">
      <img src="assets/img/arrow_left.svg" class="-ml-2 w-[18px]" alt="">
      Back to search results
    </button>
  </div>
  <app-rcyc-req-quote></app-rcyc-req-quote>
</div>
<!-- RAQ Offers & Programs - Not This one -->
<!-- REQUEST A QUOTE in Offers & Programs STARTS -->
<div *ngIf="!isRequestQuoteStatus" id="rcyc-request-quote-offerPrograms">
  <section class="content_section">
    <div class="l-container-wrapper">
      <div class="l-container intro-header leadinto">
        <div class="row is-8-4">
          <div *ngIf="apiResponseBStatus" class="row body-copy">
            <div class="headline-block c01_D intro-header leadinto">
              <!-- <h4 *ngIf="apiResponseB?.field_sub_header" id="pageSubTitleId" [title]="apiresponseB?.field_sub_header | convertHtml" [innerHtml]="apiResponseB?.field_sub_header"></h4>
              <h3 *ngIf="previousUrlStatus && !voyagePageUrlStatus" (keyup.enter)="backToPrevPage()" (click)="backToPrevPage()" tabindex="0" class="backBtn"> < BACK TO RESULTS </h3> -->
              <h1 *ngIf="apiResponseB?.title" id="pageTitleId" [title]="apiresponseB?.title | convertHtml" class="headline pageTitle" [innerHtml]="apiResponseB?.title"></h1>
              <p id="requiredField">Fields marked with a * are required</p>
            </div>
            <!-- <p *ngIf="apiResponseB?.field_summery" [innerHtml]="apiResponseB?.field_summery"></p> -->
          </div>
          <!-- left side -->
          <div *ngIf="apiResponseVoyageStatus" class="column is-3">
            <div class="feature-block flex-item">
              <img *ngIf="path" class="feature-block__image" [src]="path" alt="">
              <h3 *ngIf="apiResponseVoyage?.voyageEmbarkPort && apiResponseVoyage?.voyageDisembarkPort" class="media-object__headline media-object__headline--large" innerHtml="{{apiResponseVoyage?.voyageEmbarkPort}} TO {{apiResponseVoyage?.voyageDisembarkPort}}"> </h3>
              <p *ngIf="apiResponseVoyage?.voyageStartDate" class="feature-block__body" innerHtml="{{apiResponseVoyage?.voyageStartDate | date:'MMMM d' :'UTC'}}, {{apiResponseVoyage?.voyageStartDate | date:'y' :'UTC'}} | Voyage #{{voyageId}}"> </p>
            </div>
          </div>
          <!-- right side -->
          <div class="row is-12 lead_form_right">
            <div class="formErrorSec">
              <div id="formSubmitErrorId" *ngIf="formSubmitFailStatus">
                <span class="error">{{tokenErrorMsg}}</span>
              </div>
              <!-- <div *ngIf="specialOfferFormErrorMsg" id="formSubmitUIErrorId">
                <span class="error" [innerHTML]="specialOfferFormErrorMsg"></span>
              </div> -->
            </div>
            <form id="requestCallFormId" name="requestCallForm" [formGroup]="lead_gen_form" novalidate autocomplete="false">
              <fieldset>
                <div class="basic-form_group lead_form_field formFirstRow">
                  <div class="row is-4-4-4">
                    <div class="column is-4 form_fields">
                      <label class="basic-form_label form_label basic-form_label-required">First Name</label>
                      <input id="firstNameId" class="basic-form_input basic-form_input-half" type="text" formControlName="firstname"
                      placeholder="First Name" maxlength="30"
                      [ngClass]="{'error-borderBtm': (lead_gen_form.controls.firstname.invalid &&
                      (lead_gen_form.controls.firstname.dirty || lead_gen_form.controls.firstname.touched || specialOfferFormErrorMsg))&&
                     (lead_gen_form.controls.firstname.errors.required || (!lead_gen_form.controls.firstname.errors.required&&lead_gen_form.controls.firstname.errors.pattern) ) }"
                     (click)="specialCountryInputCloseBtnStatus=false;specialCountryInputClickStatus=false" />
                      <p class="errorptag" *ngIf="lead_gen_form.controls.firstname.invalid &&
                      (lead_gen_form.controls.firstname.dirty || lead_gen_form.controls.firstname.touched || specialOfferFormErrorMsg)">
                        <span *ngIf="lead_gen_form.controls.firstname.errors.required" class="error">This field is required</span>
                        <span *ngIf="!lead_gen_form.controls.firstname.errors.required&&lead_gen_form.controls.firstname.errors.pattern" class="error">Please enter a valid first name </span>
                      </p>
                    </div>
                    <div class="column is-4 form_fields">
                      <label class="basic-form_label form_label basic-form_label-required">Last Name</label>
                      <input id="lastNameId" class="basic-form_input basic-form_input-half" type="text" formControlName="lastname"
                      placeholder="Last Name" maxlength="60"
                      [ngClass]="{'error-borderBtm': ( lead_gen_form.controls.lastname.invalid &&
                      (lead_gen_form.controls.lastname.dirty || lead_gen_form.controls.lastname.touched || specialOfferFormErrorMsg) )&&
                     (lead_gen_form.controls.lastname.errors.required || (!lead_gen_form.controls.lastname.errors.required&&lead_gen_form.controls.lastname.errors.pattern) ) }"
                     (click)="specialCountryInputCloseBtnStatus=false;specialCountryInputClickStatus=false" />
                      <p class="errorptag" *ngIf="lead_gen_form.controls.lastname.invalid &&
                      (lead_gen_form.controls.lastname.dirty || lead_gen_form.controls.lastname.touched || specialOfferFormErrorMsg)">
                        <span *ngIf="lead_gen_form.controls.lastname.errors.required" class="error">This field is required</span>
                        <span *ngIf="!lead_gen_form.controls.lastname.errors.required&&lead_gen_form.controls.lastname.errors.pattern" class="error">Please enter a valid last name </span>
                      </p>
                    </div>
                    <div class="column is-4 form_fields">
                      <label class="basic-form_label form_label basic-form_label-required">Country/Region of
                        Residence</label>
                      <!-- <div class="countrySelectInner">
                        <select id="selectCountryId" class="basic-form_input basic-form_select" formControlName="country"
                        [ngClass]="{'error-borderBtm': (lead_gen_form.controls.country.invalid &&
                        (lead_gen_form.controls.country.dirty || lead_gen_form.controls.country.touched) && lead_gen_form.controls.country.errors.required) }">
                          <option id="selectCountryId_0" value="" disabled>Select Country/Region of Residence</option>
                          <option *ngFor="let county of countryListArray;let i=index" id="countryOptionId_{{i+1}}"
                            value="{{county.code}}">{{county.name}}</option>
                        </select>
                        <span class="icon-arrow-down"></span>
                      </div>
                      <div
                        *ngIf="lead_gen_form.controls.country.invalid && (lead_gen_form.controls.country.dirty || lead_gen_form.controls.country.touched) && lead_gen_form.controls.country.errors.required"
                        class="error">This field is required</div>		 -->

                        <div class="specialCountryOuter">
                          <div class="specialCountryInputOuter">
                            <div class="inputCloseOuter">
                              <!-- <input id="specialCountryInputId" class="basic-form_input basic-form_input-half" type="text"
                              placeholder="Select Country/Region of Residence" maxlength="60" [(ngModel)]="specialCountry"
                              [ngModelOptions]="{standalone: true}" (click)="specialCountryInputClickStatus=true"
                              [ngClass]="{'error-borderBtm': lnameWhitespaceError}" /> -->
                              <input id="specialCountryInputId" class="basic-form_input basic-form_input-half" type="text"
                              placeholder="Select Country/Region of Residence" maxlength="60" formControlName="country"
                              (click)="specialCountryInputClickStatus=true;specialCountryInputCloseBtnStatus=true"
                              [ngClass]="{'error-borderBtm': ( (lead_gen_form.controls.country.invalid &&
                              (lead_gen_form.controls.country.dirty || lead_gen_form.controls.country.touched || specialOfferFormErrorMsg))&&
                                (lead_gen_form.controls.country.errors.required ) )
                              ||( ( lead_gen_form.controls.country.valid && (lead_gen_form.controls.country.dirty || lead_gen_form.controls.country.touched) ) &&
                              !isSpecialCountrySelect)}" />
                              <span *ngIf="specialCountryInputCloseBtnStatus" class="icon-close" (click)="specialCountryInputCloseClick()"
                              [ngClass]="{'error-specialCountryCloseBtn': ( (lead_gen_form.controls.country.invalid &&
                              (lead_gen_form.controls.country.dirty || lead_gen_form.controls.country.touched || specialOfferFormErrorMsg))&&
                              (lead_gen_form.controls.country.errors.required ) )
                            ||( ( lead_gen_form.controls.country.valid && (lead_gen_form.controls.country.dirty || lead_gen_form.controls.country.touched) ) &&
                            !isSpecialCountrySelect)}"></span>

                              <p class="errorptag"
                                *ngIf="lead_gen_form.controls.country.invalid &&
                                (lead_gen_form.controls.country.dirty || lead_gen_form.controls.country.touched || specialOfferFormErrorMsg)">
                                <span *ngIf="lead_gen_form.controls.country.errors.required" class="error">This field is
                                  required</span>
                              </p>
                              <p class="errorptag"
                                *ngIf="lead_gen_form.controls.country.valid && (lead_gen_form.controls.country.dirty || lead_gen_form.controls.country.touched)">
                                <span *ngIf="!isSpecialCountrySelect" class="error">Please select a country</span>
                              </p>
                            </div>
                            <div *ngIf="specialCountryInputClickStatus" class="specialCountryListOuter">
                              <ul *ngIf="countryListArray?.length" class="specialCountryUl">
                                <li class="specialCountryLi" *ngFor="let item of countryListArray | specialCountrynameFilter: lead_gen_form.controls.country.value;let i=index"
                                  id="specialCountryListItem_{{i}}"
                                  [innerHTML]="item?.name | SpecialCountryHighlight: lead_gen_form.controls.country.value"
                                  (click)="specialCountryLiClick(item);selectCountryClick(item);">
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>


                    </div>
                  </div>
                </div>
                <div class="basic-form_group lead_form_field">
                  <div class="row is-4-4-4">
                    <div class="column is-4 form_fields">
                      <label class="basic-form_label form_label" [ngClass]="{'basic-form_label-required': zipcodeReqStatusDynamic}">
                        ZIP/Postal Code (Required for USA/Canada)</label>
                      <input id="zipCodeId" class="basic-form_input basic-form_input-half" type="text"
                        formControlName="zipcode" placeholder="ZIP/Postal Code" [maxlength]="zipcodeMaxLength"
                        (input)="checkZipcodeFormat($event)"
                        [ngClass]="{'error-borderBtm': ( lead_gen_form.controls.zipcode.invalid &&
                        (lead_gen_form.controls.zipcode.dirty || lead_gen_form.controls.zipcode.touched || specialOfferFormErrorMsg) )&&
                       (lead_gen_form.controls.zipcode.errors.required || (!lead_gen_form.controls.zipcode.errors.required&&lead_gen_form.controls.zipcode.errors.pattern)) }"
                       (click)="specialCountryInputCloseBtnStatus=false;specialCountryInputClickStatus=false" />
                      <p class="errorptag"
                        *ngIf="lead_gen_form.controls.zipcode.invalid &&
                        (lead_gen_form.controls.zipcode.dirty || lead_gen_form.controls.zipcode.touched || specialOfferFormErrorMsg) ">
                        <span *ngIf="lead_gen_form.controls.zipcode.errors.required" class="error">This field is
                          required</span>
                        <span *ngIf="!lead_gen_form.controls.zipcode.errors.required&&lead_gen_form.controls.zipcode.errors.pattern"
                          class="error">ZIP/Postal Code is not valid </span>
                      </p>
                    </div>
                    <div class="column is-4 form_fields">
                      <label class="basic-form_label form_label basic-form_label-required">Email</label>
                      <input id="emailId" class="basic-form_input basic-form_input-half" type="email" maxlength="60"
                      formControlName="email" placeholder="Email"
                      [ngClass]="{'error-borderBtm': ( lead_gen_form.controls.email.invalid &&
                      (lead_gen_form.controls.email.dirty || lead_gen_form.controls.email.touched || specialOfferFormErrorMsg) )&&
                     (lead_gen_form.controls.email.errors.required || (!lead_gen_form.controls.email.errors.required&&lead_gen_form.controls.email.errors.pattern) ) }"
                     (click)="specialCountryInputCloseBtnStatus=false;specialCountryInputClickStatus=false" />
                      <p class="errorptag" *ngIf="lead_gen_form.controls.email.invalid &&
                      (lead_gen_form.controls.email.dirty || lead_gen_form.controls.email.touched || specialOfferFormErrorMsg)">
                        <span *ngIf="lead_gen_form.controls.email.errors.required" class="error">This field is required</span>
                        <span *ngIf="!lead_gen_form.controls.email.errors.required&&lead_gen_form.controls.email.errors.pattern" class="error">
                            <!-- Please enter a valid email address (Example: johndoe@example.com) -->
                            Enter a valid email address (e.g. name@example.com)
                        </span>
                      </p>
                    </div>
                    <div class="column is-4 form_fields">

                      <div id="phoneFlagContainerId" class="phoneFlagContainer"
                        (clickOutside)="onClickedOutside($event)">
                        <label class="basic-form_label form_label"
                          [ngClass]="{'basic-form_label-required': phNumReqStatusDynamic}">Phone</label>
                        <div class="row is-3-9">
                          <div class="column is-3 phoneFlagOuter"
                            (keyup.enter)="flagCodeClickStatus = !flagCodeClickStatus"
                            (click)="flagCodeClickStatus = !flagCodeClickStatus;specialCountryInputCloseBtnStatus=false;specialCountryInputClickStatus=false" tabindex='0'
                            [ngClass]="{'error-borderBtm': (lead_gen_form.controls.phone.invalid &&
                            (lead_gen_form.controls.phone.dirty || lead_gen_form.controls.phone.touched || specialOfferFormErrorMsg))&&
                             (lead_gen_form.controls.phone.errors.required || (!lead_gen_form.controls.phone.errors.required&&lead_gen_form.controls.phone.errors.phoneIncorrect) ) }"
                             >
                            <span class="flagOuter"><img [src]="userCountryDetails?.flagUrl"
                                class="flagImg" /></span>
                            <span class="countryCodeOuter"> {{userCountryDetails?.dial_code}}</span>
                          </div>

                          <!-- Country List -->
                          <div *ngIf="flagCodeClickStatus" class="countryResultOuter">
                            <div *ngIf="flagCodeClickStatus" class="countryResultInner">
                              <!-- <input type="text" [(ngModel)]="countrySearch" (ngModelChange)="countrySearchChanged($event)" (ngModelChange)="searchCountryChange()"> -->
                              <div class="countrySearchOuter">
                                <input type="text" [(ngModel)]="countrySearch"
                                  [ngModelOptions]="{standalone: true}"/>
                              </div>
                              <ul *ngIf="phoneDataArray.length" class="countrySearchListOuter">
                                <li *ngFor="let item of phoneDataArray | countryNameSearchFilter : countrySearch; let i=index"
                                  (keyup.enter)="selectCountryClick(item)" (click)="selectCountryClick(item)"
                                  [ngStyle]="{'border-bottom':
                                  !countrySearch &&
                                  (
                                    ( i==2 && item?.code == 'CA') || (i==1 && item?.code == 'CA') ||
                                    ( (i==1  && item?.code == 'US' && permanentGeoLocation.code == 'CA') )
                                  )
                                  ? '1px solid #bfbfbf' : 'none'}" tabindex="0" class="eachPhoneCountryName"
                                  (focusout)="(i == (phoneDataArray.length - 1) ) ? flagCodeClickStatus = false: '' ">
                                  <span [innerHTML]="item?.name | CounrtyHighlightPipe : countrySearch"></span>
                                  ({{item?.dial_code}})
                                </li>
                              </ul>
                              <p class="noResultsFound letterSpacing"
                                *ngIf="!(phoneDataArray | countryNameSearchFilter : countrySearch).length"> No results
                                match "{{countrySearch}}"</p>
                            </div>
                          </div>
                          <!-- Country List -->

                          <div class="column is-9 phoneNumberInputOuter">
                            <!-- <input type="text" [(ngModel)]="phoneNumber" class="phoneNumberInput" /> -->
                            <!-- //[textMask]="{mask: mask}" (focusout)="phoneTBFocusout()" -->
                            <input id="phoneId" class="basic-form_input basic-form_input-half phoneNumberInput"
                              *ngIf="userCountryDetails?.defaultFormat" type="tel" formControlName="phone"
                              [(placeholder)]="userCountryDetails.defaultFormat"
                              [maxlength]="lead_gen_form?.controls?.phone?.errors?.phoneNoMaxLength"
                              [ngClass]="{'error-borderBtm': (lead_gen_form.controls.phone.invalid &&
                              (lead_gen_form.controls.phone.dirty || lead_gen_form.controls.phone.touched || specialOfferFormErrorMsg))&&
                             (lead_gen_form.controls.phone.errors.required || (!lead_gen_form.controls.phone.errors.required&&lead_gen_form.controls.phone.errors.phoneIncorrect) ) }"
                             (click)="specialCountryInputCloseBtnStatus=false;specialCountryInputClickStatus=false"
                             [textMask]="{mask: mask, guide:false}" />
                          </div>

                        </div>
                        <p class="errorptag"
                          *ngIf="lead_gen_form.controls.phone.invalid &&
                          (lead_gen_form.controls.phone.dirty || lead_gen_form.controls.phone.touched || specialOfferFormErrorMsg)">
                          <span *ngIf="lead_gen_form.controls.phone.errors.required" class="phoneNoError error">This field is
                            required</span>
                          <span *ngIf="!lead_gen_form.controls.phone.errors.required&&lead_gen_form.controls.phone.errors.phoneIncorrect"
                            class="phoneNoError error">Enter a valid phone number (e.g. {{ userCountryDetails?.defaultPhoneNumber }} ) </span>
                        </p>
                      </div>

                    </div>
                  </div>
                </div>
                <div *ngIf="apiResponseCStatus" class="row termsSection">
                  <div *ngIf="apiResponseC?.field_term1" class="eachTermSection">
                    <input id="emailOptId" type="checkbox" formControlName="isAgreeToReceiveMail" [checked]="emailReceiveCB"
                    (change)="emailReceiveCB=!emailReceiveCB;emailCBManual=!emailCBManual;specialCountryInputCloseBtnStatus=false;specialCountryInputClickStatus=false"
                    aria-labelledby="emailMarketing">
                    <p class="termContent" id="emailMarketing" [innerHtml]="apiResponseC?.field_term1"></p>
                  </div>
                  <div *ngIf="apiResponseC?.field_term2" class="eachTermSection">
                    <input id="phoneOptId" type="checkbox"  value="true" formControlName="isAgreeToContact" [checked]="phoneReceiveCB"
                    (change)="phoneReceiveCB=!phoneReceiveCB;phoneCBManual=!phoneCBManual;specialCountryInputCloseBtnStatus=false;specialCountryInputClickStatus=false"
                    aria-labelledby="phoneMarketing">
                    <p class="termContent" id="phoneMarketing" [innerHtml]="apiResponseC?.field_term2"></p>
                  </div>
                </div>
            </fieldset>
          </form>
          <div class="row declaration" *ngIf="apiResponseCStatus && apiResponseC?.field_summery" [innerHtml]="apiResponseC?.field_summery"></div>
          <div class="row">
            <div class="submit_btn specialRAQSubmitBtn">
              <!-- <input type="submit" id="requestQuoteSubmitId" (click)="leadGenSubmit()"
                       [disabled]="lead_gen_form?.controls?.firstname?.invalid ||
                       lead_gen_form.controls?.lastname?.invalid ||
                       lead_gen_form.controls?.country?.invalid ||
                       lead_gen_form.controls?.email?.invalid ||
                       lead_gen_form.controls?.zipcode?.invalid ||
                       lead_gen_form.controls?.phone?.errors?.phoneIncorrect==true ||
                       (lead_gen_form.controls?.phone?.errors?.phoneIncorrect!=false&&phNumReqStatusDynamic) ||
                       !userArea || !isSpecialCountrySelect" value="Submit"> -->
                  <input type="submit" id="requestQuoteSubmitId" (click)="leadGenSubmit()"
                      value="Submit">
              <!-- <input type="submit" id="requestQuoteSubmitId" (click)="leadGenSubmit()"
                       [disabled]="lead_gen_form.invalid ||
                       !userArea || !isSpecialCountrySelect" value="Submit"> -->
            </div>
          </div>

        </div>
        <div *ngIf="!voyageId" class="column is-3"></div>
        </div>
      </div>
    </div>
  </section>
</div>
<!-- REQUEST A QUOTE in Offers & Programs ENDS -->