<div id="rcyc-page-travelProfessionals-new" class="header_view bodyContentOuter">
  <app-rcyc-hero-module [responseArray]="apiResponseA"></app-rcyc-hero-module>
 <!-- Login Component -->
  <div class="section-1 mt-10 lg:mt-16">
    <div class="l-container-wrapper">
      <div class="l-containers flex justify-center ">

        <div class="flex flex-col md:flex-row mainOuter pr-[24px] pl-[24px] max-w-screen-xl w-full">

          <div class=" flex flex-col justify-between leftSection w-full md:w-3/5 h-max pr-0 lg:pr-[80px] md:pr-[20px] pr-[0px] pl-[0px]">
            <h2 class="heading text-[29px] lg:text-[49px] text-left p-0 m-0 md:pr-[60px] pr-[40px]"
              [innerHTML]="apiResponseCupdated?.field_port_list_sub_header">
            </h2>
            <div class="row is-12 emailActive">
            </div>
            <div class="formOuter">

              <div *ngIf="formSubmitFailStatus" class="errorSection">
                <div id="formSubmitErrorId">
                  <span class="error" [innerHTML]="formSubmitErrorMsg"></span>
                </div>

              </div>

              <form id="passwordupdateId" name="loginTravelProfessionalForm" [formGroup]="login_travel_professional"
                novalidate autocomplete="off">
                <div class="p lead_form_field ">

                  <!-- Username -->
                  <div class="row is-12 usernameSection lg:pt-[48px] pt-[32px]">
                    <div class="column relative is-12 form_fields md:mb-[9px] mb-[10px]">
                      <input id="loginModalEmailId"
                        class="  placeholder-black focus:placeholder-grey focus:placeholder-opacity-50 focus:placeholder:text-base hover:placeholder:text-sm "
                        type="email" placeholder="Login email" formControlName="email" maxlength="60" readonly
                        onmouseover="this.removeAttribute('readonly');" onfocus="this.removeAttribute('readonly');">
                        <label for="loginModalEmailId"
                    class="form-label absolute left-0 w-full !text-[#000] !font-[16px] !-top-[12px] !font-bold">Login email</label>
                      <div class="lg:min-h-[17px]">
                        <span *ngIf="!login_travel_professional.controls.email.valid &&
                      login_travel_professional.controls.email.touched &&
                        !emailErr" class="error" [innerHTML]="apiResponseGError?.field_email_required"></span>
                        <span *ngIf="emailErr" class="error" [innerHtml]="emailValidationMsg"></span>
                      </div>
                    </div>
                  </div>

                  <!-- Password -->
                  <div class="row is-12 mt-6 lg:mt-0">
                    <div class="column relative is-12 form_fields lg:mb-[17px] md:mb-[0px] mb-[30px]">
                      <input id="loginModalPwdId"
                        class=" placeholder-black focus:placeholder-grey focus:placeholder-opacity-50 focus:placeholder:text-base hover:placeholder:text-sm"
                        type="password" placeholder="Password" formControlName="password" minlength="1" maxlength="15"
                        (keyup.enter)="submitLogin()"
                        readonly onmouseover="this.removeAttribute('readonly');"
                        onfocus="this.removeAttribute('readonly');">
                        <label for="loginModalPwdId"
                    class="form-label absolute left-0 w-full !text-[#000] !font-[16px] !-top-[12px] !font-bold">Password</label>
                      <div class="md:min-h-[17px]">
                        <span *ngIf="login_travel_professional.controls.password.value &&
                      login_travel_professional.controls.password.invalid &&
                      login_travel_professional.controls.password.touched " class="error  "
                          [innerHTML]="apiResponseGError?.field_email_required"></span>
                        <span *ngIf="pwdErr" class="error" [innerHtml]="passwordValidationMsg"></span>
                      </div>
                    </div>
                  </div>

                  <!-- Remember me -->
                  <div class="row is-12 rememberForgotSection">

                    <div class="flex lg:justify-start items-center">

                      <div class=" w-1/2 md:w-1/3  rememberMeOuter top-4 ">
                        <input id="loginModalRememberMeId" type="checkbox" formControlName="rememberMe"
                          [checked]="rememberMeCB" (keypress)="rememberMe($event)" (click)="rememberMe($event)">
                        <span class="checkBoxText ">Remember Me</span>
                      </div>
                      <div class="w-1/2 md:w-1/3   forgotPwdOuter uppercase underline decoration-[#3b68b3] ">
                        <a [routerLink]="['/reset-password']" class="hover:opacity-50 transition-opacity whitespace-nowrap">Forgot Password?</a>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="row is-12 submitOuter">
              <!-- -->
              <div class="column is-6 form_fields submitInner lg:mt-[48px] mt-[40px] md:mt-[16px] ">
                <div class="button-container">
                  <input *ngIf="!loadApiStatus" class="button signInBtn md:w-auto w-full bg-black hover:bg-white text-white hover:text-black px-8 pt-4 pb-[38px]  " type="submit"
                    id="loginModalSigninId" (click)="submitLogin()" [disabled]="emailErr || !emailValidStatus || pwdErr || !pwdValidStatus" value="Sign in" style="line-height: 1.5;">
                  <div *ngIf="loadApiStatus" class="row is-12 btnLoader">
                    <img src="assets/components/images/loading.gif" class="formLoader">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="!emailVerificationError" class=" or block md:hidden flex mb-4 container mt-[48px] mb-[48px] !pl-0 !pr-0">
            <div class="w-1/3 "> <hr class="h-0.5 mx-auto my-4 bg-gray-400 border-0  "></div>
            <div class="w-1/3 text-center "> <h3 class="!mb-0 mt-2 text-gray-600">OR</h3> </div>
            <div class="w-1/3 "> <hr class="h-0.5 mx-auto my-4 bg-gray-400 border-0  "></div>
          </div>

          <div
            class=" rightSection w-full md:w-2/5 md:border-[#6464] md:border-l-2 md:border-solid h-max pr-0  pr-[0px] md:pl-[20px] lg:pl-[80px] pl-[0px]">
            <h2 class="heading text-[29px] lg:text-[49px] text-left p-0 m-0 md:pb-[48px] pb-[32px] "
              [innerHTML]="apiResponseCupdated?.title">
            </h2>
            <div>
              <div class="row is-12 flex justify-center">
                <div class="column is-12 md:pb-[18px] pb-[2px] flex justify-center md:justify-start">
                  <img [src]="apiResponseCupdated?.field_image" *ngIf="apiResponseCupdated?.field_image" />
                </div>
              </div>

              <!-- Create Account button -->
              <div class="row is-12 submitOuter  ">
                <div class="column is-6 form_fields  ">
                  <button class="md:w-auto w-full  justify-end bg-white text-black border border-black px-8 py-4  hover:bg-black hover:text-white"
                    (click)="navigateToAbout()">Create Account</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="md:pt-[128px] pt-[80px] pb-[10px] md:pb-0">
      <app-rcyc-50-50 *ngIf="apiResponseC" [data]="apiResponseC"></app-rcyc-50-50>
    </div>
    
  </div>