import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../rcyc-config/config';

@Injectable()
export class RcycPortsService {

    API_BASE_URL: any =   AppConfig.API_BASE_URL;
    ELASTIC_API_BASE_URL: any =   AppConfig.ELASTIC_API_BASE_URL;

  constructor(

        private http: HttpClient
  ) {

    }

    /* =====================================================================================================================================
    Funtion to call All Apis for Ports
    * ====================================================================================================================================*/

    ports(apiUrl: string) {

        const url = this.API_BASE_URL + apiUrl;
        return this.http.get(url);
    }

    /* =====================================================================================================================================
    Funtion to call All Elastic Apis for Ports
    * ====================================================================================================================================*/

    portsElastic(params, apiUrl) {

        // console.log("portCode IN SERVICE");
        // console.log(portCode);
        /*let elasticParams = {

            countryCode: params.countryCode,
            portCodes: params.portCodes,
        };*/
        const elasticParams = params;
        const url = this.ELASTIC_API_BASE_URL + apiUrl;
    return this.http.post(url, elasticParams);
    }

    /* =====================================================================================================================================
    Funtion to call Map image from Drupal
    * ====================================================================================================================================*/

    drupalGetMapImage(apiUrl: string) {

        const url = this.API_BASE_URL + apiUrl;
        return this.http.get(url);

    }

    /* =====================================================================================================================================
    Funtion to call grid image from Drupal
    * ====================================================================================================================================*/

    getCruiseImageDrupal(apiUrl: string) {
        const url = this.API_BASE_URL + apiUrl;
        return this.http.get(url);

    }

    getDefaultTableViewDrupal(apiUrl: string) {
      const url = this.API_BASE_URL + apiUrl;
      return this.http.get(url);

  }
  //   Excursion by port code
  getExcursionByPort(apiUrl){
    const url = this.ELASTIC_API_BASE_URL + apiUrl;
    return this.http.get(url);
  }
}
