import { Component, OnInit, ViewChild, HostListener, Input, AfterViewInit, OnChanges, OnDestroy, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { RcycCommonService } from '../../../rcyc-services/rcyc-common/rcyc-common.service';
import { AppConfig } from '../../../rcyc-config/config';
import { RcycInternalApisService } from '../../../rcyc-services/rcyc-internal-apis/rcyc-internal-apis.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

declare let $f: any;
declare let $: any;
import { Rcyc3playVimeoService } from '../../../rcyc-services/rcyc-3play-vimeo/rcyc-3play-vimeo.service';
@Component({
  selector: 'app-rcyc-hero-module',
  templateUrl: './rcyc-hero-module.component.html',
  styleUrls: ['./rcyc-hero-module.component.css'],
  providers: [
    Rcyc3playVimeoService
  ]
})
export class RcycHeroModuleComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy, AfterContentChecked {

  public innerWidth: any;
  IMG_BASE_URL: any = AppConfig.IMG_BASE_URL;
  heroVideo: any;
  isMouseHover = false;
  id: any;
  showArrow: any = false;
  showPause: any = false;
  clickedPause: any = false;
  showPlay: any = false;
  showButtons: any = true;
  arrowSize: any = '20px';
  device: any = 'desk';
  carouselImageArray: any = [];
  imageContentArray: any = [];
  field_image_alt_content: any;
  field_image_title_content: any;
  saveHeroVideoImageArray: any = [];
  imageArray = [];
  isCorrectOrientationAndSize: any;
  isVideoPlaying = false;
  @ViewChild('imgSlider') imgSlider: any;
  @ViewChild('vimeoVolumeRef') vimeoVolumeRef: any;
  @ViewChild('nguCarouselHomePage') nguCarouselHomePage: any;
  @ViewChild('nguHeroCarouselContent') nguHeroCarouselContent: any;
  @ViewChild('nguHeroCarouselControls') nguHeroCarouselControls: any;
  @Input() responseArray: any;
  @Input() pageName: string;
  @Input() hideTextOnBanner: boolean = false;
  showBannerCarousel = false;
  isCCEnabled = false;
  isADEnabled = false;
  videoCurrentTime = 0;
  currentUrlPathAsArray: any;
  currentUrlKey: any;
  snapshotUrl: any;
  ADClickStatus = false;
  playPauseCount: number = 0;
  current3PlayPuginId: string;
  current3playVimeoPlayer: any;
  vimeoMuteClickStatus = false;
  isInitialADClickStatus = false;
  loadedVimeoId: string;
  loaded3PlayMFId: string;
  loaded3PlayRandomHashId: string;
  loaded3PlayTargetPluginId: string;
  otherADClickStatus = false;
  playPromise: any;
  pausePromise: any;
  ccClickStatus = false;
  deviceType: string;
  muteStatus: any = true;
  textTracksStatus: any;
  deviceView:string;
  field_banner_url:any;
  videoId: Number;
  videoData: any = {};
  subHeaderDataArray_A: any;
  public heroCarouselSettings: any;

  constructor(
    private sanitizer: DomSanitizer,
    private rcycCommonService: RcycCommonService,
    private rcycInternalApisService: RcycInternalApisService,
    private activatedRoute: ActivatedRoute,
    private rcyc3playVimeoService: Rcyc3playVimeoService,
    private router: Router,
    private changeDetector: ChangeDetectorRef
  ) {

    this.constructorFn();
  }

  ngOnInit() {

    this.onInitFn();
  }

  ngOnChanges() {
    this.imageContentArray = [];
    this.getHeroVideoAndImage();
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy() {
    // $('.p3sdk-target').css({ 'visibility': 'hidden' });
    /* $('#threePlayMediaAD').css({ 'display': 'none' });
    document.getElementById('3playmediavimeomain').remove(); */

    if(this.current3PlayPuginId) {
      if(this.isCCEnabled) { //If ccEnable, ccDisable it
        this.isCCEnabled=false;
        this.rcyc3playVimeoService.disableCcVimeo();
      }
      
      if(!this.muteStatus) { //If unmute, mute it
        this.muteStatus=true;
        this.vimeoMuteClickNew();
      }
      if(!this.isVideoPlaying) { //If pause, play it
        this.isVideoPlaying=true;
        this.clickedVideoPlay();
      }
      if(this.ADClickStatus) { //If pause, play it
        this.ADClickStatus=false;
        this.rcyc3playVimeoService.setMuteStatus(true).then(
          (status)=>{
            this.muteStatus = status;
          }
        )
      }
    }   
  }

  constructorFn() {
    this.snapshotUrl = this.activatedRoute.snapshot as any;
    this.currentUrlPathAsArray = this.snapshotUrl._urlSegment.segments;
    if (this.currentUrlPathAsArray.length) {
      this.currentUrlKey = this.currentUrlPathAsArray[0].path;
    }

  }

  /* Function call on scroll */
  // @HostListener('window:scroll', ['$event']) 
  // scrollHandler(event) {
  //   if(document.querySelector('.btnOverHero')) {
  //     this.threePlayADStyle();  
  //   }
  // }

  /* Function for checking Device type */

  devicetype() {
    this.checkOrientationAndSizeMethod();
    if (this.isCorrectOrientationAndSize) {
      clearInterval(this.id);
      if (!this.heroVideo) {
        this.device = 'mobile';
      } else {
        this.device = 'desk';
        this.arrowSize = '20px';
      }
    } else {
      this.device = 'mobile';
      this.arrowSize = '15px';
    }

  }

  /* Function for checking Orientation and Size are Correct */

  private checkOrientationAndSizeMethod() {
    if (this.innerWidth > 768 && this.orientationCheck() === 'landscape') {
      this.isCorrectOrientationAndSize = true;
    } else {
      this.isCorrectOrientationAndSize = false;
    }
  }

  /* Function for checking Orientation of Device */

  orientationCheck() {
    if (window.innerHeight > window.innerWidth) {
      return 'portrait';
    }
    if (window.innerHeight < window.innerWidth) {
      return 'landscape';
    }
  }


  /* Funtion to Execute on Mouse Hover */

  enter() {
    // this.addCurrentHref();
    if (this.isCorrectOrientationAndSize) {
      if (this.carouselImageArray.length > 1) {
        if (this.clickedPause === false) {
          clearInterval(this.id);
          this.id = setInterval(() => {
            this.imgSlider?.onSlide(1);            
          }, 5000);
        }
      }
    }
  }

  /* Funtion to Execute on Mouse leave */

  leave() {
    if (this.isCorrectOrientationAndSize) {
      if (this.carouselImageArray.length > 1) {
        if (this.id) {
          clearInterval(this.id);
        }
      }
    }
  }

  /* Funtion to Execute on Play / Pause  */

  clickedPauseButton() {
    if (this.isCorrectOrientationAndSize) {
      clearInterval(this.id);
      this.clickedPause = true;
    }
  }

  clickedPlayButton() {
    if (this.isCorrectOrientationAndSize) {
      this.clickedPause = false;
    }
  }


  /*  Function to execute on Slideshow Navigation Button Click */

  leftArrowClick() {
    clearInterval(this.id);
    this.imgSlider.onSlide(-1);    
    this.enter();
  }

  rightArrowClick() {
    clearInterval(this.id);
    this.imgSlider.onSlide(1);    
    this.enter();
  }

  keyUpControl(event, clickedPause) {
    if (event.keyCode === 13) {
      if (clickedPause === true) {
        this.clickedPlayButton();
        clearInterval(this.id);
        this.enter();
      } else {
        this.clickedPauseButton();
      }
    }
  }

  keyUpVideoControl(event, isVideoPlaying) {
    if (event.keyCode === 13) {
      if (isVideoPlaying) {
        this.clickedVideoPause();
      } else {
        this.clickedVideoPlay();
      }
    }
  }

  clickedVideoPause() {
    this.otherADClickStatus = true;
    // this.pauseVimeo();
    const ADElement_TabIndex: any = document.querySelector('#heroVideoOuterId .adOuter button.mycroft-audio-description-snippet-toggle');
    if (ADElement_TabIndex) {
      ADElement_TabIndex.tabIndex = '-1';
    }
    this.rcyc3playVimeoService.pauseVimeo();
    this.isVideoPlaying = false;
    this.playPauseCount = this.playPauseCount + 1;
  }

  clickedVideoPlay() {
    this.otherADClickStatus = true;
    // this.playVimeo();
    const ADElement_TabIndex: any = document.querySelector('#heroVideoOuterId .adOuter button.mycroft-audio-description-snippet-toggle');
    if (ADElement_TabIndex) {
      ADElement_TabIndex.tabIndex = '0';
    }
    if (this.rcyc3playVimeoService.current3playVimeoPlayer) {
      this.rcyc3playVimeoService.playVimeo();
    }
    this.isVideoPlaying = true;
    this.playPauseCount = this.playPauseCount + 1;
  }


  enablePlay() {
    // setTimeout(() => {
    //   this.isVideoPlaying = true;
    // }, 5000);
    this.isVideoPlaying = true;


  }


  /* Funtion to Execute on Window Resize Event */


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.saveHeroVideoImageArray.length) {
      this.addToCarousel(this.saveHeroVideoImageArray);
    }
    this.device = 'desk';
    this.devicetype();
    if (this.innerWidth > 769) {
      this.deviceType = 'desk';
      this.deviceView='desktopView';
    } else {
      this.deviceType = 'mobile';
      this.deviceView='mobileView';
    }
    if (!this.heroVideo) {
      this.carouselControlsTop();
    }
  }


  /* Funtion for getting hero Multiple or single images and Video */

  getHeroVideoAndImage() {
    if (this.responseArray?.length) {
      this.showBannerCarousel = true;
      this.device = 'desk';
      this.saveHeroVideoImageArray = this.responseArray;
      this.addToCarousel(this.responseArray);
      this.responseArray.map(key => {
        this.findAltContentMethod(key);
        this.findTitleContentMethod(key);
        this.findHrefContentMethod(key);
        this.imageContentArray.push({
          'field_image_alt_content': this.field_image_alt_content,
          'field_image_title_content': this.field_image_title_content,
          'field_banner_url': this.field_banner_url
        });
      });

      this.responseArray.map(key => {
        if (key.field_video) {

          key.field_video = key.field_video.split('?')[0];
          this.videoId = key.field_video.split('/').pop();

          let tempCtaUrl = key.field_com_cta_link;
          if (tempCtaUrl && tempCtaUrl?.indexOf('internal:') !== -1) {
            tempCtaUrl = tempCtaUrl?.split(':')[1];
          }

          this.videoData = {
            title: key.title,
            eyebrow: key.field_com_eyebrow,
            url: tempCtaUrl,
            cta: (this.pageName!='yachtLanding'? key.field_com_cta_title: null),
          }
          // console.log(key.field_video, 'key.field_video ======>>>>>');
          // FOR TEST-1
          // key.field_video='https://player.vimeo.com/video/313209156?autoplay=true&amp;loop=true&amp;muted=true';

          this.rcycCommonService.checkHeroVideoUrl(key.field_video).subscribe(value => {
            // console.log('checkHeroVideoUrl', value);
            if (value.status === 200) {
              this.current3PlayPuginId = key.field_3_play_id;
              this.loaded3PlayMFId = key.field_3_play_mf_id;
              // this.loaded3PlayMFId='3513574';
              // this.current3PlayPuginId='tpm-plugin-5mgdacc1-313209156';

              // this.current3playVimeoPlayer=new Player('tpm-plugin-5mgdacc1-313209156', {});
              let splittedVimeoId: any = this.current3PlayPuginId.split('-');

              this.loadedVimeoId = splittedVimeoId[splittedVimeoId.length - 1];
              this.loaded3PlayRandomHashId = splittedVimeoId[splittedVimeoId.length - 2];
              this.loaded3PlayTargetPluginId = '3p-plugin-target-' + this.loaded3PlayMFId + '-' + this.loaded3PlayRandomHashId;
              // console.log(key.field_3_play_id, 'key.field_3_play_id ++++++');
              this.setHeroVideo(key.field_video);

              const add3PlayScriptParam = {
                loaded3PlayMFId: this.loaded3PlayMFId,
                loaded3PlayTargetPluginId: this.loaded3PlayTargetPluginId,
                loadedVimeoId: this.loadedVimeoId,
                current3PlayPuginId: this.current3PlayPuginId,
              }
              this.rcyc3playVimeoService.add3PlayScript(add3PlayScriptParam);

              let ADTitleTimeout;
              clearTimeout(ADTitleTimeout);
              ADTitleTimeout = setTimeout(() => {
                const ADElement_TabIndex: any = document.querySelector('#heroVideoOuterId .adOuter button.mycroft-audio-description-snippet-toggle');
                if (ADElement_TabIndex) {
                  ADElement_TabIndex.tabIndex = '0';
                }
              }, 4000);
              
              return;
            }
          }, error => { console.log(error) });
        } else {
          this.devicetype();
        }
      });
    } else {
      this.showBannerCarousel = false;
    }
  }

  /* Funtion to add Responsive Images */
  private addToCarousel(res: any) {
    const imageArray = this.loadResponsiveImage(res, this.innerWidth);
     this.carouselImageArray = imageArray.map((item,key) => {

      let tempCtaUrl = res[key].field_com_cta_link;
      if (tempCtaUrl && tempCtaUrl?.indexOf('internal:') !== -1) {
        tempCtaUrl = tempCtaUrl?.split(':')[1];
      }

      return {
        url: item,
        caption: this.imageContentArray[`${key}`]?.field_image_alt_content,
        title: res[key].title ? res[key].title : this.imageContentArray[`${key}`]?.field_image_title_content,
        eyebrow: res[key].field_com_eyebrow,
        cta: res[key].field_com_cta_title,
        href: tempCtaUrl,
        clickAction: () => this.sliderImgClick(),
      }
     })
    if (this.carouselImageArray.length > 1) {
      this.showArrow = true;
    }
  }

  /* Funtion to add Hero Video */
  private setHeroVideo(url) {
    this.heroVideo = this.sanitizer.bypassSecurityTrustResourceUrl(url+'?autoplay=1&autopause=0&loop=1&texttrack=null&muted=1&controls=0')
    this.playerInit();
  }

  playerInit() {
    setTimeout(
      () => {
        
        if (this.current3PlayPuginId) {
          this.rcyc3playVimeoService.setPlayerInstance(this.current3PlayPuginId);
          this.rcyc3playVimeoService.toggleLoop(true);
          this.rcyc3playVimeoService.setVolume0();
          
          setTimeout(()=>{
            this.isCCEnabled = !this.isCCEnabled;
            this.togleClosedCaption()
            this.rcyc3playVimeoService.setMuteStatus(this.muteStatus).then((status) => {
              if(!this.muteStatus){
                this.rcyc3playVimeoService.setVolume(0.5);
              }
              this.muteStatus = status
              // console.log(this.muteStatus, 'muteStatus');
              this.getEndedAndReCallfuntions();
            });
          },2500)
        }
      }, 100
    )
  }

  getEndedAndReCallfuntions() {
    // console.log('function called once');
    this.rcyc3playVimeoService.getEnded().then(
      (response) => {
        // console.log(response, 'getEnded');
        let videoId = Number(this.videoId);
        this.rcyc3playVimeoService.loadVideo(videoId).then(
          (response) => {
            // console.log(response);
            this.playerInit();// call recursively to loop manually            
          }
        ).catch(
          (error) => {
            console.log(error);
          }
        );
      }
    );
  }
  /* Funtion to add Title of Image */
  private findTitleContentMethod(key: any) {
    if (key.field_image_title_content === undefined || key.field_image_title_content === null || key.field_image_title_content === '') {
      this.field_image_title_content = '';
    } else {
      this.field_image_title_content = key.field_image_title_content;
    }
  }
  /* Funtion to add Alt Content of Image */
  private findAltContentMethod(key: any) {
    if (key.field_image_alt_content === undefined || key.field_image_alt_content == null || key.field_image_alt_content === '') {
      this.field_image_alt_content = '';
    } else {
      this.field_image_alt_content = key.field_image_alt_content;
    }
  }

   /* Funtion to add Href Content of Image */
   private findHrefContentMethod(key: any) {
    if (!key.field_banner_url) {
      this.field_banner_url = '';
    } else {
      this.field_banner_url = key.field_banner_url;
    }
  }

  /* Funtion to load responsive Images*/

  loadResponsiveImage(key, width) {
    this.imageArray = [];
    key.map(res => {
      if (width <= 375) {
        this.imageArray.push(this.whetherImagePresent(res, 375));
      } else if (width > 375 && width <= 768) {
        this.imageArray.push(this.whetherImagePresent(res, 768));
      } else if (width > 768 && width <= 1440) {
        this.imageArray.push(this.whetherImagePresent(res, 1440));
      } else if (width > 1440 && width <= 1920) {
        this.imageArray.push(this.whetherImagePresent(res, 1920));
      } else if (width > 1920) {
        this.imageArray.push(this.whetherImagePresent(res, 1920));
      } else {
        this.imageArray.push(this.IMG_BASE_URL + key.field_images); //  Default image
      }
    });
    return this.imageArray;
  }

  /* Funtion to check responsive Images present*/

  whetherImagePresent(res, width) {
    const imageSizeArray = [375, 768, 1440, 1920];
    for (let i = imageSizeArray.indexOf(width); i < imageSizeArray.length;) {
      let keyCheck;
      switch (i) {
        case 0: keyCheck = res.field_image_375px;
          break;
        case 1: keyCheck = res.field_image_768px;
          break;
        case 2: keyCheck = res.field_image_1440px;
          break;
        case 3: keyCheck = res.field_image_1920px;
          break;
      }
      if (!keyCheck) {
        i++;
        if (keyCheck === res.field_image_1920px) {
          return this.IMG_BASE_URL + res.field_images; //  Default image
        }
      } else {
        return this.IMG_BASE_URL + keyCheck;
      }
    }

  }

  ngAfterViewInit(): void {
    // this.add3PlayScript();


  }

  // Function for vimeo CC
  togleClosedCaption() {
    this.otherADClickStatus = true;
    this.ccClickStatus = true;    
    this.isCCEnabled = !this.isCCEnabled;
    // For Enable CC
    if (this.isCCEnabled) {      
      this.rcyc3playVimeoService.enableCcVimeo();
    } else {  // For disable CC      
      this.rcyc3playVimeoService.disableCcVimeo();
    }
  }

  carouselControlsTop() {
    if (this.nguHeroCarouselControls) {
      const top = this.nguCarouselHomePage?.carousel.offsetHeight / 2 + this.nguHeroCarouselContent?.nativeElement.offsetHeight / 2 - 40;
      this.nguHeroCarouselControls.nativeElement.style.top = `${top}px`;
    }
  }

  onInitFn() {
    setTimeout(()=>{
      if (this.rcyc3playVimeoService.current3playVimeoPlayer) {
        this.rcyc3playVimeoService.pauseVimeo();
        this.rcyc3playVimeoService.playVimeo();
      }
      $("#outerAD").removeClass("disable3play");
      $("#outerAD").removeClass("disable3play");
      if (!this.heroVideo) {
        this.carouselControlsTop();
        $("#carouselControls").removeClass('hidden')
      }
    },3000);
    if (window.innerWidth < 769) {
      this.deviceView='mobileView';      
    } else {
      this.deviceView='desktopView';     
    }    
    this.device = 'desk';
    this.innerWidth = window.innerWidth;
    this.getHeroVideoAndImage();

    this.heroCarouselSettings = {
      grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
      slide: 1,
      speed: 400,
      interval: 0,
      point: {
        visible: false
      },
      load: 3,
      touch: true,
      loop: true,
      custom: 'banner'
    };
  
    // Changing subheader A value
    this.rcycCommonService.subHeaderDataArray_A
      .subscribe(
        params => {
          this.subHeaderDataArray_A = params;
        }
      );
  }

  threePlayinit() {
    $('#slideIndex').each(function (index, elm) {
      $($(elm).find('a')).attr('tabindex', 0);
    });   
    
  }


  sliderImgTitleChange() {
    if (document.querySelector('#slideIndex a.selected')) {
      document.querySelector('#slideIndex a.selected').setAttribute("title", this.imageContentArray[this.imgSlider.slideIndex].field_image_title_content);
    }
  }

  // Function for vimeo AD
  ADClick() {   
    this.rcyc3playVimeoService.setMuteStatus(this.muteStatus).then(
      (status)=>{
        this.muteStatus = status;    
      }
    );
    this.ADClickStatus = !this.ADClickStatus;
    if (this.ADClickStatus) {
      this.isInitialADClickStatus = true;
    }    
  }


  vimeoMuteClickNew(){
    this.rcyc3playVimeoService.setMuteStatus(!this.muteStatus).then(
      (status)=>{
        this.muteStatus = status;
        if(!this.muteStatus){
          this.rcyc3playVimeoService.setVolume(0.5);
        }
        // console.log(this.muteStatus)
      }
    )
  }
  
  // Function to control vimeo volume
  setVimeoVolume(volume) {    
    let range = volume / 10;
    this.rcyc3playVimeoService.setVolume(range);
    if(document.querySelector('#slideIndex a.selected')) {
      document.querySelector('#slideIndex a.selected').setAttribute("title", this.imageContentArray[this.imgSlider.slideIndex].field_image_title_content);      
    }
  }

  sliderImgClick() {
    let navigateUrl:string=this.imageContentArray[this.imgSlider.slideIndex].field_banner_url;
    // const navigateUrl = 'http://localhost:4200/';
    if(navigateUrl) {
      this.router.navigate([navigateUrl]);
    }
   
    
  }
  

  addCurrentHref() {
    if(document.querySelector('#slideIndex a.selected')) {
      if(this.imageContentArray[this.imgSlider.slideIndex].field_banner_url) {
        document.querySelector('#slideIndex a.selected').setAttribute("href", this.imageContentArray[this.imgSlider.slideIndex].field_banner_url);      
      } else {
        document.querySelector('#slideIndex a.selected').removeAttribute("href");      
      }     
    }
  }

}
