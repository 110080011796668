import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { RcycViewBrochureComponent } from '../../rcyc-req-brochure-redesign/rcyc-view-brochure/rcyc-view-brochure.component';

@Component({
  selector: 'app-rcyc-brochure-50-50',
  templateUrl: './rcyc-brochure-50-50.component.html',
})

export class RcycBrochure5050Component implements OnInit {
  @Input() data: any
  @Input() voyageRegion: any
  sectionContentData : any
  variant = 'dark'
  brochure_key: any;
  @ViewChild('viewBrochureModal', { static: false }) viewBrochureModal: RcycViewBrochureComponent;
  constructor() {}

  ngOnInit(): void {
    this.sectionContentData = {
      eyebrow: this.data.field_com_eyebrow,
      title: this.data.title,
      text: this.data.field_com_description,
      voyageRegion: this.voyageRegion
    }
    this.brochure_key = this.data?.field_url_key;
  }

  viewBrochureClick() {
    this.viewBrochureModal.modalOpen();
  }
}
