import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { RcycCommonService } from './../../../rcyc-services/rcyc-common/rcyc-common.service';
import { RcycVoyagesService } from '../../rcyc-voyages/rcyc-voyages.service';

@Component({
  selector: 'app-rcyc-featured-excursions',
  templateUrl: './rcyc-featured-excursions.component.html',
  providers: [RcycVoyagesService]
})

export class RcycFeaturedExcursionsComponent implements OnInit {
  @Input() data: any;
  @Input() variant: any;
  @Input() imageScrollPosition: string;
  @Input() hideButtons: boolean = false;
  @Input() parent: string = 'none';
  highlight: any;
  styleClasses: string = '';

  constructor(
    private rcycCommonService: RcycCommonService,
    private changeDetector: ChangeDetectorRef,
  ) {
  }
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
    this.calcStyles();
  }

  ngOnInit(): void {
    this.data.slider = this.data.slider && this.data.slider.length ? this.data.slider : [];
    this.calcStyles();
    if ((this.variant === 'grid' || this.variant === 'shore_excursion')) {
      this.getCruiseImageDrupal();
    }
    let voyageEmbarkPort_new;
    let voyageDisembarkPort_new;
    if (this.data?.voyageEmbarkPort) {
      voyageEmbarkPort_new = this.data?.voyageEmbarkPort
        .replace(/[^a-zA-Z-]/g, '')
        .replace(/\s/g, '')
        .toLowerCase();
    }
    if (this.data?.voyageDisembarkPort) {
      voyageDisembarkPort_new = this.data?.voyageDisembarkPort
        .replace(/[^a-zA-Z-]/g, '')
        .replace(/\s/g, '')
        .toLowerCase();
    }
  }

  calcStyles() {
    let classNames: string = '';
    switch (this.variant) {
      case 'shore_excursion':
        classNames = 'xl:p-8 p-6';
        break;
      case 'grid':
        classNames = 'flex-col 2xl:p-8 p-4 grow-0';
        break;
      case 'grid-small':
        classNames = 'flex-col lg:p-8 p-4';
        break;
      default:
        break;
    }
    this.styleClasses = classNames;
  }

  // Function to get Drupal API
  getCruiseImageDrupal() {
    let drupalImageArray: any = this.rcycCommonService.getSessionData('drupalImageArray');
    if (drupalImageArray) {
      this.getVoyageMapImage();
    } else {
      const Api_Url = '/api/v1/voyages/thumbnails?_format=json';
      this.rcycCommonService.drupalApisGet(Api_Url).subscribe(
        (response: any) => {
          this.rcycCommonService.setSessionData('drupalImageArray', response);
          this.getVoyageMapImage();
        },
        (err) => {
          console.log('Error on Getting Cruise Images');
          console.log(err);
        },
      );
    }
  }

  getVoyageMapImage() {
    let drupalImageObjectNot = {};
    const drupalImageArray = this.rcycCommonService.getSessionData('drupalImageArray');
    drupalImageArray.forEach(element => {
      drupalImageObjectNot[element.field_voyages] = { field_voyage_thumbnail: element.field_voyage_thumbnail, title: element.title };
    });

    this.rcycCommonService.drupalApisGet('/api/v1/voyages/' + this.data?.voyageId + '/tab/itineraryoverview?_format=json').subscribe(
      (response: any) => {
        if (response && response.length > 0) {
          const res = response && response.length > 0 && Object.keys(response).map(function (key) {
            return response[key];
          });
          if (res.length) {
            this.data.slider.push(res[0].field_voyage_image);
            if (drupalImageObjectNot) {
              this.data.slider.push([drupalImageObjectNot[this.data?.voyageId].field_voyage_thumbnail]);
            }
          }
        } else {
          drupalImageObjectNot[this.data?.voyageId]?.field_voyage_thumbnail ?? this.data.slider.push([drupalImageObjectNot[this.data?.voyageId]?.field_voyage_thumbnail]);
        }
      },
      err => {
        console.log(err);
      }
    );
  }
}
