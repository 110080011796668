<div [ngClass]="{
  'container': data.field_com_5050_variant === 'video_variant' || data.field_com_5050_variant === 'blue_background_variant',
  'lg:container': data.field_com_5050_variant !== 'video_variant' || data.field_com_5050_variant !== 'blue_background_variant',
 }">
<div class="flex flex-col lg:flex-row lg:items-center"
  [ngClass]="{
    'lg:gap-[40px]': data.field_com_5050_variant === 'video_variant',
    'flex-col-reverse lg:gap-12': data.field_com_5050_variant === 'tall_photo_variant',
    'bg-light-blue-2': data.field_com_5050_variant === 'blue_background_variant',
    'lg:py-0': data.field_com_5050_variant === 'blue_background_variant',
    'lg:flex-row-reverse': data.field_com_5050_alignment === 'right',
   }"
>
  <div *ngIf="hideImageSection" class="lg:w-1/2"
    [ngClass]="{
      'aspect-[5/4]': data.field_com_5050_variant === 'blue_background_variant',
      'aspect-[4/5] lg:aspect-auto lg:h-[735px] relative': data.field_com_5050_variant === 'tall_photo_variant',
      'overflow-hidden': data.field_com_5050_variant !== 'tall_photo_variant',
      'aspect-video': data.field_com_5050_variant === 'video_variant',
    }">
    <div *ngIf="data.field_com_5050_variant === 'video_variant' && data.field_com_video;then video else image">here is ignored</div>
    <ng-template #video>
      <iframe [src]="data.field_com_video" class="fit-image" frameborder="0" allowfullscreen="" autoplay="autoplay"></iframe>
    </ng-template>
    <ng-template #image>
      <div [ngClass]="{
          'lg:absolute lg:top-0 lg:left-0 h-full': data.field_com_5050_variant === 'tall_photo_variant',
          'lg:right-0': data.field_com_5050_variant === 'tall_photo_variant' && data.field_com_5050_alignment === 'left',
          'lg:left-0': data.field_com_5050_variant === 'tall_photo_variant' && data.field_com_5050_alignment === 'right',
          'lg:w-[50vw]': data.field_com_5050_variant === 'tall_photo_variant' && !isBeyondDesktop,
          'lg:w-auto': data.field_com_5050_variant === 'tall_photo_variant' && isBeyondDesktop,
          'h-full w-max': data.field_com_5050_variant === 'blue_background_variant' && isEditorialHub
        }">
        <img *ngIf="data.field_com_image" [src]="data.field_com_image" class="fit-image"
        [ngClass]="{
          'lg:!h-[735px]':data.field_com_5050_variant === 'tall_photo_variant'
        }" alt="">
      </div>
    </ng-template>
  </div>

  <div class="lg:m-0"
    [ngClass]="{
      'lg:w-1/2': data.field_com_5050_variant !== 'tall_photo_variant',
      'mb-10 lg:w-[71%] lg:px-0 container': data.field_com_5050_variant === 'tall_photo_variant',
      'py-10 lg:py-0': data.field_com_5050_variant === 'video_variant',
      'px-6': data.field_com_5050_variant !== 'video_variant' || data.field_com_5050_variant !== 'blue_background_variant',
      'lg:px-[48px] py-[32px] px-[24px]': data.field_com_5050_variant === 'blue_background_variant',
    }"
  >
    <div *ngIf="isEditorialHub" class="text-[14px] mb-[16px] lg:mb-[24px] text-[#1C1C1C] leading-[23px]">
      <span>{{data.field_category_title_1}}</span><span class="mx-4">|</span>
      <span>{{data.field_category_title_2}}</span><span class="mx-4">|</span>
      <span>{{data.field_topic_name}}</span><span class="mx-4">|</span>
      <span [innerHTML]="data.field_date"></span>
    </div>
    <app-rcyc-section-content
      *ngIf="sectionContentData"
      class="lg:flex items-center"
      variant="dark"
      heading="h2"
      [data]="sectionContentData"
      [isFAQ]="isFAQ"
      [sectionVariant]="data.field_com_5050_variant"

    ></app-rcyc-section-content>

    <div *ngIf="isPressPage; else notPressPageContent" class="flex flex-col gap-2 lg:flex-row lg:mt-[16px]">
    </div>
    
    <ng-template #notPressPageContent>
      <div *ngIf="(data.field_com_cta_title || data.field_com_secondary_cta_title) && !isEditorialHub" class="flex flex-col gap-2 lg:flex-row lg:mt-auto">
        <ng-container *ngIf="data.field_com_cta_title">
          <ng-container *ngIf="!isChartersPage">
          <a [routerLink]="actionLink" class="button-primary lg:px-8 no-underline hover:no-underline">
            {{data.field_com_cta_title}}
          </a>
        </ng-container>
          <ng-container *ngIf="isChartersPage">
            <a (click)="viewBrochureClick()" class="button-primary lg:px-8">
              {{data.field_com_cta_title}}
            </a>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="data.field_com_secondary_cta_title">
          <ng-container *ngIf="currentUrlPath && currentUrlPath === 'cruise-ports'">
            <a class="button-tertiary lg:px-4" (click)="viewBrochureClick()">
              {{data.field_com_secondary_cta_title}}
            </a>
          </ng-container>
          <ng-container *ngIf="currentUrlPath && currentUrlPath !== 'cruise-ports'">
            <a [href]="secondaryAction" class="button-tertiary lg:px-4">
              {{data.field_com_secondary_cta_title}}
            </a>
          </ng-container>
        </ng-container>
      </div>
    </ng-template>
    <div *ngIf="isPressPage" class="!mt-0 underline decoration-[#3b68b3] underline-offset-[6px]">
      <a [href]="data.field_com_cta_link">
        {{data.field_com_cta_title}}
      </a>
    </div>
    
    <div *ngIf="isEditorialHub" class="uppercase underline underline-offset-8 cursor-pointer decoration-[#3b68b3] leading-8">
      <a (click)="navigateTo(data.field_url_key)">
        {{data.field_cta_text}}
      </a>
    </div>
  </div>
</div>
</div>

<ng-container *ngIf="(currentUrlPath && currentUrlPath === 'cruise-ports') || isChartersPage">
  <app-rcyc-view-brochure #viewBrochureModal [voyageRegion]="data" [pageName]="pageName" [key]="brochure_key"></app-rcyc-view-brochure>
  </ng-container>


<!--496 708-->
