<!-- Desktop View -->
<div [class]="pageName=='diningModal'?'':'container'" *ngIf="!isMobile && !isTab">
  <div *ngIf="isHeading" class="text-sm uppercase mb-3 lg:mb-[18px] font-proxima-nova tracking-[1px]"
    [innerHTML]="headerContent?.field_com_eyebrow">
  </div>
  <h2 *ngIf="isHeading"
    class="text-[29px] lg:text-[49px] mb-5 lg:mb-6 leading-[33px] lg:leading-[56px] lg:[&+.field-content]:-mt-2 tracking-[1px]"
    [innerHTML]="headerContent?.title">
  </h2>
  <div [class]="pageName=='diningModal'?'':'mb-[48px]'" *ngFor="let data of SuiteFare_result">
    <div class="flex" *ngIf="pageName!='diningModal'">
      <div class="w-[276px]">
        <img [src]="data.field_image" class="fit-image aspect-[5/4]" alt="" *ngIf="data.field_image">
      </div>
      <div class="bg-light-blue-2 w-full py-8 px-6 lg:py-6 lg:px-8 lg:flex">
        <div class="w-6/12 m-auto">
          <div class="text-[14px] italic font-proxima-nova uppercase mb-[24px] tracking-[1px]">
            {{data.field_yacht_name}}
          </div>
          <h2 *ngIf="pageName=='yachtSuite'"
            class="text-[23px] lg:text-[25px] leading-[26px] lg:leading-normal font-adobe-caslon-pro tracking-[1px]">
            <span [innerHTML]="data.name"></span>
          </h2>
          <h2 *ngIf="pageName!='yachtSuite'"
            class="text-[23px] lg:text-[25px] leading-[26px] lg:leading-normal font-adobe-caslon-pro tracking-[1px]">
            <span [innerHTML]="data.title"></span>
          </h2>
        </div>
        <div class="w-3/12 m-auto" *ngIf="isPricing && data.price === 0 || data.price">
          <div class="text-[14px] uppercase font-proxima-nova uppercase mb-[24px] tracking-[1px]">
            Fares From:
          </div>
          <h2 class="text-[23px] lg:text-[25px] leading-[26px] lg:leading-normal font-adobe-caslon-pro tracking-[1px]">
            <div
              *ngIf="!data?.pcfiToggleStatus && data?.suiteAvailability && data?.suiteAvailability >= 1 && data?.startingPriceMap && data?.portFeeMap && currencyCode && data?.startingPriceMap[currencyCode]">
              <span *ngIf="data.currency=='USM'">
                <span *ngIf="(data.price % 1) === 0">{{data.price |
                  currency:'USD':'symbol':'1.0-0'}}</span>
                <span *ngIf="(data.price % 1) !== 0">{{data.price |
                  currency:'USD':'symbol':'1.2-2'}}</span>
                USD*
              </span>
              <span *ngIf="data.currency!='USM'">
                <span *ngIf="(data.price % 1) === 0">{{data.price |
                  currency:data.currency:'symbol':'1.0-0'}}</span>
                <span *ngIf="(data.price % 1) !== 0">{{data.price |
                  currency:data.currency:'symbol':'1.2-2'}}</span>
                {{data.currency}}*
              </span>
              <h4 class="mt-5">PER GUEST</h4>
            </div>
            <span
              *ngIf="( data?.pcfiToggleStatus || !data?.suiteAvailability || data?.suiteAvailability < 1 || !data?.startingPriceMap || !data?.portFeeMap || !currencyCode || !data?.startingPriceMap[currencyCode])"
              [innerHTML]="calltext"></span>
          </h2>
        </div>
        <div class="w-3/12 flex flex-col gap-2 lg:gap-6 lg:m-auto">
          <div class="w-full">
            <a class="link-cta button-secondary button-secondary--light uppercase tracking-[.5px] !py-[18.5px]"
              (keyup.enter)="requestAQuoteClick()" (click)="requestAQuoteClick()" id="btn_suite_quote_{{i}}"
              routerLink="/request-quote/{{data.cruiseCode}}">
              Get A Quote
            </a>
          </div>
          <div class="w-full">
            <a class="group uppercase button-secondary link-cta !py-[18.5px]"
              (click)="pageName=='yachtSuite'? toggleTabAccor(data.field_suites_tabs_code, data):toggleTabAccor(data.field_suite_code, data)">
              <span
                *ngIf="pageName=='yachtSuite'? !isOpenedTab(data.field_suites_tabs_code):!isOpenedTab(data.field_suite_code)">
                <img src="assets/img/down-arrow-white.svg" class="display-none group-hover:flex w-3 mr-3" alt="">
                <img src="assets/img/down-arrow.svg" class="group-hover:display-none w-3 mr-3" alt="">
              </span>
              <span
                *ngIf="pageName=='yachtSuite'? isOpenedTab(data.field_suites_tabs_code):isOpenedTab(data.field_suite_code)">
                <img src="assets/img/up-arrow-white.svg" class="display-none group-hover:flex w-3 mr-3" alt="">
                <img src="assets/img/up-arrow.svg" class="group-hover:display-none w-3 mr-3" alt="">
              </span>
              <span *ngIf="pageName!='yachtSuite'" class="tracking-[.5px]"
                [innerHTML]="!isOpenedTab(data.field_suite_code)? 'Show Details': 'Hide Details'"></span>
              <span *ngIf="pageName=='yachtSuite'" class="tracking-[.5px]"
                [innerHTML]="!isOpenedTab(data.field_suites_tabs_code)? 'Show Details': 'Hide Details'"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="grid gap-[3rem] grid-cols-2 moreInfo py-[32.19px] pr-[32.96px]"
      *ngIf="pageName=='yachtSuite'? isOpenedTab(data.field_suites_tabs_code):isOpenedTab(data.field_suite_code) || pageName=='diningModal'"
      [class]="pageName=='diningModal'?'':'bg-light-blue-2 border-solid border-t border-[#C7DDF3]'">
      <div class="column is-6 p02_photo_row">
        <div class="p02_photo_row banner_wrapper pl-[32.96px]"
          *ngIf="accordionData[pageName=='yachtSuite'? data.field_suites_tabs_code : data.field_suite_code]?.suitemoreInfo_slider || SuiteFare_result[0]?.imageSliderData">
          <ngu-carousel #pageNguCarousel [inputs]="pageCarousel"
            [dataSource]="pageName=='diningModal'? SuiteFare_result[0]?.imageSliderData : accordionData[pageName=='yachtSuite'? data.field_suites_tabs_code : data.field_suite_code]?.suitemoreInfo_slider"
            (onMove)="onmoveFn($event,pageName=='yachtSuite'? data.field_suites_tabs_code : data.field_suite_code)">
            <ngu-item *nguCarouselDef="let item; let j=index" class="p-0">
              <img *ngIf="item?.field_image" src="{{IMG_BASE_URL}}{{item.field_image}}" alt=""
                class="min-w-full w-auto w-full max-w-[none] h-auto min-h-full">
              <iframe *ngIf="item?.field_video"
                [src]="pageName=='diningModal'? diningVideo : accordionData[pageName=='yachtSuite'? data.field_suites_tabs_code : data.field_suite_code]?.video"
                class="min-w-full w-auto w-full max-w-[none] h-auto min-h-full"></iframe>
            </ngu-item>
          </ngu-carousel>
          <div class="w-full max-w-[552px] slider-nav-lg mt-4"
            [class]="((accordionData[pageName=='yachtSuite'? data.field_suites_tabs_code : data.field_suite_code]?.nguTotalPage > 1) || (nguTotalPage > 1)) ? '' : 'display-none'">
            <div class="flex justify-center gap-3">
              <div class="cursor-pointer flex leading-8"
                (click)="prevSlide(pageName=='diningModal'? '':(pageName=='yachtSuite'? data?.field_suites_tabs_code : data?.field_suite_code))">
                <img src="assets/img/arrow_left_small.svg" alt="">
                PREVIOUS
              </div>
              <div
                class="carousel-itinerary-slider-experience-dots relative flex items-center grow lg:max-w-[375px] justify-center">
                <ul class="slick-dots" role="tablist" style="">
                  <li
                    *ngFor="let page of (pageName=='diningModal'? SuiteFare_result[0]?.imageSliderData : accordionData[pageName=='yachtSuite'? data.field_suites_tabs_code : data.field_suite_code]?.suitemoreInfo_slider); let j=index"
                    [class]="j+1==(accordionData[pageName=='yachtSuite'? data.field_suites_tabs_code : data.field_suite_code]?.nguPage || nguPage)? 'slick-active': ''"
                    role="presentation"><button type="button" role="tab" id="slick-slide-control0+'j'"
                      aria-controls="slick-slide0+'j'"
                      aria-label="accordionData[pageName=='yachtSuite'? data.field_suites_tabs_code : data.field_suite_code].nguPage of 3"
                      tabindex="0" aria-selected="true">{{accordionData[pageName=='yachtSuite'?
                      data?.field_suites_tabs_code : data?.field_suite_code]?.nguPage || nguPage}}</button></li>
                </ul>
              </div>
              <div class="cursor-pointer flex leading-8"
                (click)="nextSlide(pageName=='diningModal'? '':(pageName=='yachtSuite'? data?.field_suites_tabs_code : data?.field_suite_code))">
                NEXT <img src="assets/img/arrow_right_small.svg" alt=""></div>
            </div>
            <div class="flex justify-center">
              {{accordionData[pageName=='yachtSuite'? data?.field_suites_tabs_code : data?.field_suite_code]?.nguPage ||
              nguPage}} / {{accordionData[pageName=='yachtSuite'? data?.field_suites_tabs_code :
              data?.field_suite_code]?.nguTotalPage || SuiteFare_result[0]?.imageSliderData?.length}}
            </div>
          </div>
        </div>
        <div class="lg:flex lg:gap-4 lg:w-full lg:justify-start mt-[24px] mb-6 px-[32.96px]">
          <div
            *ngIf="SuiteFare_result[0]?.imageSliderData?.length>1 || accordionData[pageName=='yachtSuite'? data.field_suites_tabs_code : data.field_suite_code]?.imageSliderLength "
            class="flex">
            <button
              *ngIf="pageName=='diningModal'? SuiteFare_result[0]?.imageSliderData[0]?.field_video : accordionData[pageName=='yachtSuite'? data.field_suites_tabs_code : data.field_suite_code]?.suitemoreInfo_slider[0]?.field_video"
              (click)="setImageMode(pageName=='yachtSuite'? data?.field_suites_tabs_code : data?.field_suite_code,'tour')"
              class="button-small min-w-[84px] lg:min-h-6 px-0 lg:px-0 -mr-[1px] m-0 text-sm group w-1/3"
              [class]="(accordionData[pageName=='yachtSuite'? data?.field_suites_tabs_code : data?.field_suite_code]?.imageScrollPosition === 'tour' || imageScrollPosition === 'tour') ? 'button-primary' : 'button-secondary'">
              <span
                *ngIf="(pageName!='diningModal'? accordionData[pageName=='yachtSuite'? data?.field_suites_tabs_code : data?.field_suite_code]?.imageScrollPosition === 'tour' : imageScrollPosition === 'tour')">
                <img src="assets/img/Motion-white.svg" class="flex group-hover:display-none w-4" alt="">
                <img src="assets/img/Motion.svg" class="display-none group-hover:flex w-4" alt="">
              </span>
              <span
                *ngIf="(pageName!='diningModal'? accordionData[pageName=='yachtSuite'? data?.field_suites_tabs_code : data?.field_suite_code]?.imageScrollPosition !== 'tour' : imageScrollPosition !== 'tour')">
                <img src="assets/img/Motion-white.svg" class="display-none group-hover:flex w-4" alt="">
                <img src="assets/img/Motion.svg" class="flex group-hover:display-none w-4" alt="">
              </span>
              <span class="ml-1">
                Tour
              </span>
            </button>
            <button
              (click)="setImageMode(pageName=='yachtSuite'? data?.field_suites_tabs_code : data?.field_suite_code,'map')"
              class="button-small min-w-[120px] lg:min-h-6 px-0 lg:px-0 m-0 text-sm group w-1/3"
              [class]="(accordionData[pageName=='yachtSuite'? data?.field_suites_tabs_code : data?.field_suite_code]?.imageScrollPosition === 'map' || imageScrollPosition === 'map') ? 'button-primary' : 'button-secondary'"
              style="border-right: none ;">
              <span
                *ngIf="(pageName!='diningModal'? accordionData[pageName=='yachtSuite'? data?.field_suites_tabs_code : data?.field_suite_code]?.imageScrollPosition === 'map' : imageScrollPosition === 'map')">
                <img src="assets/img/Map-w.svg" class="flex group-hover:display-none w-3" alt="">
                <img src="assets/img/map.svg" class="display-none group-hover:flex w-3" alt="">
              </span>
              <span
                *ngIf="(pageName!='diningModal'? accordionData[pageName=='yachtSuite'? data?.field_suites_tabs_code : data?.field_suite_code]?.imageScrollPosition !== 'map' : imageScrollPosition !== 'map')">
                <img src="assets/img/Map-w.svg" class="display-none group-hover:flex w-3" alt="">
                <img src="assets/img/map.svg" class="flex group-hover:display-none w-3" alt="">
              </span>

              <span class="ml-1">
                FloorPlan
              </span>
            </button>
            <button
              (click)="setImageMode(pageName=='yachtSuite'? data?.field_suites_tabs_code : data?.field_suite_code,'photo')"
              class="button-small min-w-[84px] lg:min-h-6 px-0 lg:px-0 m-0 text-sm group w-1/3 border-l-transparent"
              [class]="(accordionData[pageName=='yachtSuite'? data?.field_suites_tabs_code : data?.field_suite_code]?.imageScrollPosition === 'photo' || imageScrollPosition === 'photo') ? 'button-primary' : 'button-secondary'">
              <span
                *ngIf="(pageName!='diningModal'? accordionData[pageName=='yachtSuite'? data?.field_suites_tabs_code : data?.field_suite_code]?.imageScrollPosition === 'photo' : imageScrollPosition === 'photo')">
                <img src="assets/img/photo.svg" class="display-none group-hover:flex w-4" alt="">
                <img src="assets/img/photo-white.svg" class="flex group-hover:display-none w-4" alt="">
              </span>
              <span
                *ngIf="(pageName!='diningModal'? accordionData[pageName=='yachtSuite'? data?.field_suites_tabs_code : data?.field_suite_code]?.imageScrollPosition !== 'photo' : imageScrollPosition !== 'photo')">
                <img src="assets/img/photo-white.svg" class="display-none group-hover:flex w-4" alt="">
                <img src="assets/img/photo.svg" class="flex group-hover:display-none w-4" alt="">
              </span>

              <span class="ml-1">
                Photo
              </span>
            </button>
          </div>
        </div>
      </div>
      <div class="column is-6"
        *ngIf="accordionData[pageName=='yachtSuite'? data?.field_suites_tabs_code : data?.field_suite_code]?.suitemoreInfo || pageName=='diningModal'">
        <div class="w-full h-auto" *ngIf="pageName=='diningModal'">
          <div class="flex text-sm uppercase mb-1 font-normal leading-[23.1px] tracking-[1.2px] text-[#1C1C1C]"
            [innerHTML]="SuiteFare_result[0]?.metaData?.eyebrow">
          </div>
          <h2 class="flex text-[23px] lg:text-[39px] font-normal tracking-[1.2px] !leading-[44.85px] lg:leading-normal">
            <span [innerHTML]="SuiteFare_result[0]?.metaData?.dineName"></span>
          </h2>
        </div>
        <div class="lg:flex lg:justify-between">
          <div class="w-1/3 mr-4">
            <div
              *ngIf="accordionData[pageName=='yachtSuite'? data?.field_suites_tabs_code : data?.field_suite_code]?.suitemoreInfo[0]?.field_suite_size || (pageName=='diningModal'&&SuiteFare_result[0]?.headerTableData[0]?.field_category_title)">
              <div class="text-[18px] font-adobe-caslon-pro uppercase mb-1 tracking-[1px]">
                <span class="font-caslon"
                  [innerHTML]="pageName=='diningModal'?SuiteFare_result[0]?.headerTableData[0]?.field_category_title:'Suites Size'"></span>
              </div>
              <h2 class="text-[16px] font-proxima-nova tracking-[1px]">
                <span *ngIf="pageName=='diningModal'"
                  [innerHTML]="SuiteFare_result[0]?.headerTableData[0]?.field_text_block_1"></span>
                <span *ngIf="pageName!='diningModal'">{{accordionData[pageName=='yachtSuite'?
                  data?.field_suites_tabs_code : data?.field_suite_code]?.suitemoreInfo[0]?.field_suite_size |
                  convertHtml}}</span>
              </h2>
            </div>
          </div>
          <div class="w-1/3 mr-4">
            <div
              *ngIf="accordionData[pageName=='yachtSuite'? data?.field_suites_tabs_code : data?.field_suite_code]?.suitemoreInfo[0]?.field_terrace_size || (pageName=='diningModal'&&SuiteFare_result[0]?.headerTableData[1]?.field_category_title)">
              <div class="text-[18px] uppercase mb-1 tracking-[1px]">
                <span class="font-caslon"
                  [innerHTML]="pageName=='diningModal'?SuiteFare_result[0]?.headerTableData[1]?.field_category_title:'Terrace Size'"></span>
              </div>
              <h2 class="text-[16px] font-proxima-nova tracking-[1px]">
                <span *ngIf="pageName=='diningModal'"
                  [innerHTML]="SuiteFare_result[0]?.headerTableData[1]?.field_text_block_1"></span>
                <span *ngIf="pageName!='diningModal'">{{accordionData[pageName=='yachtSuite'?
                  data.field_suites_tabs_code : data.field_suite_code]?.suitemoreInfo[0]?.field_terrace_size |
                  convertHtml}}</span>
              </h2>
            </div>
          </div>
          <div class="w-1/3 mr-4">
            <div *ngIf="isPricing" class="text-[18px] font-caslon uppercase mb-1 tracking-[1px]">
              Fares From
            </div>
            <div *ngIf="(pageName=='diningModal'&&SuiteFare_result[0]?.headerTableData[2]?.field_category_title)">
              <div class="text-[18px] font-adobe-caslon-pro uppercase mb-1 tracking-[1px]">
                <span [innerHTML]="SuiteFare_result[0]?.headerTableData[2]?.field_category_title"></span>
              </div>
              <h2 class="text-[14px] font-proxima-nova tracking-[1px]">
                <span [innerHTML]="SuiteFare_result[0]?.headerTableData[2]?.field_text_block_1"></span>
              </h2>
            </div>
            <h2 *ngIf="isPricing" class="text-[16px] font-proxima-nova tracking-[1px]">
              <div *ngIf="data.price === 0 || data.price">
                <span *ngIf="data.currency=='USM'">
                  <span *ngIf="(data.price % 1) === 0">{{data.price |
                    currency:'USD':'symbol':'1.0-0'}}</span>
                  <span *ngIf="(data.price % 1) !== 0">{{data.price |
                    currency:'USD':'symbol':'1.2-2'}}</span>
                  USD*
                </span>
                <span *ngIf="data.currency!='USM'">
                  <span *ngIf="(data.price % 1) === 0">{{data.price |
                    currency:data.currency:'symbol':'1.0-0'}}</span>
                  <span *ngIf="(data.price % 1) !== 0">{{data.price |
                    currency:data.currency:'symbol':'1.2-2'}}</span>
                  {{data.currency}}*
                </span>
              </div>
              <span *ngIf="!data.price" [innerHTML]="calltext"></span>
            </h2>
          </div>
        </div>
        <div class="flex flex-col">
          <div
            *ngFor="let tab of (pageName=='diningModal'?SuiteFare_result[0]?.accordionData : accordionData[pageName=='yachtSuite'? data?.field_suites_tabs_code : data?.field_suite_code]?.suitemoreInfo)"
            class="mb-[29.5px] flex flex-col w-full" (click)="toogleTab(tab?.field_sort_order)">
            <div *ngIf="tab?.field_tile_title" class="flex justify-between items-center cursor-pointer">
              <div class="text-[20px] font-proxima-nova font-semibold">
                {{tab.field_tile_title | convertHtml}}
              </div>
              <div class="w-[18px] h-[18px] lg:w-[26px] lg:h-[26px]
                  top-[16px] lg:top-[10px] right-[8px] lg:right-[24px] text-[10px] leading-none">
                <img *ngIf="!isOpened(tab?.field_sort_order)" src="assets/img/plus_icon.svg" alt="">
                <img *ngIf="isOpened(tab?.field_sort_order)" src="assets/img/Minus_icon.svg" alt="">
              </div>
            </div>
            <div *ngIf="tab?.body && tab?.field_tile_title" [ngClass]="{
              'max-h-0 opacity-0 overflow-hidden': !isOpened(tab?.field_sort_order),
              'max-h-[500px] opacity-100 overflow-visible': isOpened(tab?.field_sort_order)
            }" class="transition-[max-height] duration-3000 ease-in-out">
              <div class="text-[16px] mt-[16px] mr-[40px] font-proxima-nova font-regular listItem"
                [innerHTML]="tab.body"></div>
            </div>
            <div *ngIf="tab?.body && tab?.field_tile_title" class="border-solid border-t border-[#646464]"
              [class]="isOpened(tab.field_sort_order)? 'mt-0': 'mt-[21px]'"></div>
          </div>
          <div *ngIf="pageName=='diningModal'" class="flex flex-col lg:flex-row lg:mt-[10.5px]">
            <a *ngIf="SuiteFare_result[0]?.metaData?.menuLink"
              class="group uppercase button-primary link-cta !py-[16px] !px-[32px]"
              href="{{SuiteFare_result[0]?.metaData?.menuLink}}">
              <span class="tracking-[.5px] text-[16px] font-proxima-nova"
                [innerHTML]="SuiteFare_result[0]?.metaData?.menuName"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Mobile View -->
<div class="text-primary" [class]="pageName=='diningModal'?'':'mx-[24px] mb-[40px] mt-[40px]'"
  *ngIf="isMobile || isTab">
  <div *ngIf="isHeading" class="text-sm uppercase mb-3 lg:mb-[18px]" [innerHTML]="headerContent?.field_com_eyebrow">
  </div>
  <h2 *ngIf="isHeading"
    class="text-[29px] lg:text-[49px] mb-[32px] lg:mb-6 leading-[33px] lg:leading-[56px] lg:[&+.field-content]:-mt-2 text-primary"
    [innerHTML]="headerContent?.title">
  </h2>
  <div [class]="pageName=='diningModal'?'':'mb-[48px]'" *ngFor="let data of SuiteFare_result; let i=index">
    <div class="flex flex-col" *ngIf="pageName!='diningModal'">
      <div class="w-full">
        <img [src]="data.field_image" class="fit-image aspect-[5/4]" alt="" *ngIf="data.field_image">
      </div>
      <div class="bg-light-blue-2 w-full py-8 px-6 lg:py-6 lg:px-8 xl:p-10 flex flex-col">
        <div class="w-full m-auto mb-[12px]">
          <div class="text-[14px] italic font-proxima-nova uppercase mb-3">
            {{data.field_yacht_name}}
          </div>
          <h2 *ngIf="pageName!='yachtSuite'"
            class="text-[23px] lg:text-[25px] leading-[26px] lg:leading-normal font-adobe-caslon-pro">
            <span [innerHTML]="data.title"></span>
          </h2>
          <h2 *ngIf="pageName=='yachtSuite'"
            class="text-[23px] lg:text-[25px] leading-[26px] lg:leading-normal font-adobe-caslon-pro">
            <span [innerHTML]="data.name"></span>
          </h2>
        </div>
        <div *ngIf="isPricing" class="w-full m-auto mb-[12px]">
          <div class="text-[14px] uppercase font-proxima-nova uppercase mb-3">
            Fares From
          </div>
          <h2 *ngIf="isPricing"
            class="text-[23px] lg:text-[25px] leading-[26px] lg:leading-normal font-adobe-caslon-pro">
            <div *ngIf="data.price === 0 || data.price">
              <span *ngIf="data.currency=='USM'">
                <span *ngIf="(data.price % 1) === 0">{{data.price |
                  currency:'USD':'symbol':'1.0-0'}}</span>
                <span *ngIf="(data.price % 1) !== 0">{{data.price |
                  currency:'USD':'symbol':'1.2-2'}}</span>
                USD*
              </span>
              <span *ngIf="data.currency!='USM'">
                <span *ngIf="(data.price % 1) === 0">{{data.price |
                  currency:data.currency:'symbol':'1.0-0'}}</span>
                <span *ngIf="(data.price % 1) !== 0">{{data.price |
                  currency:data.currency:'symbol':'1.2-2'}}</span>
                {{data.currency}}*
              </span>
              <h4 class="mt-5">PER GUEST</h4>
            </div>
            <span *ngIf="!data.price" [innerHTML]="calltext"></span>
          </h2>
        </div>
        <div class="w-full flex flex-col gap-[16px] lg:gap-6 lg:m-auto">
          <div class="w-full">
            <a class="link-cta button-secondary button-secondary--light uppercase !py-[4px]"
              (keyup.enter)="requestAQuoteClick()" (click)="requestAQuoteClick()" id="btn_suite_quote_{{i}}"
              routerLink="/request-quote/{{data.cruiseCode}}">
              Get A Quote
            </a>
          </div>
          <div class="w-full">
            <a class="uppercase link-cta !py-[4px]"
              (click)="pageName=='yachtSuite'? toggleTabAccor(data.field_suites_tabs_code, data):toggleTabAccor(data.field_suite_code, data)"
              [class]="(selectedSuiteId && (data.field_suite_code==selectedSuiteId || data.field_suites_tabs_code==selectedSuiteId))? 'primary-button' : 'secondary-button'">
              <span
                *ngIf="pageName=='yachtSuite'? !isOpenedTab(data.field_suites_tabs_code):!isOpenedTab(data.field_suite_code)">
                <img src="assets/img/down-arrow.svg" class="w-3 mr-3" alt="">
              </span>
              <span
                *ngIf="pageName=='yachtSuite'? isOpenedTab(data.field_suites_tabs_code):isOpenedTab(data.field_suite_code)">
                <img src="assets/img/up-arrow.svg" class="flex w-3 mr-3" alt="">
              </span>
              <span *ngIf="pageName!='yachtSuite'" class="tracking-[.5px]"
                [innerHTML]="!isOpenedTab(data.field_suite_code)? 'Show Details': 'Hide Details'"></span>
              <span *ngIf="pageName=='yachtSuite'" class="tracking-[.5px]"
                [innerHTML]="!isOpenedTab(data.field_suites_tabs_code)? 'Show Details': 'Hide Details'"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full h-auto mx-[24px]" *ngIf="pageName=='diningModal'">
      <div class="flex text-sm uppercase mb-1 font-normal leading-[23.1px] tracking-[1.2px] text-[#1C1C1C]"
        [innerHTML]="SuiteFare_result[0]?.metaData?.eyebrow">
      </div>
      <h2 class="flex text-[23px] lg:text-[39px] font-normal tracking-[1.2px] !leading-[44.85px] lg:leading-normal">
        <span [innerHTML]="SuiteFare_result[0]?.metaData?.dineName"></span>
      </h2>
    </div>
    <div class="flex flex-col moreInfo py-[24px] px-[24px]"
      [class]="pageName=='diningModal'?'':'bg-light-blue-2 border-solid border-t border-[#C7DDF3]'"
      *ngIf="pageName=='yachtSuite'? isOpenedTab(data.field_suites_tabs_code):isOpenedTab(data.field_suite_code) || pageName=='diningModal'">
      <div class="p02_photo_row">
        <div class="flex w-full justify-start mb-[32.25px]">
          <div
            *ngIf="SuiteFare_result[0]?.imageSliderData?.length>1 || accordionData[pageName=='yachtSuite'? data.field_suites_tabs_code : data.field_suite_code]?.imageSliderLength"
            class="flex">
            <button
              *ngIf="pageName=='diningModal'? SuiteFare_result[0]?.imageSliderData[0]?.field_video : accordionData[pageName=='yachtSuite'? data.field_suites_tabs_code : data.field_suite_code]?.suitemoreInfo_slider[0]?.field_video"
              (click)="setImageMode(pageName=='yachtSuite'? data.field_suites_tabs_code : data.field_suite_code,'tour')"
              class="button-small min-w-[30%] lg:min-h-6 px-0 lg:px-0 -mr-[1px] m-0 text-sm w-1/3"
              [class]="(accordionData[pageName=='yachtSuite'? data?.field_suites_tabs_code : data?.field_suite_code]?.imageScrollPosition === 'tour' || imageScrollPosition === 'tour') ? 'primary-button' : 'secondary-button'">
              <span
                *ngIf="(pageName!='diningModal'? accordionData[pageName=='yachtSuite'? data?.field_suites_tabs_code : data?.field_suite_code]?.imageScrollPosition === 'tour' : imageScrollPosition === 'tour')">
                <img src="assets/img/Motion-white.svg" class="flex w-4" alt="">
              </span>
              <span
                *ngIf="(pageName!='diningModal'? accordionData[pageName=='yachtSuite'? data?.field_suites_tabs_code : data?.field_suite_code]?.imageScrollPosition !== 'tour' : imageScrollPosition !== 'tour')">
                <img src="assets/img/Motion.svg" class="flex w-4" alt="">
              </span>
              <span class="ml-1">
                Tour
              </span>
            </button>
            <button
              (click)="setImageMode(pageName=='yachtSuite'? data.field_suites_tabs_code : data.field_suite_code,'map')"
              class="button-small min-w-[120px] lg:min-h-6 px-0 lg:px-0 m-0 text-sm group w-1/3"
              [class]="(accordionData[pageName=='yachtSuite'? data?.field_suites_tabs_code : data?.field_suite_code]?.imageScrollPosition === 'map' || imageScrollPosition === 'map') ? 'primary-button' : 'secondary-button'"
              style="border-right: none ;">
              <span
                *ngIf="(pageName!='diningModal'? accordionData[pageName=='yachtSuite'? data?.field_suites_tabs_code : data?.field_suite_code]?.imageScrollPosition === 'map' : imageScrollPosition === 'map')">
                <img src="assets/img/Map-w.svg" class="flex w-4" alt="">
              </span>
              <span
                *ngIf="(pageName!='diningModal'? accordionData[pageName=='yachtSuite'? data?.field_suites_tabs_code : data?.field_suite_code]?.imageScrollPosition !== 'map' : imageScrollPosition !== 'map')">
                <img src="assets/img/map.svg" class="flex w-4" alt="">
              </span>

              <span class="ml-1">
                FloorPlan
              </span>
            </button>
            <button
              (click)="setImageMode(pageName=='yachtSuite'? data.field_suites_tabs_code : data.field_suite_code,'photo')"
              class="button-small min-w-[84px] lg:min-h-6 px-0 lg:px-0 m-0 text-sm group w-1/3 border-l-transparent"
              [class]="(accordionData[pageName=='yachtSuite'? data?.field_suites_tabs_code : data?.field_suite_code]?.imageScrollPosition === 'photo' || imageScrollPosition === 'photo') ? 'primary-button' : 'secondary-button'">
              <span
                *ngIf="(pageName!='diningModal'? accordionData[pageName=='yachtSuite'? data?.field_suites_tabs_code : data?.field_suite_code]?.imageScrollPosition === 'photo' : imageScrollPosition === 'photo')">
                <img src="assets/img/photo-white.svg" class="flex w-5" alt="">
              </span>
              <span
                *ngIf="(pageName!='diningModal'? accordionData[pageName=='yachtSuite'? data?.field_suites_tabs_code : data?.field_suite_code]?.imageScrollPosition !== 'photo' : imageScrollPosition !== 'photo')">
                <img src="assets/img/photo.svg" class="flex w-5" alt="">
              </span>

              <span class="ml-1">
                Photo
              </span>
            </button>
          </div>
        </div>
        <div class="p02_photo_row banner_wrapper"
          *ngIf="accordionData[pageName=='yachtSuite'? data.field_suites_tabs_code : data.field_suite_code]?.suitemoreInfo_slider || SuiteFare_result[0]?.imageSliderData">
          <ngu-carousel #pageNguCarousel [inputs]="pageCarousel"
            [dataSource]="pageName=='diningModal'? SuiteFare_result[0]?.imageSliderData : accordionData[pageName=='yachtSuite'? data.field_suites_tabs_code : data.field_suite_code].suitemoreInfo_slider"
            (onMove)="onmoveFn($event,pageName=='yachtSuite'? data.field_suites_tabs_code : data.field_suite_code)">
            <ngu-item *nguCarouselDef="let item; let j=index" class="p-0">
              <img *ngIf="item?.field_image" src="{{IMG_BASE_URL}}{{item?.field_image}}" alt=""
                class="min-w-full w-auto w-full max-w-[none] h-auto min-h-full">
              <iframe *ngIf="item?.field_video"
                [src]="pageName=='diningModal'? diningVideo : accordionData[pageName=='yachtSuite'? data.field_suites_tabs_code : data.field_suite_code]?.video"
                class="min-w-full w-auto w-full max-w-[none] h-auto min-h-full"></iframe>
            </ngu-item>
          </ngu-carousel>
          <div class="w-full max-w-[552px] slider-nav-lg mt-4"
            [class]="((accordionData[pageName=='yachtSuite'? data.field_suites_tabs_code : data.field_suite_code]?.nguTotalPage > 1) || (SuiteFare_result[0]?.imageSliderData?.length > 1)) ? '' : 'display-none'">
            <div class="flex justify-center gap-3">
              <div class="cursor-pointer flex leading-8 text-[14px]"
                (click)="prevSlide(pageName=='diningModal'? '':(pageName=='yachtSuite'? data?.field_suites_tabs_code : data?.field_suite_code))">
                <img src="assets/img/arrow_left_small.svg" alt="">
                PREVIOUS
              </div>
              <div
                class="carousel-itinerary-slider-experience-dots relative flex items-center grow lg:max-w-[375px] justify-center">
                <ul class="slick-dots" role="tablist" style="">
                  <li
                    *ngFor="let page of (pageName=='diningModal'? SuiteFare_result[0]?.imageSliderData : accordionData[pageName=='yachtSuite'? data.field_suites_tabs_code : data.field_suite_code].suitemoreInfo_slider); let j=index"
                    [class]="j+1==(accordionData[pageName=='yachtSuite'? data.field_suites_tabs_code : data.field_suite_code]?.nguPage || nguPage)? 'slick-active': ''"
                    role="presentation"><button type="button" role="tab" id="slick-slide-control0+'j'"
                      aria-controls="slick-slide0+'j'"
                      aria-label="accordionData[pageName=='yachtSuite'? data.field_suites_tabs_code : data.field_suite_code].nguPage of 3"
                      tabindex="0" aria-selected="true">{{accordionData[pageName=='yachtSuite'?
                      data?.field_suites_tabs_code : data?.field_suite_code]?.nguPage || nguPage}}</button></li>
                </ul>
              </div>
              <div class="cursor-pointer flex leading-8 text-[14px]"
                (click)="nextSlide(pageName=='diningModal'? '':(pageName=='yachtSuite'? data?.field_suites_tabs_code : data?.field_suite_code))">
                NEXT <img src="assets/img/arrow_right_small.svg" alt=""></div>
            </div>
            <div class="flex justify-center ml-8">
              {{accordionData[pageName=='yachtSuite'? data?.field_suites_tabs_code : data?.field_suite_code]?.nguPage ||
              nguPage}} / {{accordionData[pageName=='yachtSuite'? data?.field_suites_tabs_code :
              data?.field_suite_code]?.nguTotalPage || SuiteFare_result[0]?.imageSliderData?.length}}
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col mt-[32px]"
        *ngIf="accordionData[pageName=='yachtSuite'? data?.field_suites_tabs_code : data?.field_suite_code]?.suitemoreInfo || pageName=='diningModal'">
        <div class="flex flex-col mb-[32px]">
          <div class="w-full m-auto mb-[12px]">
            <div
              *ngIf="accordionData[pageName=='yachtSuite'? data?.field_suites_tabs_code : data?.field_suite_code]?.suitemoreInfo[0]?.field_suite_size || (pageName=='diningModal'&&SuiteFare_result[0]?.headerTableData[0]?.field_category_title)">
              <div class="text-[18px] regular font-adobe-caslon-pro uppercase mb-1 text-[#646464]">
                <span class="font-caslon"
                  [innerHTML]="pageName=='diningModal'?SuiteFare_result[0]?.headerTableData[0]?.field_category_title:'Suites Size'"></span>
              </div>
              <h2 class="text-[16px] font-proxima-nova">
                <span *ngIf="pageName=='diningModal'"
                  [innerHTML]="SuiteFare_result[0]?.headerTableData[0]?.field_text_block_1"></span>
                <span *ngIf="pageName!='diningModal'">{{accordionData[pageName=='yachtSuite'?
                  data?.field_suites_tabs_code : data?.field_suite_code]?.suitemoreInfo[0]?.field_suite_size |
                  convertHtml}}</span>
              </h2>
            </div>
          </div>
          <div class="w-full m-auto mb-[12px]">
            <div
              *ngIf="accordionData[pageName=='yachtSuite'? data?.field_suites_tabs_code : data?.field_suite_code]?.suitemoreInfo[0]?.field_terrace_size || (pageName=='diningModal'&&SuiteFare_result[0]?.headerTableData[1]?.field_category_title)">
              <div class="text-[18px] regular uppercase mb-1 text-[#646464]">
                <span class="font-caslon"
                  [innerHTML]="pageName=='diningModal'?SuiteFare_result[0]?.headerTableData[1]?.field_category_title:'Terrace Size'"></span>
              </div>
              <h2 class="text-[16px] font-proxima-nova">
                <span *ngIf="pageName=='diningModal'"
                  [innerHTML]="SuiteFare_result[0]?.headerTableData[1]?.field_text_block_1"></span>
                <span *ngIf="pageName!='diningModal'">{{accordionData[pageName=='yachtSuite'?
                  data.field_suites_tabs_code : data.field_suite_code]?.suitemoreInfo[0]?.field_terrace_size |
                  convertHtml}}</span>
              </h2>
            </div>
          </div>
          <div class="w-full m-auto">
            <div *ngIf="isPricing" class="text-[18px] regular font-caslon uppercase mb-1 text-[#646464]">
              Fares From
            </div>
            <div *ngIf="(pageName=='diningModal'&&SuiteFare_result[0]?.headerTableData[2]?.field_category_title)">
              <div class="text-[18px] font-adobe-caslon-pro uppercase mb-1 tracking-[1px]">
                <span [innerHTML]="SuiteFare_result[0]?.headerTableData[2]?.field_category_title"></span>
              </div>
              <h2 class="text-[18px] font-proxima-nova tracking-[1px]">
                <span [innerHTML]="SuiteFare_result[0]?.headerTableData[2]?.field_text_block_1"></span>
              </h2>
            </div>
            <h2 *ngIf="isPricing" class="text-[16px] font-proxima-nova">
              <div *ngIf="data.price === 0 || data.price">
                <span *ngIf="data.currency=='USM'">
                  <span *ngIf="(data.price % 1) === 0">{{data.price |
                    currency:'USD':'symbol':'1.0-0'}}</span>
                  <span *ngIf="(data.price % 1) !== 0">{{data.price |
                    currency:'USD':'symbol':'1.2-2'}}</span>
                  USD*
                </span>
                <span *ngIf="data.currency!='USM'">
                  <span *ngIf="(data.price % 1) === 0">{{data.price |
                    currency:data.currency:'symbol':'1.0-0'}}</span>
                  <span *ngIf="(data.price % 1) !== 0">{{data.price |
                    currency:data.currency:'symbol':'1.2-2'}}</span>
                  {{data.currency}}*
                </span>
              </div>
              <span *ngIf="!data.price" [innerHTML]="calltext"></span>
            </h2>
          </div>
        </div>
        <div class="flex flex-col">
          <div
            *ngFor="let tab of (pageName=='diningModal'?SuiteFare_result[0]?.accordionData : accordionData[pageName=='yachtSuite'? data?.field_suites_tabs_code : data?.field_suite_code]?.suitemoreInfo)"
            class="mb-[29.5px] flex flex-col w-full transition-all duration-[1000ms]"
            (click)="toogleTab(tab?.field_sort_order)">
            <div *ngIf="tab?.field_tile_title" class="flex justify-between gap-1">
              <div class="text-[20px] font-proxima-nova font-semibold w-[94%]">
                {{tab.field_tile_title | convertHtml}}
              </div>
              <div class="w-[6%] lg:w-[26px] lg:h-[26px]
                  top-[16px] lg:top-[10px] right-[8px] lg:right-[24px] text-[10px] leading-none">
                <img *ngIf="!isOpened(tab.field_sort_order)" src="assets/img/plus_icon.svg" alt="">
                <img *ngIf="isOpened(tab.field_sort_order)" src="assets/img/Minus_icon.svg" alt="">
              </div>
            </div>
            <div *ngIf="isOpened(tab?.field_sort_order) && tab?.body && tab?.field_tile_title"
              class="mt-[16px] mr-[40px] transition-all duration-[2000ms]"
              [class]="isOpened(tab?.field_sort_order)? 'opacity-100' : 'opacity-0'">
              <div class="text-[16px] font-proxima-nova font-regular listItem" [innerHTML]="tab.body"></div>
            </div>
            <div *ngIf="tab?.body && tab?.field_tile_title" class="mt-[14px] border-solid border-t border-[#646464]">
            </div>
          </div>
          <div *ngIf="pageName=='diningModal'">
            <a *ngIf="SuiteFare_result[0]?.metaData?.menuLink"
              class="group uppercase button-primary link-cta !py-[16px] !px-[32px]"
              href="{{SuiteFare_result[0]?.metaData?.menuLink}}">
              <span class="tracking-[.5px] text-[16px] font-proxima-nova"
                [innerHTML]="SuiteFare_result[0]?.metaData?.menuName"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>