import { Component, HostListener, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { RcycCommonService } from '../../../rcyc-services/rcyc-common/rcyc-common.service';
import { RcycVoyagesService } from '../../rcyc-voyages/rcyc-voyages.service';
import { NguCarousel, NguCarouselStore } from '@ngu/carousel';
import { DomSanitizer } from '@angular/platform-browser';
import { SwalComponent } from '@toverux/ngx-sweetalert2';

const { detect } = require('detect-browser');
const browser: any = detect();

@Component({
  selector: 'app-rcyc-suites-modal',
  templateUrl: './rcyc-suites-modal.component.html',
  styleUrls: ['./rcyc-suites-modal.component.css'],
  encapsulation: ViewEncapsulation.None,

})
export class RcycSuitesModalComponent implements OnInit {
  @ViewChild('suiteSwal', { static: true }) suiteSwal: SwalComponent;

  isMobile: any;
  suitemoreInfo: any;
  suitemoreInfo_slider: any;
  selectedSuiteId: any;
  suitetitle: any;
  @Input() suiteData: any;
  @Input() isPricing: boolean;
  @Input() isHeading: boolean;
  // @Input() id: any;
  @Input() variant: any;
  yachtSuite: boolean;
  @ViewChild('pageNguCarousel') pageNguCarousel: NguCarousel<any>;
  @ViewChild('nguPageCarouselControls') nguPageCarouselControls: any;
  slideConfig: any;
  imageScrollPosition: any;
  page: number;
  nrOfPages: number;
  pageNumber: number;
  opened: any;
  suiteSize: any;
  terraceSize: any;
  public pageCarousel: any;
  currentSlide = 0;
  sliderIndex = 0;
  nguTotalPage: any;
  nguPage: any;
  openedTabs: number[] = [];
  slideValue: number = 0;
  calltext: any;
  isTab: boolean;
  headerContent: any;
  yacht: string;
  suiteCode: string;
  video: any;
  eyebrow: any;

  constructor(
    private rcycCommonService: RcycCommonService,
    private rcycVoyagesService: RcycVoyagesService,
    private sanitizer: DomSanitizer
  ) { }


  ngOnInit(): void {
    this.pageCarousel = {
      grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
      slide: 1,
      speed: 500,
      interval: 0,
      point: {
        visible: false
      },
      // load: 2,
      touch: true,
      loop: true,
      // custom: 'banner'
    };

    this.screenSize();
  }

  @HostListener('window:resize', ['$event'])
  screenSize() {
    this.isMobile = window.innerWidth <= 769;
    this.isTab = window.innerWidth <= 1024;
  }
  // method to call while opening the modal
  onOpen(event) {
  }
  // method to open modal
  modalOpen(suiteData: any) {
    let id;
    this.yacht = suiteData.yacht;
    this.suiteCode = suiteData.suite.field_suite_code;
    this.suitetitle = suiteData.suite.title
    this.eyebrow = suiteData.suite.field_com_eyebrow;
    this.getSuitesDetails( id, this.suiteCode)
    this.suiteSwal.show();
  }
  onClose(event) {

  }
  // method to call just before opening the modal
  beforeOpen(event) {
    event.modalElement.parentElement.setAttribute('id', 'onboardSuiteSwalId');
  }
  requestAQuoteClick() {
    this.rcycCommonService.setSessionData('requestAQuoteClick', true);
  }

  getSuitesDetails(id, item) {

    // cruiseCode
    // currency
    this.suitemoreInfo = false;
    this.suitemoreInfo_slider = false;
    this.suiteSize = '';
    this.terraceSize = ''
      this.imageScrollPosition = 'tour'
      let imgTitle = '';
      this.rcycVoyagesService.DrupalSuitesSlider_API(this.yacht, item).subscribe(
        response => {
          if (response) {
            this.suitemoreInfo_slider = true;
            this.suitemoreInfo_slider = response;
            this.suitemoreInfo_slider.sort((a, b) => a.field_sort_order - b.field_sort_order);
            if(this.suitemoreInfo_slider[0]?.field_video && this.suitemoreInfo_slider[0]?.field_video.length){
              this.getSafeVideoUrl(this.suitemoreInfo_slider[0]?.field_video, id)
            }
            let slidesToShow = 1;
            this.nguPage = Math.ceil((this.currentSlide ? this.currentSlide : 0) / slidesToShow + 1);
            this.nguTotalPage = Math.ceil(this.suitemoreInfo_slider.length / slidesToShow);
          }
        },
        err => {

        });

      this.rcycVoyagesService.DrupalSuitesDetails_API(this.yacht, item).subscribe(
        (response: any) => {
          if (response) {
            this.suitemoreInfo = true;
            this.suitemoreInfo = response;
            this.suiteSize = this.suitemoreInfo[2].field_suite_size;
            this.terraceSize = this.suitemoreInfo[2].field_terrace_size;
            this.suitemoreInfo.sort((a, b) => a.field_sort_order - b.field_sort_order)
          }
        },
        err => {

        });
      this.selectedSuiteId = 0;
  }

  getSafeVideoUrl(url,id){
    let safeUrl = url.split('?')[0];
    this.video = this.sanitizer.bypassSecurityTrustResourceUrl(safeUrl+'?autoplay=0&autopause=0&loop=1&texttrack=null&muted=1&controls=1')
  }

  setImageMode(position) {
    if (position == 'tour') {
      this.pageNguCarousel.moveTo(0);
      this.imageScrollPosition = 'tour'
    } else if (position == 'map') {
      this.pageNguCarousel.moveTo(1);
      this.imageScrollPosition = 'map'
    } else if (position == 'photo') {
      this.pageNguCarousel.moveTo(2);
      this.imageScrollPosition = 'photo'
    }
  }

  toogleTab(id: number) {
    const index = this.openedTabs.indexOf(id);
    if (index === -1) {
      this.openedTabs.push(id);
    } else {
      this.openedTabs.splice(index, 1);
    }
  }

  isOpened(id: number): boolean {
    return this.openedTabs.includes(id);
  }

  onmoveFn(event: NguCarouselStore) {
    this.currentSlide = event.currentSlide;
    let slidesToShow = 1;
    this.nguPage = Math.ceil((this.currentSlide ? this.currentSlide : 0) / slidesToShow + 1);
    this.nguTotalPage = Math.ceil(this.suitemoreInfo_slider.length / slidesToShow);
    if (this.nguPage == 1) {
      this.imageScrollPosition = 'tour'
    } else if (this.nguPage == 2) {
      this.imageScrollPosition = 'map'
    } else {
      this.imageScrollPosition = 'photo'
    }
  }

  prevSlide() {
    const prevIndex = this.pageNguCarousel.currentSlide - 1;
    if (prevIndex < 0) {
      this.pageNguCarousel.moveTo(this.suitemoreInfo_slider.length - 1);
    } else {
      this.pageNguCarousel.moveTo(prevIndex);
    }
  }

  nextSlide() {
    const nextIndex = this.pageNguCarousel.currentSlide + 1;
    if (nextIndex >= this.suitemoreInfo_slider.length) {
      this.pageNguCarousel.moveTo(0);
    } else {
      this.pageNguCarousel.moveTo(nextIndex)
    }
  }
}