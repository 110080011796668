<div id="rcyc-page-offers-and-programs-details" class="row bodyContentOuter">
  <section id="nonCompareSection" class="nonCompareSection">
    <!-- Hero-video 1-->
    <app-rcyc-hero-module *ngIf="responseArray" [responseArray]="responseArray"
      [pageName]="'offersProgramsDetails'"></app-rcyc-hero-module>
    <div *ngIf="currentUrlKey!=='nonperformance-of-cruises' && apiResponseB" class="!mb-0 pt-10 lg:pt-16 lg:pb-16 overflow-x-hidden">
      <app-rcyc-50-50 [data]="apiResponseB"></app-rcyc-50-50>
    </div>
    <div *ngIf="currentUrlKey==='nonperformance-of-cruises' && apiResponseB" class="pt-10 lg:pt-16 lg:pb-16 overflow-x-hidden ">
      <section class="row">
        <section class="row section-2">
          <section class="l-container-wrapper">
            <section class="l-container">
              <div *ngIf="apiResponseB?.title" id="mainTitleId" class="page-title" [title]="apiResponseB?.title | convertHtml" [innerHtml]="apiResponseB?.title"></div>
              <div class="row is-12">										
                <p *ngIf="apiResponseB?.field_com_description" id="mainDescriptionId" [innerHtml]="apiResponseB?.field_com_description"></p>												
            </div>
            </section>
          </section>
        </section>
      </section>
    </div>
    <div class="pb-[80px] lg:pb-[128px] lg:pt-[64px]" *ngIf="drupalVoyages">
      <app-rcyc-new-find-a-cruise #rcycNewFindACruiseComponent [pageName]="'offersProgramsDetails'"
        [drupalVoyages]="drupalVoyages" [legalDisclosureText]="data[0]?.field_offers_landing_page_bottom"></app-rcyc-new-find-a-cruise>
    </div>
    <!--SLIDER-->
    <div *ngIf="apiResponseE" class=" pt-0 lg:pt-0 pb-10 lg:pb-16">
      <app-rcyc-slider variant="editorial" id="home-editorial-slider" [data]="{
        topContent: {
          eyebrow: apiResponseE[0]?.field_com_eyebrow,
          title: apiResponseE[0]?.title | convertHtml,
        },
        items: apiResponseE
      }"></app-rcyc-slider>
    </div>
  </section>
</div>