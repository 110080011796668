import { Component, OnInit, ViewEncapsulation, HostListener, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
/* import { NgxCarousel } from 'ngx-carousel'; */
import { Router } from '@angular/router';
import { switchMap, first } from 'rxjs/operators';
import { of } from 'rxjs';
import { RcycHomeService } from './rcyc-home.service';
import { AppConfig } from '../../rcyc-config/config';
import { RcycCommonService } from '../../rcyc-services/rcyc-common/rcyc-common.service';
// import { RcycGoogleAnalyticsEventsService } from '../../rcyc-services/rcyc-google-analytics-events/rcyc-google-analytics-events.service';
import { DomSanitizer } from '@angular/platform-browser';
import { RcycNewFindACruiseService } from '../rcyc-new-find-a-cruise/rcyc-new-find-a-cruise.service';
import { SharedServiceService } from '../../rcyc-services/rcyc-shared/shared-service.service';
// declare let ga: any;

@Component({
  selector: 'app-home',
  templateUrl: './rcyc-home.component.html',
  styleUrls: ['./rcyc-home.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [RcycHomeService]
})
export class HomeComponent implements OnInit, AfterContentChecked {
  IMG_BASE_URL: any = AppConfig.IMG_BASE_URL;

  public carouselOne: any;
  UserDetails: any = {};
  moreOption = false;
  region = false;
  departure_date = false;
  duration = false;
  durationValues = false;
  ports = false;
  yachts = false;
  offer_program = false;
  regionselect: any = [];
  departure_dateselect: any = [];
  duration_select: any = [];
  ports_select: any = [];
  durationValues_select: any = [];
  yachts_select: any = [];
  offer_program_select: any = [];

  voyagesThumbnails: any;
  apiResponseA: any;
  apiResponseATiles: any;
  apiResponseB: any;
  apiResponseC: any;
  apiResponseD: any = [];
  apiResponseE: any;
  apiResponseF: any;
  apiResponse: any;
  apiResponseATemp: any;
  apiResponseBTemp: any;
  apiResponseCTemp: any;
  apiResponseDTemp: any;
  apiResponseETemp: any;
  apiResponseFTemp: any;
  apiResponseAStatus: any = false;
  apiResponseATilesStatus: any = false;
  apiResponseBStatus: any = false;
  apiResponseCStatus: any = false;
  apiResponseDStatus: any = false;
  apiResponseEStatus: any = false;
  apiResponseFStatus: any = false;

  apiUrlMetatag: any;
  apiUrlA: any;
  apiUrlATiles: any;
  apiUrlB: any;
  apiUrlC: any;
  apiUrlD: any;
  apiUrlE: any;
  apiUrlF: any;
  apiUrlFindACruise: any;

  Fistfilterfield: any = [];
  FindcruiseAPI: any;
  searchdata: any = {};
  voyagesResult: any;

  basepath: any;
  Mob_device = false;
  location: any;
  dep_dateselect: any = [];
  select_feild: any;
  slct_regionname: any = [];
  slct_deptnname: any = [];
  slct_durationnname: any = [];
  slct_yachtnname: any = [];
  FindCruiseRegion: any = [];
  FindCruiseDepdate: any = [];
  FindCruiseDuration: any;
  FindCruisePorts: any = [];
  FindCruisedurationValues: any = [];
  FindCruiseYacht: any;
  totaldata: any;
  temp_deptdate: any;
  selectedDurations: any = [];

  responseArray: any;
  regionTypedFlag = false;
  regionTyped = '';
  regionTypedInitialFlag = false;
  FindCruiseRegionTemp = [];
  FindCruiseFilteredRegion = [];

  durationTypedFlag = false;
  yachtTypedFlag = false;
  durationTyped = '';
  durationTypedInitialFlag = false;
  yachtTyped = '';
  yachtTypedInitialFlag = false;
  FindCruisePortTemp = [];
  FindCruiseDurationTemp = [];
  FindCruiseYachtTemp = [];
  FindCruiseFilteredDuration = [];
  FindCruiseFilteredYacht = [];

  departureDateTypedFlag = false;
  departureDateTyped = '';
  departureDateInitialFlag = false;
  FindCruiseDepartureDateTemp = [];
  FindCruiseFilteredDepartureDate = [];

  regionDropDownFlag = false;
  departureDateDropDownFlag = false;
  durationDropDownFlag = false;
  findCruiseData: any;
  formattedDate: any;

  months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September',
    'October', 'November', 'December'];

  regionDDTabIndex: any = '0';
  departureDateDDTabIndex: any = '0';
  durationValuesDDTabIndex: any = '0';
  yachtsDDTabIndex: any = '0';

  regionFocusFlag = false;
  departureDateFocusFlag = false;
  portFocusFlag = false;
  durationFocusFlag = false;
  yachtFocusFlag = false;
  regionIconFocussed = false;
  departureDateIconFocussed = false;
  portIconFocussed = false;
  durationIconFocussed = false;
  yachtIconFocussed = false;
  public innerWidth: any;
  imageArray = [];
  pageSize: number = 9;
  additionalCostInfo: any;
  infoCommonMsg: any;
  currencyCode: any;
  topScreen: boolean = true;
  stickyFooterData: any;
  primaryLink: any;
  secondaryLink: any;

  constructor(
    private router: Router,
    private rcycHomeService: RcycHomeService,
    private rcycCommonService: RcycCommonService, // public googleAnalyticsEventsService: RcycGoogleAnalyticsEventsService
    private sanitizer: DomSanitizer,
    private changeDetector: ChangeDetectorRef,
    private rcycFindACruiseService: RcycNewFindACruiseService,
    private _SharedServiceService: SharedServiceService,

  ) {
    // Call get Metatag function

    this.apiUrlMetatag = '/api/v1/meta/home?_format=json';
    this.rcycCommonService.getMetaTags(this.apiUrlMetatag);

    // Current subHeader Status
    this.rcycCommonService.changeSubHeaderStatus(false);

    if (!localStorage.getItem('UserDetails')) {
      setTimeout(() => {
        this.get_userbasic_details();
      }, 2000);
    } else {
      this.get_userbasic_details();
    }
  }

  ngOnInit() {
    /**
     * Settings for carousel
     */
    this.carouselOne = {
      grid: { xs: 1, sm: 1, md: 3, lg: 3, all: 0 },
      slide: 3,
      speed: 400,
      interval: { timing: 4000, initialDelay: 1000 },
      point: {
        visible: true
      },
      load: 2,
      touch: true,
      loop: true,
      custom: 'banner'
    };

    this.innerWidth = window.innerWidth;
    this.getCruiseImageDrupal();
    this.getInfoCommonMsg();
    this.getStickyFooterData();
  }

  getStickyFooterData() {
    const apiUrl = '/api/v1/stickyfootercontent/home?_format=json';
    this.rcycHomeService.home(apiUrl).subscribe(
      (response: any) => {
        if (response && response.length) {
          this.stickyFooterData = response[0];
          let tempCtaUrl = this.stickyFooterData?.field_com_cta_link;
          if (tempCtaUrl && tempCtaUrl?.indexOf('internal:') !== -1) {
            tempCtaUrl = tempCtaUrl?.split(':')[1];
          }
          this.primaryLink = tempCtaUrl;
          let tempUrl = this.stickyFooterData?.field_com_secondary_cta_link;
          if (tempUrl && tempUrl?.indexOf('internal:') !== -1) {
            tempUrl = tempUrl?.split(':')[1];
          }
          this.secondaryLink = tempUrl;
        }
      },
      err => {
        console.log('Drupal Call Api error');
        console.log(err);
      }
    );
  }

  getCruiseImageDrupal() {
    const drupalImageArray = this.rcycCommonService.getSessionData('drupalImageArray');
    if (drupalImageArray) {
      this.rcycHomeContent();
    } else {
      const Api_Url = '/api/v1/voyages/thumbnails?_format=json';
      this.rcycFindACruiseService.getCruiseImageDrupal(Api_Url).subscribe(
        (response) => {
          this.rcycCommonService.setSessionData('drupalImageArray', response);
          this.rcycHomeContent();
        },
        (err) => {
          console.log('Error on Getting Cruise Images');
          console.log(err);
        },
      );
    }
  }

  @HostListener("window:scroll", [])
  onScroll(): void {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    if (scrollTop < 50) {
      this.topScreen = true;
    } else {
      this.topScreen = false;
    }
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  /*
   * Function for toggle find a cruise
   */
  changeMoreoption() {
    this.moreOption = !this.moreOption;
  }

  /*
   * Function for trigger while slider reach last slide image
   */
  nextCarouselload(ev) { }

  /*
  * Function for Colapse find a cruise
  */

  scrollDown() {
    document.getElementById("findacruise").scrollIntoView({ block: 'end', behavior: 'smooth' })
  }

  reset_filter(field) {
    if (field !== 'region') { this.region = false; }
    if (field !== 'departure_date') { this.departure_date = false; }
    if (field !== 'durationValues') { this.durationValues = false; }
    if (field !== 'ports') { this.ports = false; }
    if (field !== 'yachts') { this.yachts = false; }
    if (field !== 'offer_program') { this.offer_program = false; }
  }
  select_filter(field) {

    if (field === 'region') {
      this.region = true;

      if (document.getElementById('region_dropdown')) {
        document.getElementById('region_dropdown').style.visibility = 'visible';
        this.regionDropDownFlag = true;
      }
      this.regionTypedFlag = true;
      this.regionDDTabIndex = '-1';

      // Close Other drop Downs
      // Departure Date
      const departureDateDropDown = document.getElementById('departure_dropdown');
      if (departureDateDropDown && departureDateDropDown.style && departureDateDropDown.style.visibility) {
        departureDateDropDown.style.visibility = 'hidden';
        this.departureDateDropDownFlag = false;
      }
      // Ports
      const portDropDown = document.getElementById('ports_dropdown');
      if (portDropDown && portDropDown.style && portDropDown.style.visibility) {
        portDropDown.style.visibility = 'hidden';
      }
      // durationValues
      const durationDropDown = document.getElementById('durationValues_dropdown');
      if (durationDropDown && durationDropDown.style && durationDropDown.style.visibility) {
        durationDropDown.style.visibility = 'hidden';
        this.durationDropDownFlag = false;
      }
    }
    if (field === 'departure_date') {

      this.departure_date = true;
      if (document.getElementById('departure_dropdown')) {
        document.getElementById('departure_dropdown').style.visibility = 'visible';
        this.departureDateDropDownFlag = true;
      }
      this.departureDateTypedFlag = true; // commment this line  when issue in date
      this.departureDateDDTabIndex = '-1';

      // Close Other drop Downs
      // Region
      const regionDropDown = document.getElementById('region_dropdown');
      if (regionDropDown && regionDropDown.style && regionDropDown.style.visibility) {
        regionDropDown.style.visibility = 'hidden';
        this.regionDropDownFlag = false;
      }
      // Ports
      const portDropDown = document.getElementById('ports_dropdown');
      if (portDropDown && portDropDown.style && portDropDown.style.visibility) {
        portDropDown.style.visibility = 'hidden';
      }
      // durationValues
      const durationDropDown = document.getElementById('durationValues_dropdown');
      if (durationDropDown && durationDropDown.style && durationDropDown.style.visibility) {
        durationDropDown.style.visibility = 'hidden';
        this.durationDropDownFlag = false;
      }
    }

    if (field === 'durationValues') {

      this.durationValues = true;

      if (document.getElementById('durationValues_dropdown')) {
        document.getElementById('durationValues_dropdown').style.visibility = 'visible';
        this.durationDropDownFlag = true;
      }

      this.durationTypedFlag = true;
      this.durationValuesDDTabIndex = '-1';

      // Close Other drop Downs
      // Departure Date
      const departureDateDropDown = document.getElementById('departure_dropdown');
      if (departureDateDropDown && departureDateDropDown.style && departureDateDropDown.style.visibility) {
        departureDateDropDown.style.visibility = 'hidden';
        this.departureDateDropDownFlag = false;
      }
      // Region
      const regionDropDown = document.getElementById('region_dropdown');
      if (regionDropDown && regionDropDown.style && regionDropDown.style.visibility) {
        regionDropDown.style.visibility = 'hidden';
        this.regionDropDownFlag = false;
      }
      // Ports
      const portDropDown = document.getElementById('ports_dropdown');
      if (portDropDown && portDropDown.style && portDropDown.style.visibility) {
        portDropDown.style.visibility = 'hidden';
      }
    }
    if (field === 'ports') {

      this.ports = true;

      if (document.getElementById('ports_dropdown')) {
        document.getElementById('ports_dropdown').style.visibility = 'visible';
      }

      // Close Other drop Downs
      // Departure Date
      const departureDateDropDown = document.getElementById('departure_dropdown');
      if (departureDateDropDown && departureDateDropDown.style && departureDateDropDown.style.visibility) {
        departureDateDropDown.style.visibility = 'hidden';
        this.departureDateDropDownFlag = false;
      }
      // Region
      const regionDropDown = document.getElementById('region_dropdown');
      if (regionDropDown && regionDropDown.style && regionDropDown.style.visibility) {
        regionDropDown.style.visibility = 'hidden';
        this.regionDropDownFlag = false;
      }
      // durationValues
      const durationDropDown = document.getElementById('durationValues_dropdown');
      if (durationDropDown && durationDropDown.style && durationDropDown.style.visibility) {
        durationDropDown.style.visibility = 'hidden';
        this.durationDropDownFlag = false;
      }
    }
    if (field === 'offer_program') { this.offer_program = !this.offer_program; }
    // if (!field) {
    //   this.region = true;
    // }
  }

  clearSearchfield(searchfeilds) {
    if (this.Fistfilterfield.indexOf('region') === -1) { this.regionselect = []; }

    if (this.Fistfilterfield.indexOf('departure_date') === -1) {
      this.departure_dateselect = [];
      this.dep_dateselect = [];
    }

    if (this.Fistfilterfield.indexOf('duration') === -1) {
      this.duration_select = [];
    }
    if (this.Fistfilterfield.indexOf('durationValues') === -1) { this.durationValues_select = []; }
    if (this.Fistfilterfield.indexOf('ports') === -1) { this.ports_select = []; }
    if (this.Fistfilterfield.indexOf('yachts') === -1) { this.yachts_select = []; }
    if (this.Fistfilterfield.indexOf('offer_program') === -1) {
      this.offer_program_select = [];
    }
  }

  /**
   * Function for filter values while select dropdown options
   */

  filtervalue(field, value, status, name) {
    this.select_feild = '';
    this.durationTypedFlag = false;
    this.durationValuesDDTabIndex = '0';
    this.yachtTypedFlag = false;
    this.yachtsDDTabIndex = '0';
    this.regionTypedFlag = false;
    this.regionDDTabIndex = '0';
    this.departureDateTypedFlag = false;
    this.departureDateDDTabIndex = '0';
    this.select_feild = field;

    if (this.Fistfilterfield.indexOf(field) === -1) {
      this.Fistfilterfield.push(field);
    }

    const index = this.Fistfilterfield.indexOf(field) + 1;
    this.Fistfilterfield.splice(index, this.Fistfilterfield.length - index);
    // this.clearSearchfield(this.Fistfilterfield);

    if (field === 'region' && status.target.checked === true) {
      this.regionselect.push(value);
      this.slct_regionname.push(name);
      // make the text field not editable
      this.regionTypedFlag = false;
      this.regionDDTabIndex = '0';
    } else if (field === 'region' && status.target.checked === false) {
      this.regionselect.splice(this.regionselect.indexOf(value), 1);
      this.slct_regionname.splice(this.slct_regionname.indexOf(name), 1);
      this.regionTypedFlag = false;
      this.regionDDTabIndex = '0';
    }

    if (field === 'departure_date' && status.target.checked === true) {
      const array = value.split('-');
      // this.departure_dateselect.push(array[0] + '_' + array[1]);

      const dateSplittedArray = value.split(' ');
      // const requiredDateFormat =  dateSplittedArray[1] + '_' + ('0' + this.months.indexOf(dateSplittedArray[0])).slice(-2) + '_' + '01';
      const requiredDateFormat = dateSplittedArray[1] + '_' + ('0' + (this.months.indexOf(dateSplittedArray[0]) + 1)).slice(-2);
      // console.log(newArray[1] + '_' + ('0' + this.months.indexOf(newArray[0])).slice(-2) + '_' + '01');
      //  console.log('requiredDateFormat', requiredDateFormat)
      this.departure_dateselect.push(requiredDateFormat);
      this.dep_dateselect.push(requiredDateFormat);
      this.slct_deptnname.push(value);

      const departureDatedown = document.getElementById('departure_dropdown');
      if (departureDatedown && departureDatedown.style && departureDatedown.style.visibility) {
        departureDatedown.style.visibility = 'visible';
        this.departureDateDropDownFlag = true;
      } // remove this line when issue with date
      this.departureDateTypedFlag = false;
      this.departureDateDDTabIndex = '0';

    } else if (field === 'departure_date' && status.target.checked === false) {

      const dateSplittedArray = value.split(' ');

      this.departure_dateselect.splice(
        this.departure_dateselect.indexOf(dateSplittedArray[1] + '_' + ('0' + (this.months.indexOf(dateSplittedArray[0]) + 1)).slice(-2)),
        1
      );

      const requiredDateFormat = dateSplittedArray[1] + '_' + ('0' + (this.months.indexOf(dateSplittedArray[0]) + 1)).slice(-2);

      this.dep_dateselect.push(requiredDateFormat);
      this.dep_dateselect.splice(this.dep_dateselect.indexOf(value), 1);
      this.slct_deptnname.splice(this.slct_deptnname.indexOf(value), 1);
      this.departureDateTypedFlag = false;
      this.departureDateDDTabIndex = '0';
    }

    if (field === 'durationValues' && status.target.checked === true) {
      value.forEach(element => { this.durationValues_select.push(element); });
      this.slct_durationnname.push(name);
      this.selectedDurations.push(value);
      this.durationTypedFlag = false;
      this.durationValuesDDTabIndex = '0';

    } else if (field === 'durationValues' && status.target.checked === false) {
      value.forEach(element => { this.durationValues_select.splice(this.durationValues_select.indexOf(element), 1); });
      this.slct_durationnname.splice(this.slct_durationnname.indexOf(name), 1);
      this.selectedDurations.splice(this.selectedDurations.indexOf(name), 1)
      this.durationTypedFlag = false;
      this.durationValuesDDTabIndex = '0';
    }

    if (field === 'yachts' && status.target.checked === true) {
      this.yachts_select.push(value);
      this.slct_yachtnname.push(name);
      this.yachtTypedFlag = false;
      this.yachtsDDTabIndex = '0';


    } else if (field === 'yachts' && status.target.checked === false) {
      this.yachts_select.splice(this.yachts_select.indexOf(value), 1);
      this.slct_yachtnname.splice(this.slct_yachtnname.indexOf(name), 1);
      this.yachtTypedFlag = false;
      this.yachtsDDTabIndex = '0';
    }
    if (field === 'offer_program' && status.target.checked === true) {
      this.offer_program_select.push(value);
    } else if (field === 'offer_program' && status.target.checked === false) {
      this.offer_program_select.splice(
        this.offer_program_select.indexOf(value),
        1
      );
    }


    if (status.target.checked === false) {
      this.check_empty_filter();
    }

    this.searchdata = {
      regionCodes: this.regionselect,
      departureYearMonths: this.departure_dateselect,
      durations: this.durationValues_select,
      portCodes: this.ports_select,
      yachtIds: this.yachts_select,
      countryCode: this.UserDetails.country_code,
      page: '0',
      size: this.pageSize,
      selectedDurations: this.selectedDurations
    };

    this.find_cruise_search(this.searchdata);
  }

  /**
  * Check for filter criteria is empty or not
  */

  check_empty_filter() {
    if (this.regionselect.length === 0 && this.dep_dateselect.length === 0 && this.ports_select.length === 0 && this.durationValues_select.length === 0 && this.yachts_select.length === 0) {
      this.select_feild = '';
    }
    if (this.regionselect.length === 0 && this.select_feild === 'region') {
      this.select_feild = '';
    }

    if (this.dep_dateselect.length === 0 && this.select_feild === 'departure_date') {
      this.select_feild = '';
    }

    if (this.durationValues_select.length === 0 && this.select_feild === 'durationValues') {
      this.select_feild = '';
    }

    if (this.ports_select.length === 0 && this.select_feild === 'ports') {
      this.select_feild = '';
    }

    if (this.yachts_select.length === 0 && this.select_feild === 'yachts') {
      this.select_feild = '';
    }

  }

  /**
   * Function checking which options are selected in drop down
   * @param field
   * @param value
   */
  Checkotpionselect(field, value) {
    if (field === 'region') {

      return this.regionselect.indexOf(value) > -1;
    }

    if (field === 'departure_date') {
      return this.slct_deptnname.indexOf(value) > -1;
    }
    if (field === 'durationValues') { return this.slct_durationnname.indexOf(value) > -1; }

    if (field === 'ports') { return this.ports_select.indexOf(value) > -1; }

    if (field === 'yachts') { return this.yachts_select.indexOf(value) > -1; }

    if (field === 'offer_program') {
      return this.offer_program_select.indexOf(value) > -1;
    }
  }

  rcycHomeContent() {
    /******************Header image/video Section A********************/
    // this.apiUrlA = '/api/v1/home/video/a?_format=json';
    this.apiUrlA = '/api/v1/banners/home/a?_format=json';
    this.rcycHomeService.home(this.apiUrlA).subscribe(
      response => {

        this.apiResponseATemp = response;
        if (this.apiResponseATemp && this.apiResponseATemp.length) {
          // var res= Object.values(response);
          const res = Object.keys(response).map(function (key) {
            return response[key];
          });
          if (res.length) {

            this.responseArray = res;
            const resArray = [];
            for (const index of Object.keys(res)) {
              const factor = res[index];
              let imgTitle;
              if (factor.field_images) {
                factor.field_images = this.IMG_BASE_URL + factor.field_image_375px;
                imgTitle = this.rcycCommonService.imageNameAltSplit(factor.field_images);
                setTimeout(() => {
                  factor.field_images = this.IMG_BASE_URL + factor.field_images;
                }, (2000)
                );
              }
              factor.altval = factor.title + ' ' + imgTitle;
              factor.titleval = factor.title + ' ' + imgTitle;
              resArray.push(factor);
              if (parseInt(index, 10) + 1 === res.length) {
                this.apiResponseAStatus = true;
                this.apiResponseA = resArray;
              }
            }
          }
        }

      },
      err => { }
    );

    /******************Header image/video Section A********************/
    this.apiUrlATiles = '/api/v1/tiles/home/a?_format=json';
    this.rcycHomeService.home(this.apiUrlATiles).subscribe(
      response => {
        const apiResponseATilesTemp:any = response;
        if (apiResponseATilesTemp && apiResponseATilesTemp.length) {
          this.apiResponseATilesStatus = true;
          this.apiResponseATiles = apiResponseATilesTemp.sort(this.rcycCommonService.orderByProp('field_sort_order'));
        }
      },
      err => { }
    );

    /****************Section B **********************/
    this.apiUrlB = '/api/v1/content-slider/home/b?_format=json';
    this.rcycHomeService.home(this.apiUrlB).subscribe(
      response => {

        this.apiResponseBTemp = response;
        if (this.apiResponseBTemp && this.apiResponseBTemp.length) {

          // var res= Object.values(response);
          const res = Object.keys(response).map(function (key) {
            return response[key];
          });
          if (res.length) {
            this.apiResponseBStatus = true;
            this.apiResponseB = res;
          }
        }
      },
      err => { }
    );

    /*****************Section C*********************/
    this.apiUrlC = '/api/v1/50-50/home/c?_format=json';
    this.rcycHomeService.home(this.apiUrlC).subscribe(
      response => {

        this.apiResponseCTemp = response;
        if (this.apiResponseCTemp && this.apiResponseCTemp.length) {
          // var res= Object.values(response);
          const res = Object.keys(response).map(function (key) {
            return response[key];
          });
          if (res.length) {
            this.apiResponseCStatus = true;
            this.apiResponseC = res[0];
          }
        }
      },
      err => { }
    );

    /******************Section D********************/
    this.apiUrlD = '/api/v1/featured-voyages/home/d?_format=json';
    this.rcycHomeService.home(this.apiUrlD).subscribe(
      response => {
        this.apiResponseDTemp = response;
        if (this.apiResponseDTemp && this.apiResponseDTemp.length) {

          const res = Object.keys(response).map(function (key) {
            return response[key];
          });
          if (res.length) {
            res.map((item, i) => {
              this.rcycHomeService.get_voyage(item.field_com_voyage_id, this.UserDetails?.country_code).subscribe(response => {
                let data = { ...response['data'], ...res[i], slider: [] };

                this.rcycCommonService.getSessionData('drupalImageArray')?.map((image, j) => {
                  if (item.field_com_voyage_id === image.field_voyages) {
                    data.slider[0] = [this.rcycCommonService.getSessionData('drupalImageArray')[j].field_voyage_thumbnail]
                  }
                })

                this.rcycCommonService.drupalApisGet('/api/v1/voyages/' + item.field_com_voyage_id + '/tab/itineraryoverview?_format=json').subscribe(
                  (response: any) => {

                    const res = response && response.length > 0 ? Object.keys(response).map(function (key) {
                      return response[key];
                    }) : [];

                    if (res.length) {
                      data.slider[1] = res[0].field_voyage_image;
                    }
                  },
                  err => {
                    console.log(err);
                  },
                );

                // Get data for each voyage
                // this.rcycCommonService.drupalApisGet('/api/v1/voyage-card-slider/' + '11240619' + '?_format=json').subscribe(
                this.rcycCommonService.drupalApisGet('/api/v1/voyage-card-slider/' + data.field_com_voyage_id + '?_format=json').subscribe(
                  (response: any) => {
                    const res = response && response.length > 0 ? Object.keys(response).map(function (key) {
                      return response[key];
                    }) : [];

                    if (res.length) {
                      data.slider = [...data.slider, ...res[0].field_com_images.split(', ')];
                      data.highlight = res[0].field_com_highlight_text
                    }
                  },
                  err => { },
                );

                this.apiResponseD.push(data);
                this.apiResponseDStatus = true;
              })
            })
          }
        }
      },
      err => { },
    );

    this.apiUrlE = '/api/v1/featured-articles/home?_format=json';
    this.rcycHomeService.home(this.apiUrlE).subscribe(
      response => {

        this.apiResponseETemp = response;
        if (this.apiResponseETemp && this.apiResponseETemp.length) {

          // var res= Object.values(response);
          const res = Object.keys(response).map(function (key) {
            return response[key];
          });
          if (res.length) {
            this.apiResponseEStatus = true;
            this.apiResponseE = res;
          }
        }
      },
      err => { }
    );
  }

  find_cruise_search(search) {
    this.voyagesResult = null;
    this.apiUrlFindACruise = '/api/criteria-result';
    this.rcycHomeService.find_cruise(this.apiUrlFindACruise, search).subscribe(
      response => {
        const findcruise: any = response;
        if (!this.findCruiseData) {
          this.findCruiseData = response;
          if (this.findCruiseData && this.findCruiseData.data && this.findCruiseData.data.yachtSearchCriterionForResponseDTO) {
            this.findCruiseData = this.findCruiseData.data.yachtSearchCriterionForResponseDTO;
          }
        }
        if (findcruise && findcruise.code === 200) {
          if (findcruise.data.totalCount !== 0) {
            this.currencyCode = findcruise.data.currencyCode;
            this.setAdditionalCostInfo();

            this.FindcruiseAPI =
              findcruise.data.yachtSearchCriterionForResponseDTO;
            this.voyagesResult = findcruise.data.voyages;
            this.totaldata = findcruise.data.totalCount;

            if (this.select_feild !== 'region') {
              this.FindCruiseRegion =
                findcruise.data.yachtSearchCriterionForResponseDTO.regions;
            }

            if (this.select_feild !== 'departure_date') {
              const FindCruiseDepdate =
                findcruise.data.yachtSearchCriterionForResponseDTO
                  .departureYearMonths;
              this.DepartureDate_sortMonth(FindCruiseDepdate);

            }
            if (this.select_feild !== 'durationValues') {
              this.FindCruisedurationValues =
                findcruise.data.yachtSearchCriterionForResponseDTO.durationValues;
            }
            if (this.select_feild !== 'ports') {
              this.FindCruisePorts =
                findcruise.data.yachtSearchCriterionForResponseDTO.ports;

            }
            if (this.select_feild !== 'yachts') {
              this.FindCruiseYacht =
                findcruise.data.yachtSearchCriterionForResponseDTO.yachts;

            }
          } else {
            this.select_feild = '';
            this.regionselect = [];
            this.dep_dateselect = [];
            this.departure_dateselect = [];
            this.ports_select = [];
            this.durationValues_select = [];
            this.yachts_select = [];

            this.slct_durationnname = [];
            this.slct_yachtnname = [];
            this.slct_deptnname = [];
            this.slct_regionname = [];

            this.searchdata = {
              countryCode: this.UserDetails.country_code,
              page: '0',
              size: this.pageSize
            };
            this.find_cruise_search(this.searchdata);
          }
        }
      },
      err => { }
    );
  }

  DepartureDate_sortMonth(scdate) {
    const temp_arry = [];
    scdate.map(function (val, i) {
      val = val.replace('_', '-');
      temp_arry.push(val + '-01');
    });

    this.FindCruiseDepdate = temp_arry;
    const FilteredDepDateArray = [];
    if (temp_arry.length) {

      temp_arry.map((item, index) => {
        FilteredDepDateArray.push(this.months[parseInt(item.split('-')[1], 10) - 1] + ' ' + item.split('-')[0]);

        if (index === temp_arry.length - 1) {
          this.FindCruiseDepdate = FilteredDepDateArray;
        }
      });

      // Set FormattedDate globally
      if (!this.formattedDate) {
        this.formattedDate = this.FindCruiseDepdate;
      }


    }
  }

  find_a_cruise() {
    if (!this.voyagesResult) {
      return;
    }
    const searchcruiseresult: any = this.searchdata;
    this.rcycCommonService.setData('searchcruiseresult', searchcruiseresult);
    this.rcycCommonService.setData('voyagesResult', this.voyagesResult);
    this.rcycCommonService.setData('totalData', this.totaldata);
    this.rcycCommonService.setData('dep_dateselect', this.dep_dateselect);
    this.router.navigate(['/find-a-cruise']);
  }

  get_userbasic_details() {
    if (!localStorage.getItem('UserDetails')) {
      this.rcycCommonService.getUser_Details().subscribe(
        response => {
          this.UserDetails = response;
          if (this.UserDetails) {
            this.rcycCommonService.setData('UserDetails', this.UserDetails);
            this.searchdata = {
              countryCode: this.UserDetails.country_code,
              page: '0',
              size: this.pageSize
            };

            this.find_cruise_search(this.searchdata);
          } else {
            this.searchdata = {
              countryCode: 'US',
              page: '0',
              size: this.pageSize
            };

            this.find_cruise_search(this.searchdata);
          }
        },
        err => {
          this.searchdata = {
            countryCode: 'US',
            page: '0',
            size: this.pageSize
          };

          this.find_cruise_search(this.searchdata);
        }
      );
    } else {
      this.UserDetails = this.rcycCommonService.getData('UserDetails');
      if (this.UserDetails.country_code) {
        this.UserDetails = this.rcycCommonService.getData('UserDetails');
        this.rcycCommonService.expireLocation();
      } else {
        this.UserDetails.country_code = 'US';
        localStorage.removeItem('UserDetails');
      }


      this.searchdata = {
        countryCode: this.UserDetails.country_code,
        page: '0',
        size: this.pageSize
      };


      this.find_cruise_search(this.searchdata);
    }
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }


  // Filter based on typed Region // ok
  onRegionTyped(event) {
    this.regionTyped = event;


    if (this.regionTyped) {
      this.region = true;
      this.FindCruiseFilteredRegion = this.findCruiseData.regions;
      this.FindCruiseFilteredRegion = this.FindCruiseRegionTemp.filter(item => item.generalDescription.toLowerCase()
        .startsWith(this.regionTyped.toLowerCase()));


      this.FindCruiseRegion = this.FindCruiseFilteredRegion;

      if (!this.FindCruiseRegion.length) {
        if (document.getElementById('region_dropdown')) {
          document.getElementById('region_dropdown').style.visibility = 'hidden';
          this.regionDropDownFlag = false;
        }
      } else {
        this.region = true;
        this.regionDropDownFlag = true;
        if (document.getElementById('region_dropdown')) {
          document.getElementById('region_dropdown').style.visibility = 'visible';
        }

      }

    } else {

      // ================================= Set Required Region
      if (!this.regionTypedInitialFlag) {

        this.FindCruiseRegionTemp = this.FindCruiseRegion;

        // Flag to set the region fild changed ( set only on initial change)
        this.regionTypedInitialFlag = true;
      } else if (!this.slct_deptnname.length && !this.ports_select.length && !this.durationValues_select.length && !this.slct_regionname.length && !this.yachts_select.length) {
        this.FindCruiseRegionTemp = this.findCruiseData.regions;

      } else {
        this.find_cruise_search(this.searchdata);
      }

      // =================================  Set Required Region

      // Set All Cruise Region array default
      this.FindCruiseRegion = this.FindCruiseRegionTemp;
      this.regionTypedFlag = true;
      this.regionDDTabIndex = '-1';
      this.region = true;
      this.regionDropDownFlag = true;
      if (document.getElementById('region_dropdown')) {
        document.getElementById('region_dropdown').style.visibility = 'visible';
      }

      setTimeout(() => {
        if (document.getElementById('region_input')) {
          document.getElementById('region_input').focus();
        }
      }, 500);
    }
  }

  // Filter based on typed Duration // ok
  onDurationTyped(event) {
    this.durationTyped = event;
    if (this.durationTyped) {
      this.FindCruiseFilteredDuration = this.FindCruiseDurationTemp.filter(
        (item) => {
          const countryMatch = item.countryName ? item.countryName.toLowerCase().startsWith(this.durationTyped.toLowerCase()) : false;
          let durationMatch = false;
          const durationValuesegment = item.durationName.split(",").map((segment) => segment.trim());
          for (let index = 0; index < durationValuesegment.length; index++) {
            const segment = durationValuesegment[index];
            if (segment.toLowerCase().startsWith(this.durationTyped.toLowerCase())) {
              durationMatch = true;
              break;
            }
          }
          return durationMatch || countryMatch
        }
      );

      this.FindCruisedurationValues = this.FindCruiseFilteredDuration;

      if (!this.FindCruisedurationValues.length) {
        this.durationDropDownFlag = false;
        if (document.getElementById('durationValues_dropdown')) {
          document.getElementById('durationValues_dropdown').style.visibility = 'hidden';
          this.durationValues = false;
        }

      } else {
        this.durationDropDownFlag = true;
        this.durationValues = true;
        if (document.getElementById('durationValues_dropdown')) {
          document.getElementById('durationValues_dropdown').style.visibility = 'visible';
        }

      }
    } else {

      // ================================= Set Required durationValues
      if (!this.durationTypedInitialFlag) {

        this.FindCruiseDurationTemp = this.FindCruisedurationValues;

        // Flag to set the duration field changed ( set only on initial change)
        this.durationTypedInitialFlag = true;
      } else if (!this.slct_deptnname.length && !this.ports_select.length && !this.durationValues_select.length && !this.durationValues_select.length && !this.slct_regionname.length && !this.yachts_select.length) {
        this.FindCruiseDurationTemp = this.findCruiseData.durationValues;

      } else {
        this.find_cruise_search(this.searchdata);
      }

      // =================================  Set Required durationValues

      // Set All Cruise durationValues array default
      this.FindCruisedurationValues = this.FindCruiseDurationTemp;
      this.durationTypedFlag = true;
      this.durationValuesDDTabIndex = '-1';
      this.durationDropDownFlag = true;
      this.durationValues = true;
      if (document.getElementById('durationValues_dropdown')) {
        document.getElementById('durationValues_dropdown').style.visibility = 'visible';
      }
    }
  }

  // Filter based on typed Departure Date // ok
  onDepartureDateTyped(event) {
    this.departureDateTyped = event;



    if (this.departureDateTyped) {

      this.FindCruiseDepartureDateTemp = this.formattedDate; //
      this.FindCruiseFilteredDepartureDate = this.FindCruiseDepartureDateTemp.filter(item => item.toLowerCase()
        .startsWith(this.departureDateTyped.toLowerCase()));


      this.FindCruiseDepdate = this.FindCruiseFilteredDepartureDate;

      if (!this.FindCruiseDepdate.length) {
        this.departureDateDropDownFlag = false;
        if (document.getElementById('departure_dropdown')) {
          document.getElementById('departure_dropdown').style.visibility = 'hidden';
          this.departure_date = false;
        }
        // this.FindCruiseRegion = this.FindCruiseRegionTemp;
      } else {
        this.departureDateDropDownFlag = true;
        this.departure_date = true;
        if (document.getElementById('departure_dropdown')) {
          document.getElementById('departure_dropdown').style.visibility = 'visible';
        }

      }

    } else {
      // ================================= Set Required fotmatted date
      if (!this.departureDateInitialFlag) {

        this.FindCruiseDepartureDateTemp = this.FindCruiseDepdate;

        // Flag to set the departure Dtae field changed ( set only on initial change)
        this.departureDateInitialFlag = true;
      } else if (!this.slct_deptnname.length && !this.ports_select.length && !this.durationValues_select.length && !this.slct_regionname.length && !this.yachts_select.length) {

        this.FindCruiseDepartureDateTemp = this.formattedDate;

      } else {

        // this.FindCruiseDepartureDateTemp = this.FindCruiseDepdate;
        this.find_cruise_search(this.searchdata);
      }

      // =================================  Set Required fotmatted date

      // Set All Cruise DepartureDate array default
      this.FindCruiseDepdate = this.FindCruiseDepartureDateTemp;
      this.departureDateTypedFlag = true;
      this.departureDateDDTabIndex = '-1';
      this.departure_date = true;
      this.departureDateDropDownFlag = true;
      if (document.getElementById('departure_dropdown')) {
        document.getElementById('departure_dropdown').style.visibility = 'visible';
      }

    }
  }

  // Function execute when click // ok
  searchFieldClick(field) {
    switch (field) {
      case 'region':


        if (!this.ports_select.length && !this.durationValues_select.length && !this.dep_dateselect.length && !this.yachts_select.length) {

          this.FindCruiseRegion = this.findCruiseData.regions;
        }
        this.regionTypedFlag = true;
        this.regionDDTabIndex = '-1';
        if (!this.regionDropDownFlag) {
          this.regionTypedFlag = true;
          this.regionDDTabIndex = '-1';
          setTimeout(() => {
            if (document.getElementById('region_input')) {
              document.getElementById('region_input').focus();
            }
          }, 500);


          const regionDropDown = document.getElementById('region_dropdown');
          if (regionDropDown) {
            regionDropDown.style.visibility = 'visible';
            this.regionDropDownFlag = true;
          }


          // Filter Region based on typed region
          if (this.regionTyped) {  // 2
            this.onRegionTyped(this.regionTyped);
            // For Enable input
            this.regionTypedFlag = true;
            this.regionDDTabIndex = '-1';
            this.region = true;
          } else {
            this.onRegionTyped('');
            // For Enable input
            this.regionTypedFlag = true;
            this.regionDDTabIndex = '-1';
            this.region = true;
          }
        } else {
          setTimeout(() => {
            if (document.getElementById('region_input')) {
              document.getElementById('region_input').focus();
            }
          }, 300);

          this.regionDropDownFlag = false;
          this.regionTypedFlag = false;
          this.regionDDTabIndex = '0';
        }

        break;

      case 'departure_date':

        // if (!this.regionselect.length  && !this.yachts_select.length  && !this.ports_select.length && !this.durationValues_select.length  ) {
        //   this.FindCruiseDepdate = this.findCruiseData.departure_date;
        // }
        this.departureDateTypedFlag = true;
        this.departureDateDDTabIndex = '-1';
        if (!this.departureDateDropDownFlag) {
          this.departureDateTypedFlag = true;
          this.departureDateDDTabIndex = '-1';
          setTimeout(() => {
            if (document.getElementById('departureDate_input')) {
              document.getElementById('departureDate_input').focus();
            }
          }, 300);

          const departureDateDropDown = document.getElementById('departure_dropdown');
          if (departureDateDropDown) {
            departureDateDropDown.style.visibility = 'visible';
            this.departureDateDropDownFlag = true;
          }


          // Filter Departure Date based on Date typed
          if (this.departureDateTyped) {
            this.onDepartureDateTyped(this.departureDateTyped);
            // For Enable input
            this.departureDateTypedFlag = true;
            this.departureDateDDTabIndex = '-1';
            this.departure_date = true;
          } else {
            this.onDepartureDateTyped('');
            // For Enable input
            this.departureDateTypedFlag = true;
            this.departureDateDDTabIndex = '-1';
            this.departure_date = true;
          }
        } else {
          setTimeout(() => {
            if (document.getElementById('departureDate_input')) {
              document.getElementById('departureDate_input').focus();
            }
          }, 300);
          this.departureDateDropDownFlag = false;
          this.departureDateTypedFlag = false;
          this.departureDateDDTabIndex = '0';
        }

        break;

      case 'durationValues':
        if (!this.regionselect.length && !this.dep_dateselect.length && !this.yachts_select.length && !this.ports_select.length) {
          this.FindCruisedurationValues = this.findCruiseData.durationValues;
        }
        if (!this.durationDropDownFlag) {
          this.durationTypedFlag = true;
          this.durationValuesDDTabIndex = '-1';

          setTimeout(() => {
            if (document.getElementById('duration_input')) {
              document.getElementById('duration_input').focus();
            }
          }, 300);


          const durationDropDown = document.getElementById('durationValues_dropdown');
          if (durationDropDown) {
            durationDropDown.style.visibility = 'visible';
            this.durationDropDownFlag = true;
          }

          // Filter duration based on typed ports
          if (this.durationTyped) {

            this.onDurationTyped(this.durationTyped);
            // For Enable input
            this.durationTypedFlag = true;
            this.durationValuesDDTabIndex = '-1';
            this.durationValues = true;
          } else {

            this.onDurationTyped('');
            // For Enable input
            this.durationTypedFlag = true;
            this.durationValuesDDTabIndex = '-1';
            this.durationValues = true;
          }
        } else {
          setTimeout(() => {
            if (document.getElementById('duration_input')) {
              document.getElementById('duration_input').focus();
            }
          }, 300);
          this.durationDropDownFlag = false;
          this.durationTypedFlag = false;
          this.durationValuesDDTabIndex = '0';
        }
        break;
    }
  }

  onRegionFocus(event) {
    const regionFocusSpan = document.getElementsByClassName('regionDD')[0] as HTMLElement;
    if (!this.regionTypedFlag) {
      regionFocusSpan.classList.add('regionDDFocus');
    }
    this.regionFocusFlag = true;
    this.departureDateFocusFlag = false;
    this.portFocusFlag = false;
    this.durationFocusFlag = false;
    this.yachtFocusFlag = false;

    if (!this.slct_durationnname.length) {
      this.durationTypedFlag = false;
      this.durationValuesDDTabIndex = '0';
      this.durationValues = false;
      this.durationDropDownFlag = false;
      this.durationTyped = null;
    } else if (this.slct_durationnname.length) {
      this.durationValues = true;
      this.durationTypedFlag = false;
      this.durationValuesDDTabIndex = '0';
      this.durationTyped = null;
    }
    if (!this.slct_deptnname.length) {
      this.departureDateTypedFlag = false;
      this.departureDateDDTabIndex = '0';
      this.departure_date = false;
      this.departureDateDropDownFlag = false;
      this.departureDateTyped = null;
    } else if (this.slct_deptnname.length) {
      this.departure_date = true;
      this.departureDateTypedFlag = false;
      this.departureDateDDTabIndex = '0';
      this.departureDateTyped = null;
    }

    // For change region dropdown Icon
    this.regionIconFocussed = true;

  }
  onDepartureDateFocus(event) {
    const departureDateFocusSpan = document.getElementsByClassName('departureDateDD')[0] as HTMLElement;
    if (!this.departureDateTypedFlag) {
      departureDateFocusSpan.classList.add('departureDateDDFocus');
    }
    this.departureDateFocusFlag = true;
    this.portFocusFlag = false;
    this.durationFocusFlag = false;
    this.regionFocusFlag = false;
    this.yachtFocusFlag = false;

    if (!this.slct_regionname.length) {
      this.regionTypedFlag = false;
      this.regionDDTabIndex = '0';
      this.region = false;
      this.regionDropDownFlag = false;
      this.regionTyped = null;
    } else if (this.slct_regionname.length) {
      this.region = true;
      this.regionTypedFlag = false;
      this.regionDDTabIndex = '0';
      this.regionTyped = null;
    }
    if (!this.slct_durationnname.length) {
      this.durationTypedFlag = false;
      this.durationValuesDDTabIndex = '0';
      this.durationValues = false;
      this.durationDropDownFlag = false;
      this.durationTyped = null;
    } else if (this.slct_durationnname.length) {
      this.durationValues = true;
      this.durationTypedFlag = false;
      this.durationValuesDDTabIndex = '0';
      this.durationTyped = null;
    }

    // For change departure date Icon
    this.departureDateIconFocussed = true;
  }

  onDurationFocus(event) {
    const durationFocusSpan = document.getElementsByClassName('durationValuesDD')[0] as HTMLElement;
    if (!this.durationTypedFlag) {
      durationFocusSpan.classList.add('durationValuesDDFocus');
    }
    this.portFocusFlag = true;
    this.durationFocusFlag = true;
    this.departureDateFocusFlag = false;
    this.regionFocusFlag = false;
    this.yachtFocusFlag = false;

    if (!this.slct_regionname.length) {
      this.regionTypedFlag = false;
      this.regionDDTabIndex = '0';
      this.region = false;
      this.regionDropDownFlag = false;
      this.regionTyped = null;
    } else if (this.slct_regionname.length) {
      this.region = true;
      this.regionTypedFlag = false;
      this.regionDDTabIndex = '0';
      this.regionTyped = null;
    }

    if (!this.slct_deptnname.length) {
      this.departureDateTypedFlag = false;
      this.departureDateDDTabIndex = '0';
      this.departure_date = false;
      this.departureDateDropDownFlag = false;
      this.departureDateTyped = null;
    } else if (this.slct_deptnname.length) {
      this.departure_date = true;
      this.departureDateTypedFlag = false;
      this.departureDateDDTabIndex = '0';
      this.departureDateTyped = null;
    }
    // For change port Icon
    this.portIconFocussed = true;
  }

  onYachtFocus(event) {
    const yachtFocusSpan = document.getElementsByClassName('yachtDD')[0] as HTMLElement;
    if (!this.yachtTypedFlag) {
      yachtFocusSpan.classList.add('yachtsDDFocus');
    }
    this.yachtFocusFlag = true;
    this.departureDateFocusFlag = false;
    this.regionFocusFlag = false;
    this.portFocusFlag = false;
    this.durationFocusFlag = false;

    if (!this.slct_regionname.length) {
      this.regionTypedFlag = false;
      this.regionDDTabIndex = '0';
      this.region = false;
      this.regionDropDownFlag = false;
      this.regionTyped = null;
    } else if (this.slct_regionname.length) {
      this.region = true;
      this.regionTypedFlag = false;
      this.regionDDTabIndex = '0';
      this.regionTyped = null;
    }
    if (!this.slct_durationnname.length) {
      this.durationTypedFlag = false;
      this.durationValuesDDTabIndex = '0';
      this.durationValues = false;
      this.durationDropDownFlag = false;
      this.durationTyped = null;
    } else if (this.slct_durationnname.length) {
      this.durationValues = true;
      this.durationTypedFlag = false;
      this.durationValuesDDTabIndex = '0';
      this.durationTyped = null;
    }
    if (!this.slct_deptnname.length) {
      this.departureDateTypedFlag = false;
      this.departureDateDDTabIndex = '0';
      this.departure_date = false;
      this.departureDateDropDownFlag = false;
      this.departureDateTyped = null;
    } else if (this.slct_deptnname.length) {
      this.departure_date = true;
      this.departureDateTypedFlag = false;
      this.departureDateDDTabIndex = '0';
      this.departureDateTyped = null;
    }
    // For change port Icon
    this.yachtIconFocussed = true;
  }

  onFindACruiseButtonAndLogoFocus(event) {
    if (!this.slct_regionname.length) {
      this.regionTypedFlag = false;
      this.regionDDTabIndex = '0';
      this.region = false;
      this.regionDropDownFlag = false;
      this.regionTyped = null;
    } else if (this.slct_regionname.length) {
      this.region = true;
      this.regionTypedFlag = false;
      this.regionDDTabIndex = '0';
      this.regionTyped = null;
    }

    if (!this.slct_deptnname.length) {
      this.departureDateTypedFlag = false;
      this.departureDateDDTabIndex = '0';
      this.departure_date = false;
      this.departureDateDropDownFlag = false;
      this.departureDateTyped = null;
    } else if (this.slct_deptnname.length) {
      this.departure_date = true;
      this.departureDateTypedFlag = false;
      this.departureDateDDTabIndex = '0';
      this.departureDateTyped = null;
    }
    if (!this.slct_durationnname.length) {
      this.durationTypedFlag = false;
      this.durationValuesDDTabIndex = '0';
      this.durationValues = false;
      this.durationDropDownFlag = false;
      this.durationTyped = null;
    } else if (this.slct_durationnname.length) {
      this.durationValues = true;
      this.durationTypedFlag = false;
      this.durationValuesDDTabIndex = '0';
      this.durationTyped = null;
    }
  }



  /* ===================================================================
      Host Listener
   ========================================================================= */
  @HostListener('document:click', ['$event'])
  clickout(event) {

    // Remove Three Dropdowns Focus Flag on Focussing Find A Cruise Btn
    if (event.target.classList.contains('findACruiseSearchBtn')) {
      this.regionFocusFlag = false;
    }

    // Related with REGION
    if (document.querySelector('.regionDD')) {
      if (document.querySelector('.regionDD').contains(event.target)) {
        const regionFocusSpan = document.getElementsByClassName('regionDD')[0] as HTMLElement;
        regionFocusSpan.classList.remove('regionDDFocus');
        this.searchFieldClick('region');
        this.regionHideOtherDropdown();
      }
    }
    if (document.getElementById('region_input')) {
      if (document.getElementById('region_input').contains(event.target)) {
        this.regionHideOtherDropdown();
      }
    }

    // Related with DEPARTUREDATE
    if (document.querySelector('.departureDateDD')) {
      if (document.querySelector('.departureDateDD').contains(event.target)) {
        const departureDateFocusSpan = document.getElementsByClassName('departureDateDD')[0] as HTMLElement;
        departureDateFocusSpan.classList.remove('departureDateDDFocus');
        this.searchFieldClick('departure_date');
        this.departureDateHideOtherDropdown();
      }
    }
    if (document.getElementById('departureDate_input')) {
      if (document.getElementById('departureDate_input').contains(event.target)) {
        this.departureDateHideOtherDropdown();
      }
    }

    // Related with PORTS
    if (document.querySelector('.portsDD')) {
      if (document.querySelector('.portsDD').contains(event.target)) {
        const portFocusSpan = document.getElementsByClassName('portsDD')[0] as HTMLElement;
        portFocusSpan.classList.remove('portsDDFocus');
        this.searchFieldClick('ports');
        this.portsDateHideOtherDropdown();
      }
    }
    if (document.getElementById('port_input')) {
      if (document.getElementById('port_input').contains(event.target)) {
        this.portsDateHideOtherDropdown();
      }
    }

    // Related with durationValues
    if (document.querySelector('.durationValuesDD')) {
      if (document.querySelector('.durationValuesDD').contains(event.target)) {
        const durationFocusSpan = document.getElementsByClassName('durationValuesDD')[0] as HTMLElement;
        durationFocusSpan.classList.remove('durationValuesDDFocus');
        this.searchFieldClick('durationValues');
        this.durationValuesDateHideOtherDropdown();
      }
    }
    if (document.getElementById('duration_input')) {
      if (document.getElementById('duration_input').contains(event.target)) {
        this.durationValuesDateHideOtherDropdown();
      }
    }

    // Related with YACHTS
    if (document.querySelector('.yachtsDD')) {
      if (document.querySelector('.yachtsDD').contains(event.target)) {
        const yachtFocusSpan = document.getElementsByClassName('yachtsDD')[0] as HTMLElement;
        yachtFocusSpan.classList.remove('yachtsDDFocus');
        this.searchFieldClick('yachts');
        this.yachtsDateHideOtherDropdown();
      }
    }
    if (document.getElementById('yacht_input')) {
      if (document.getElementById('yacht_input').contains(event.target)) {
        this.yachtsDateHideOtherDropdown();
      }
    }

    if (
      (document.querySelector('.regionDD') && document.querySelector('.regionDD').contains(event.target))
      || (document.getElementById('region_input') && document.getElementById('region_input').contains(event.target))
      || (document.getElementById('region_dropdown') && document.getElementById('region_dropdown').contains(event.target))

      || (document.querySelector('.departureDateDD') && document.querySelector('.departureDateDD').contains(event.target))
      || (document.getElementById('departureDate_input') && document.getElementById('departureDate_input').contains(event.target))
      || (document.getElementById('departure_dropdown') && document.getElementById('departure_dropdown').contains(event.target))

      || (document.querySelector('.portsDD') && document.querySelector('.portsDD').contains(event.target))
      || (document.getElementById('port_input') && document.getElementById('port_input').contains(event.target))
      || (document.getElementById('ports_dropdown') && document.getElementById('ports_dropdown').contains(event.target))

      || (document.querySelector('.durationValuesDD') && document.querySelector('.durationValuesDD').contains(event.target))
      || (document.getElementById('duration_input') && document.getElementById('duration_input').contains(event.target))
      || (document.getElementById('durationValues_dropdown') && document.getElementById('durationValues_dropdown').contains(event.target))

      || (document.querySelector('.yachtsDD') && document.querySelector('.yachtsDD').contains(event.target))
      || (document.getElementById('yacht_input') && document.getElementById('yacht_input').contains(event.target))
      || (document.getElementById('yachts_dropdown') && document.getElementById('yachts_dropdown').contains(event.target))
    ) {

    } else {
      this.outsideFiltersClick();
    }

  }

  /* ===================================================================
       regionHideOtherDropdown
    ========================================================================= */
  regionHideOtherDropdown() {
    // check other twos are open Starts
    const portDropDown = document.getElementById('ports_dropdown');
    const departureDateDropDown = document.getElementById('departure_dropdown');
    const yachtDropDown = document.getElementById('yachts_dropdown');
    const durationDropDown = document.getElementById('durationValues_dropdown');

    if (portDropDown && portDropDown.style) {
      portDropDown.style.visibility = 'hidden';
    }
    if (durationDropDown && durationDropDown.style) {
      durationDropDown.style.visibility = 'hidden';
    }
    if (yachtDropDown && yachtDropDown.style) {
      yachtDropDown.style.visibility = 'hidden';
    }
    if (departureDateDropDown && departureDateDropDown.style) {
      departureDateDropDown.style.visibility = 'hidden';
    }
    this.durationDropDownFlag = false;
    this.durationTypedFlag = false;
    this.durationValuesDDTabIndex = '0';
    this.departureDateDropDownFlag = false;
    this.departureDateTypedFlag = false;
    this.departureDateDDTabIndex = '0';
    if (!this.durationValues_select.length) {
      this.durationValues = false;
    }
    if (!this.dep_dateselect.length) {
      this.departure_date = false;
    }

    // check other twos are open Ends
    // Focusing the Corresponding field Field
    this.regionTypedFlag = true;
    this.regionDDTabIndex = '-1';
    if (document.getElementById('region_input')) {
      document.getElementById('region_input').focus();
    }
  }

  /* ===================================================================
    departureDateHideOtherDropdown
 ========================================================================= */
  departureDateHideOtherDropdown() {

    // check other twos are open Starts
    const portDropDown = document.getElementById('ports_dropdown');
    const durationDropDown = document.getElementById('durationValues_dropdown');
    const regionDropDown = document.getElementById('region_dropdown');
    const yachtDropDown = document.getElementById('yachts_dropdown');

    if (portDropDown && portDropDown.style) {
      portDropDown.style.visibility = 'hidden';
    }
    if (durationDropDown && durationDropDown.style) {
      durationDropDown.style.visibility = 'hidden';
    }
    if (yachtDropDown && yachtDropDown.style) {
      yachtDropDown.style.visibility = 'hidden';
    }
    if (regionDropDown && regionDropDown.style) {
      regionDropDown.style.visibility = 'hidden';
    }

    this.durationDropDownFlag = false;
    this.durationTypedFlag = false;
    this.durationValuesDDTabIndex = '0';
    this.regionDropDownFlag = false;
    this.regionTypedFlag = false;
    this.regionDDTabIndex = '0';
    if (!this.durationValues_select.length) {
      this.durationValues = false;
    }
    if (!this.slct_regionname.length) {
      this.region = false;
    }

    // check other twos are open Ends
    // Focusing the Corresponding field Field

    this.departureDateTypedFlag = true;
    this.departureDateDDTabIndex = '-1';

    if (document.getElementById('departureDate_input')) {
      document.getElementById('departureDate_input').focus();
    }
  }

  /* ===================================================================
   portsDateHideOtherDropdown
========================================================================= */
  portsDateHideOtherDropdown() {

    // check other twos are open Starts

    const regionDropDown = document.getElementById('region_dropdown');
    const departureDateDropDown = document.getElementById('departure_dropdown');
    const yachtDropDown = document.getElementById('yachts_dropdown');
    const durationDropDown = document.getElementById('durationValues_dropdown');

    if (regionDropDown && regionDropDown.style) {
      regionDropDown.style.visibility = 'hidden';
    }
    if (departureDateDropDown && departureDateDropDown.style) {
      departureDateDropDown.style.visibility = 'hidden';
    }
    if (durationDropDown && durationDropDown.style) {
      durationDropDown.style.visibility = 'hidden';
    }

    if (yachtDropDown && yachtDropDown.style) {
      yachtDropDown.style.visibility = 'hidden';
    }
    this.regionDropDownFlag = false;
    this.regionTypedFlag = false;
    this.regionDDTabIndex = '0';
    this.durationDropDownFlag = false;
    this.durationTypedFlag = false;
    this.durationValuesDDTabIndex = '0';
    this.departureDateDropDownFlag = false;
    this.departureDateTypedFlag = false;
    this.departureDateDDTabIndex = '0';
    if (!this.slct_regionname.length) {
      this.region = false;
    }
    if (!this.durationValues_select.length) {
      this.durationValues = false;
    }
    if (!this.dep_dateselect.length) {
      this.departure_date = false;
    }
  }

  /* ===================================================================
  portsDateHideOtherDropdown
========================================================================= */
  durationValuesDateHideOtherDropdown() {

    // check other twos are open Starts

    const regionDropDown = document.getElementById('region_dropdown');
    const departureDateDropDown = document.getElementById('departure_dropdown');
    const yachtDropDown = document.getElementById('yachts_dropdown');
    const portDropDown = document.getElementById('ports_dropdown');

    if (regionDropDown && regionDropDown.style) {
      regionDropDown.style.visibility = 'hidden';
    }
    if (departureDateDropDown && departureDateDropDown.style) {
      departureDateDropDown.style.visibility = 'hidden';
    }
    if (portDropDown && portDropDown.style) {
      portDropDown.style.visibility = 'hidden';
    }

    if (yachtDropDown && yachtDropDown.style) {
      yachtDropDown.style.visibility = 'hidden';
    }
    this.regionDropDownFlag = false;
    this.regionTypedFlag = false;
    this.regionDDTabIndex = '0';
    this.departureDateDropDownFlag = false;
    this.departureDateTypedFlag = false;
    this.departureDateDDTabIndex = '0';
    if (!this.slct_regionname.length) {
      this.region = false;
    }
    if (!this.dep_dateselect.length) {
      this.departure_date = false;
    }

    // check other twos are open Ends
    // Focusing the Corresponding field Field
    this.durationTypedFlag = true;
    this.durationValuesDDTabIndex = '-1';

    if (document.getElementById('duration_input')) {
      document.getElementById('duration_input').focus();
    }

  }

  yachtsDateHideOtherDropdown() {

    // check other twos are open Starts

    const regionDropDown = document.getElementById('region_dropdown');
    const departureDateDropDown = document.getElementById('departure_dropdown');
    const portDropDown = document.getElementById('ports_dropdown');
    const durationDropDown = document.getElementById('durationValues_dropdown');

    if (regionDropDown && regionDropDown.style) {
      regionDropDown.style.visibility = 'hidden';
    }
    if (departureDateDropDown && departureDateDropDown.style) {
      departureDateDropDown.style.visibility = 'hidden';
    }
    if (portDropDown && portDropDown.style) {
      portDropDown.style.visibility = 'hidden';
    }
    if (durationDropDown && durationDropDown.style) {
      durationDropDown.style.visibility = 'hidden';
    }
    this.regionDropDownFlag = false;
    this.regionTypedFlag = false;
    this.regionDDTabIndex = '0';
    this.durationDropDownFlag = false;
    this.durationTypedFlag = false;
    this.durationValuesDDTabIndex = '0';
    this.departureDateDropDownFlag = false;
    this.departureDateTypedFlag = false;
    this.departureDateDDTabIndex = '0';
    if (!this.slct_regionname.length) {
      this.region = false;
    }
    if (!this.ports_select.length) {
      this.ports = false;
    }
    if (!this.durationValues_select.length) {
      this.durationValues = false;
    }
    if (!this.dep_dateselect.length) {
      this.departure_date = false;
    }

    // check other twos are open Ends
    // Focusing the Corresponding field Field
    this.yachtTypedFlag = true;
    this.yachtsDDTabIndex = '-1';

    if (document.getElementById('yacht_input')) {
      document.getElementById('yacht_input').focus();
    }

  }

  /* ===================================================================
    outsideFiltersClick
 ========================================================================= */
  outsideFiltersClick() {
    const regionDropDown = document.getElementById('region_dropdown');

    if (regionDropDown && regionDropDown.style) { // If Region visible
      regionDropDown.style.visibility = 'hidden';
      if (!this.slct_regionname.length) {
        this.region = false;
        this.regionDropDownFlag = false;
        this.regionTypedFlag = false;
        this.regionDDTabIndex = '0';
        this.regionTyped = null;
      } else {
        this.regionDropDownFlag = false;
        this.regionTypedFlag = false;
        this.regionDDTabIndex = '0';
        this.region = true;
        //
        this.regionTyped = null;
      }
    } else {
      if (!this.slct_regionname.length) {
        this.region = false;
        this.regionDropDownFlag = false;
        this.regionTypedFlag = false;
        this.regionDDTabIndex = '0';
        this.regionTyped = null;
      } else {
        this.region = true;
        this.regionTypedFlag = false;
        this.regionDDTabIndex = '0';
        this.regionTyped = null; // new line
      }
    }

    const departureDateDropDown = document.getElementById('departure_dropdown');

    if (departureDateDropDown && departureDateDropDown.style) {
      departureDateDropDown.style.visibility = 'hidden';
      if (!this.slct_deptnname.length) {
        this.departure_date = false;
        this.departureDateDropDownFlag = false;
        this.departureDateTypedFlag = false;
        this.departureDateDDTabIndex = '0';
        this.departureDateTyped = null;
      } else {
        this.departureDateDropDownFlag = false;
        this.departureDateTypedFlag = false;
        this.departureDateDDTabIndex = '0';
        this.departure_date = true;
        //
        this.departureDateTyped = null;
      }
    } else {
      if (!this.slct_deptnname.length) {
        this.departure_date = false;
        this.departureDateDropDownFlag = false;
        this.departureDateTypedFlag = false;
        this.departureDateDDTabIndex = '0';
        this.departureDateTyped = null;
      } else {
        this.departure_date = true;
        this.departureDateTypedFlag = false;
        this.departureDateDDTabIndex = '0';
        this.departureDateTyped = null; // new line

      }
    }

    const durationDropDown = document.getElementById('durationValues_dropdown');
    if (durationDropDown && durationDropDown.style) {
      durationDropDown.style.visibility = 'hidden';
      if (!this.slct_durationnname.length) {
        this.durationValues = false;
        this.durationDropDownFlag = false;
        this.durationTypedFlag = false;
        this.durationValuesDDTabIndex = '0';
        this.durationTyped = null;
      } else {
        this.durationDropDownFlag = false;
        this.durationTypedFlag = false;
        this.durationValuesDDTabIndex = '0';
        this.durationValues = true;
        //
        this.durationTyped = null;
      }
    } else {
      if (!this.slct_durationnname.length) {
        this.durationValues = false;
        this.durationDropDownFlag = false;
        this.durationTypedFlag = false;
        this.durationValuesDDTabIndex = '0';
        this.durationTyped = null;
      } else {
        this.durationValues = true;
        this.durationTypedFlag = false;
        this.durationValuesDDTabIndex = '0';
        this.durationTyped = null; // new line
      }
    }
  }
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {

    if (this.regionFocusFlag) {
      const regionMainSpan: HTMLElement = document.getElementsByClassName('regionDD')[0] as HTMLElement;
      if (regionMainSpan) {
        regionMainSpan.focus();
        regionMainSpan.classList.add('regionDDFocus');
      }
    } else if (this.departureDateFocusFlag) {
      const departureDateMainSpan: HTMLElement = document.getElementsByClassName('departureDateDD')[0] as HTMLElement;
      if (departureDateMainSpan) {
        departureDateMainSpan.focus();
        departureDateMainSpan.classList.add('departureDateDDFocus');
      }
    } else if (this.portFocusFlag) {
      const portMainSpan: HTMLElement = document.getElementsByClassName('portsDD')[0] as HTMLElement;
      if (portMainSpan) {
        portMainSpan.focus();
        portMainSpan.classList.add('portsDDFocus');
      }
    } else if (this.durationFocusFlag) {
      const durationMainSpan: HTMLElement = document.getElementsByClassName('durationValuesDD')[0] as HTMLElement;
      if (durationMainSpan) {
        durationMainSpan.focus();
        durationMainSpan.classList.add('durationValuesDDFocus');
      }
    }
    else if (this.yachtFocusFlag) {
      const yachtMainSpan: HTMLElement = document.getElementsByClassName('yachtsDD')[0] as HTMLElement;
      if (yachtMainSpan) {
        yachtMainSpan.focus();
        yachtMainSpan.classList.add('yachtsDDFocus');
      }
    }


    this.outsideFiltersClick();
  }

  /***********************************************************************************************************************
    Function on Region Focus Out
  ***********************************************************************************************************************/
  onRegionFocusOut(event) {
    this.regionIconFocussed = false;
    const regionFocusSpan = document.getElementsByClassName('regionDD')[0] as HTMLElement;
    regionFocusSpan.classList.remove('regionDDFocus');
  }

  /***********************************************************************************************************************
    Function on Region Focus Out
  ***********************************************************************************************************************/
  onDepartureDateFocusOut(event) {
    this.departureDateIconFocussed = false;
    const departureDateFocusSpan = document.getElementsByClassName('departureDateDD')[0] as HTMLElement;
    departureDateFocusSpan.classList.remove('departureDateDDFocus');
  }
  /***********************************************************************************************************************
    Function on Region Focus Out
  ***********************************************************************************************************************/
  onDurationFocusOut(event) {
    this.durationIconFocussed = false;
    const durationFocusSpan = document.getElementsByClassName('durationValuesDD')[0] as HTMLElement;
    durationFocusSpan.classList.remove('durationValuesDDFocus');
  }
  onYachtFocusOut(event) {
    this.yachtIconFocussed = false;
    const yachtFocusSpan = document.getElementsByClassName('yachtsDD')[0] as HTMLElement;
    yachtFocusSpan.classList.remove('yachtsDDFocus');
  }

  /* Funtion to load responsive Images*/

  loadResponsiveImage(key, width) {
    this.imageArray = [];
    key.map(res => {
      if (width <= 375) {
        this.imageArray.push(this.whetherImagePresent(res, 375));
      } else if (width > 375 && width <= 768) {
        this.imageArray.push(this.whetherImagePresent(res, 768));
      } else if (width > 768 && width <= 1440) {
        this.imageArray.push(this.whetherImagePresent(res, 1440));
      } else if (width > 1440 && width <= 1920) {
        this.imageArray.push(this.whetherImagePresent(res, 1920));
      } else if (width > 1920) {
        this.imageArray.push(this.whetherImagePresent(res, 1920));
      } else {
        this.imageArray.push(this.IMG_BASE_URL + key.field_images); //  Default image
      }
    });
    return this.imageArray;
  }

  /* Funtion to check responsive Images present*/

  whetherImagePresent(res, width) {
    const imageSizeArray = [375, 768, 1440, 1920];
    for (let i = imageSizeArray.indexOf(width); i < imageSizeArray.length;) {
      let keyCheck;
      switch (i) {
        case 0: keyCheck = res.field_image_375px;
          break;
        case 1: keyCheck = res.field_image_768px;
          break;
        case 2: keyCheck = res.field_image_1440px;
          break;
        case 3: keyCheck = res.field_image_1920px;
          break;
      }
      if (!keyCheck) {
        i++;
        if (keyCheck === res.field_image_1920px) {
          return this.IMG_BASE_URL + res.field_images; //  Default image
        }
      } else {
        return this.IMG_BASE_URL + keyCheck;
      }
    }

  }

  /**
  * Get common info messages from drupal
  */
  getInfoCommonMsg() {
    this.rcycCommonService.currentInfoMessageSource$.pipe(
      switchMap((value) => value ? of(value) : this._SharedServiceService.getInfoCommonMsg()),
      first()
    ).subscribe((response) => {
      this.infoCommonMsg = response;
      this.rcycCommonService.setInfoMessageSource(response);
    });
  }

  /**
 * Set the additional cost info based on currency code
 */
  setAdditionalCostInfo() {
    const currencyFieldMap = {
      USD: 'field_usd_additional_cost_info',
      USM: 'field_usm_additional_cost_info',
      EUR: 'field_eur_additional_cost_info',
      GBP: 'field_gbp_additional_cost_info',
    };
    this.additionalCostInfo = this.infoCommonMsg?.[currencyFieldMap[this.currencyCode]];
  }
}