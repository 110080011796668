<div [ngClass]="{
  'container': data.field_com_5050_variant === 'video_variant',
  'lg:container': data.field_com_5050_variant !== 'video_variant',
 }" class="!lg:mx-[96px] !lg:px-0 mx-[24px]">
 <div [ngClass]="{
  'lg:pt-16 pt-[40px]': data.field_com_5050_variant !== 'background_variant',
 }">
<div class="flex flex-col lg:flex-row lg:items-center background-color"
  [ngClass]="{
    'lg:gap-[40px]': data.field_com_5050_variant === 'video_variant',
    'flex-col-reverse lg:gap-12': data.field_com_5050_variant === 'tall_photo_variant',
    'bg-light-blue-2': data.field_com_5050_variant === 'background_variant' || data.field_com_page === 'requestbrochure',
    'lg:flex-row-reverse': data.field_com_5050_alignment === 'right',
   }"
>
  <div class="lg:w-1/2 relative"
    [ngClass]="{
      'aspect-[5/4]': data.field_com_5050_variant === 'background_variant',
      'aspect-[4/5] lg:aspect-auto lg:h-[735px]': data.field_com_5050_variant === 'tall_photo_variant',
      'overflow-hidden': data.field_com_5050_variant !== 'tall_photo_variant',
      'aspect-video': data.field_com_5050_variant === 'video_variant',
    }">
    <div *ngIf="data.field_com_5050_variant === 'video_variant' && data.field_com_video;then video else image">here is ignored</div>
    <ng-template #video>
      <iframe [src]="data.field_com_video" class="fit-image" frameborder="0" allowfullscreen="" autoplay="autoplay"></iframe>
    </ng-template>
    <ng-template #image>
      <div [ngClass]="{
          'lg:absolute lg:top-0 lg:left-0 h-full lg:w-[50vw]': data.field_com_5050_variant === 'tall_photo_variant',
          'lg:right-0': data.field_com_5050_variant === 'tall_photo_variant' && data.field_com_5050_alignment === 'left',
          'lg:left-0': data.field_com_5050_variant === 'tall_photo_variant' && data.field_com_5050_alignment === 'right',

        }">
        <img [src]="data.field_com_image" class="fit-image" alt="">
      </div>
    </ng-template>
  </div>

  <div class="lg:m-0 content-align"
    [ngClass]="{
      'lg:w-1/2': data.field_com_5050_variant !== 'tall_photo_variant',
      'mb-10 lg:w-[71%] lg:px-0': data.field_com_5050_variant === 'tall_photo_variant',
      'py-10 lg:py-0': data.field_com_5050_variant === 'video_variant',
      'lg:px-[48px] py-[32px] px-[24px]': data.field_com_5050_variant !== 'video_variant',
      'lg:px-11 py-8 lg:py-6': data.field_com_5050_variant === 'background_variant',
    }"
  >
    <app-rcyc-section-content
      *ngIf="sectionContentData"
      class="lg:flex items-center lg:mb-0 mb-[36px]"
      variant="dark"
      heading="h2"
      [data]="sectionContentData"
    ></app-rcyc-section-content>

      <div class="flex flex-col gap-2 lg:flex-row lg:mt-[16px]">
      <div class="button-primary lg:px-8 tracking-[1px] px-[30px] py-[8px]" (click)="viewBrochureClick()">View Brochure</div>
      </div>
  </div>
</div>
</div>
</div>

<app-rcyc-view-brochure #viewBrochureModal [voyageRegion]="voyageRegion" [pageName]="'requestbrochure'" [key]="brochure_key"></app-rcyc-view-brochure>


<!--496 708-->
