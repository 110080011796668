<div id="rcyc-page-editorialhub-article" class="row bodyContentOuter">

  <!-- Section 1 - Banner Section with Heading -->
  <section class="bannerSection">
    <!-- Banner Image/vedio -->
    <app-rcyc-hero-module *ngIf="apiResponseBannerStatus && apiResponseBanner" [responseArray]="apiResponseBanner" [hideTextOnBanner]="true"></app-rcyc-hero-module>
  </section>

  <!-- Section 2 - Heading Section -->
  <section class="row lg:mt-[64px] mt-[40px]">
    <div class="container">
      <div class="row is-8-4">
        <div class="column is-8" *ngIf="apiResponseABCDStatus">
          <!-- Page Title -->
          <div *ngIf="apiResponseABCD[0]?.field_topic_name"
            class="font-proxima-nova uppercase text-[16px] mb-[40px] !leading-[26.4px]">
            {{apiResponseABCD[0]?.field_category_title_1}} <span
              class="lg:mx-4 mx-2">|</span>{{apiResponseABCD[0]?.field_category_title_2}} <span
              class="lg:mx-4 mx-2">|</span>{{apiResponseABCD[0]?.field_topic_name}} <span class="lg:mx-4">|</span> <span
              class="lg:ml-0 ml-2" [innerHTML]="apiResponseABCD[0]?.field_date"></span>
          </div>
          <div *ngIf="apiResponseABCD[0]?.title" id="mainTitleId"
            class="uppercase font-adobe-caslon-pro text-[29px] lg:text-[49px] !leading-[33.35px] lg:!leading-[56.35px]"
            [title]="apiResponseABCD[0]?.title | convertHtml" [innerHtml]="apiResponseABCD[0]?.title"></div>
        </div>
        <div class="column is-4  headingIs-4">
          <!-- Dummy section for proper alignment -->
        </div>
      </div>
    </div>
  </section>

  <!-- Section 3 - Article Section -->
  <section class="row mt-[48px]">
    <div class="container">
      <div class="row is-8-4">
        <!-- Left section -->
        <div class="column is-8">
          <!-- Paragraph section-A -->
          <div *ngIf="apiResponseABCDStatus && apiResponseABCD[0]?.field_accordion_details"
            class="font-proxima-nova lg:text-[16px] tracking-[1.2%] leading-[26.4px] mb-[48px]"
            [innerHtml]="apiResponseABCD[0]?.field_accordion_details"></div>
          <!-- Featured Trip Section for mobile view -->
          <div class="featuredTripMobilePosition" *ngIf="featuredTripMobilePosition && featuredVoyageDetails.length">
            <div class="row" *ngIf="voyageDetailsStatus && apiResponseABCDStatus">
              <!-- Heading - Featured Trip -->
              <div class="h1 uppercase font-adobe-caslon-pro lg:text-[31px]"
                [innerHTML]="apiResponseABCD[0]?.field_subheading_featured_trips"></div>
              <!-- GRID View Starts -->
              <div>
                <div class="lg:w-[384px]">
                  <div *ngFor="let item of featuredVoyageDetails">
                    <app-rcyc-card-featured variant="grid-small" [data]="item" [hideInfo]="false" [hideButtons]="false"
                      [imageScrollPosition]="'photo'" [pageName]="'editorial-details'"
                      [additionalCostInfo]="additionalCostInfo" [currencyCode]="currencyCode">
                    </app-rcyc-card-featured>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Article Carousel Section -->
          <div *ngFor="let detail of details; let i=index">
            <div *ngIf="i<details.length-1">
              <h2 *ngIf="detail?.field_accordion_title" class="font-adobe-caslon-pro !text-[25px] mb-[36px]">
                {{detail?.field_accordion_title | convertHtml}}</h2>
              <div *ngIf="detail?.field_accordion_details"
                class="font-proxima-nova lg:text-[16px] tracking-[1.2%] leading-[26.4px] mb-[48px]"
                [innerHtml]="detail?.field_accordion_details"></div>

              <img *ngIf="detail?.field_image_1" class="p04_img slider_data_list imageClass mb-[16px]"
                [src]="detail?.field_image_1" appRcycErrorSrc [onErrImage]="detail?.field_image_1" />
              <iframe *ngIf="detail?.field_video" [src]="detail?.field_video"
                class="min-w-full w-auto w-full max-w-[none] h-auto min-h-full mb-[16px]"></iframe>
              <div *ngIf=" detail?.field_image_1 && detail?.field_image_caption"
                class="font-proxima-nova text-[14px] mb-[48px] text-[#646464]">{{detail?.field_image_caption |
                convertHtml}}</div>
            </div>
            <div *ngIf="i===details.length-1">
              <div [ngClass]="{'flex flex-col lg:flex-row lg:gap-[48px]': detail?.field_image_1}">
                <div [ngClass]="{'lg:w-[50%]': detail?.field_image_1}">
                  <h2 *ngIf="detail?.field_accordion_title" class="font-adobe-caslon-pro !text-[25px] mb-[36px]">
                    {{detail?.field_accordion_title | convertHtml}}</h2>
                  <div *ngIf="detail?.field_accordion_details"
                    class="font-proxima-nova lg:text-[16px] tracking-[1.2%] leading-[26.4px]"
                    [ngClass]="{'text-justify': detail?.field_image_1}" [innerHtml]="detail?.field_accordion_details">
                  </div>
                </div>
                <div [ngclass]="{'lg:w-[50%] lg:h-[480px]': detail?.field_image_1}">
                  <img *ngIf="detail?.field_image_1" class="p04_img slider_data_list imageClass mb-[16px] aspect-[4/5]"
                    [src]="detail?.field_image_1" appRcycErrorSrc [onErrImage]="detail?.field_image_1" />
                  <iframe *ngIf="detail?.field_video" [src]="detail?.field_video"
                    class="min-w-full w-auto w-full max-w-[none] h-auto min-h-full mb-[16px]"></iframe>
                  <div *ngIf="detail?.field_image_1 && detail?.field_image_caption"
                    class="font-proxima-nova text-[14px] text-[#646464]">{{detail?.field_image_caption | convertHtml}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Right Section -->
        <div class="column is-4 outerDiv featuredTripOriginalPosition lg:ml-[27px]"
          *ngIf="featuredTripOriginalPosition">
          <!-- Featured Trip Section-->
          <div class="row" *ngIf="voyageDetailsStatus && apiResponseABCDStatus && featuredVoyageDetails.length">
            <!-- Heading - Featured Trip -->
            <div class="h1 uppercase font-adobe-caslon-pro lg:text-[31px] lg:-mt-[2%]"
              [innerHTML]="apiResponseABCD[0]?.field_subheading_featured_trips"></div>
            <!-- GRID View Starts -->
            <div>
              <div class="lg:w-[384px]">
                <div class="mb-10" *ngFor="let item of featuredVoyageDetails">
                  <app-rcyc-card-featured variant="grid-small" [data]="item" [hideInfo]="false" [hideButtons]="false"
                    [imageScrollPosition]="'photo'" [pageName]="'editorial-details'"
                    [additionalCostInfo]="additionalCostInfo" [currencyCode]="currencyCode">
                  </app-rcyc-card-featured>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>

  <!-- Section 4 - Related Trips Section -->
  <div *ngIf="voyageDetailsStatus && apiResponseABCDStatus" class="lg:pt-[128px] pt-[80px]">
    <!-- GRID View Starts -->
    <app-rcyc-slider variant="featured" id="home-featured-slider" [pageName]="'editorial-details'" [data]="{
              topContent: {
                eyebrow: apiResponseABCD[0]?.field_com_eyebrow,
                title: apiResponseABCD[0]?.field_subheading_related_trips | convertHtml,
              },
              items: loadedItems
            }" [imageScrollPosition]="'map'" [additionalCostInfo]="additionalCostInfo"
      [currencyCode]="currencyCode"></app-rcyc-slider>

    <!-- Display the "Load More" button conditionally. -->
    <div class="loadMoreContainer">
      <button class="loadMoreButton" *ngIf="storyDisplayCount < storyTotalCount" (click)="loadMore()">Load More</button>
    </div>
  </div>

</div>