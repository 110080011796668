<div id="rcyc-page-deck-plans" class="bodyContentOuter">
	<!-- Hero Image -->
	<app-rcyc-hero-module *ngIf="responseArray" [responseArray]="responseArray"
		[pageName]="'deck-plan'"></app-rcyc-hero-module>
	<div>
		<!-- Tabbed navigation -->
		<section class="section-2 sticky top-[51px] lg:top-[55px] z-10 overflow-hidden lg:pb-[15px] md:pb-[15px]">
			<div class="absolute left-0 bottom-[15px] w-full h-full shadow-header display-none lg:block md:block"></div>
			<div class="w-full left-0 top-full backdrop-blur-effect bg-white z-10 overflow-auto relative flex justify-between cursor-pointer">
				<button *ngIf="isMobile" (click)="scrollTabs('left')" (keyup.enter)="scrollTabs('left')"
					class="unset-button text-primary hover:text-primary text-[13px] flex items-center left-arrow hidden">
					<img src="assets/img/arrow_left_small.svg" alt="">
				</button>
				<div class="tabs-container overflow-hidden flex-1 overflow-x-auto whitespace-nowrap container">
					<ul class="flex lg:justify-center gap-8 lg:gap-12 lg:pb-4 md:pb-4" [ngClass]="topScreen === true ? 'py-10 lg:pt-16 md:pt-16': 'py-2.5 lg:pt-2.5 md:pt-2.5' ">
						<ng-container *ngFor="let eachDeck of deckTabObject">
							<li class="m-0 whitespace-nowrap lg:w-1/5 lg:max-w-[120px] text-primary uppercase border-solid border-b border-primary"
								[class.activeTab]="activeTab=== eachDeck.field_deck_floor">
								<a (click)="changeDeck(eachDeck.field_deck_floor);getDeckImage(eachDeck.field_deck_floor)"
									[innerHtml]="eachDeck.title"
									class="unset-button hover:text-primary focus:text-primary whitespace-nowrap text-primary uppercase justify-center flex w-full hover:no-underline border-solid border-b-[3px] border-transparent hover:border-primary focus:border-primary">
								</a>
							</li>
						</ng-container>
					</ul>
				</div>
				<button *ngIf="isMobile" (click)="scrollTabs('right')" (keyup.enter)="scrollTabs('right')"
					class="unset-button text-primary hover:text-primary text-[13px] flex items-center right-arrow">
					<img src="assets/img/arrow_right_small.svg" alt="">
				</button>
			</div>
		</section>
		<!-- Heading -->
		<div class="container mb-10 mt-0 lg:my-16 md:my-16">
			<span class="text-sm/[14px]">{{eyebrow}}</span>
			<div class="mt-6 lg:flex">
				<h2 class="lg:w-1/2 text-[29px] lg:text-[49px] mb-8 lg:mb-10 leading-[33px] lg:leading-[56px] lg:mr-10 lg:relative lg:top-[7px]">
					{{subHeader | convertHtml}}
				</h2>
				<span class="leading-5 lg:w-1/2" [innerHtml]="description">
				</span>
			</div>
		</div>
		<!-- Deck Image -->
		<div class="lg:container pt-12 pb-10 lg:pb-16">
			<img *ngIf="deckImage" id="deckPlanImageId" class="fit-image" draggable="false" [src]="deckImage">
		</div>

		<!-- Amenities -->
		<ng-container *ngIf="diningData && diningData != ''">
			<div class="py-10 lg:py-16">
				<app-rcyc-slider variant="experience" id="dining-slider"  [isMobile]="isMobile"
				(openModal)="openDiningModal($event)" [data]="{
						topContent: {
							title: amenitiesHeaderData?.field_com_title,
							eyebrow: amenitiesHeaderData?.field_com_eyebrow,
							isModal: true,
						},
						items: diningData
		}"></app-rcyc-slider>
			</div>
		</ng-container>

		<!-- Suite -->
		<ng-container *ngIf="suiteData && suiteData != ''">
			<div class="py-10 lg:py-16">
				<app-rcyc-slider variant="experience" id="deck-slider" [isMobile]="isMobile"
					(openModal)="openSuiteModal($event)" [data]="{
						topContent: {
							title: accomodationHeaderData?.field_com_title,
							eyebrow: accomodationHeaderData?.field_com_eyebrow,
							isModal: true,
						},
		  items: suiteData
		}"></app-rcyc-slider>
			</div>
		</ng-container>
		<!-- Featured Voyages -->
		<div class="pt-10 lg:pt-16">
			<app-rcyc-slider variant="featured" id="deck-featured-slider" *ngIf="apiResponse" [additionalCostInfo]="additionalCostInfo"
			[currencyCode] = "currencyCode" [data]="{
			  topContent: {
				eyebrow: apiResponse[0]?.field_com_eyebrow,
				title: apiResponse[0]?.title | convertHtml,
			  },
			  items: apiResponse
			}"></app-rcyc-slider>
		</div>
	</div>
</div>

<app-rcyc-onboard-dining-modal [isMobile]="isMobile" #onboardDiningModal></app-rcyc-onboard-dining-modal>
<app-rcyc-onboard-exp-modal #onboardExpModal></app-rcyc-onboard-exp-modal>
<app-rcyc-suites-modal #onboardSuiteModal></app-rcyc-suites-modal>