import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { RcycRequestCallService } from '../rcyc-request-call/rcyc-request-call.service';
import { RcycHomeService } from '../rcyc-home/rcyc-home.service';
import { catchError, of } from 'rxjs';
import { RcycInternalApisService } from '../../rcyc-services/rcyc-internal-apis/rcyc-internal-apis.service';
import { RcycCommonService } from '../../rcyc-services/rcyc-common/rcyc-common.service';

@Component({
    selector: 'app-rcyc-press',
    templateUrl: './rcyc-press.component.html',
    styleUrls: ['./rcyc-press.component.css'],
    encapsulation: ViewEncapsulation.None,
})

export class RcycPressComponent implements OnInit {

    apiResponseA: any;
    apiResponseB: any[] = [];
    apiResponseC: any;
    apiResponseD: any;
    allPressReleaseData: any[] = [];
    totalData: any;
    pageNumbersToShow: any;
    perPage: any = 5;

    constructor(
        private rcycRequestCallService: RcycRequestCallService,
        private rcycHomeService: RcycHomeService,
        private rcycInternalApisService: RcycInternalApisService,
        private rcycCommonService: RcycCommonService,
    ) { }

    ngOnInit() {
        // Call get Metatag function
        const apiUrlMetatag ='/api/v1/meta/press?_format=json';
        this.rcycCommonService.getMetaTags(apiUrlMetatag);
        
        this.getBannerImage();
        this.getPressContacts();
        this.get5050Data();
        this.getPressReleasesHeader();
        this.getPressReleaseData(1);
        this.pageNumbersToShow = 5;

        if (window.innerWidth <= 1024) {
            this.pageNumbersToShow = 4;
          }
    }

    getBannerImage() {
        let apiUrlA = '/api/v1/banners/press/a?_format=json';
        this.rcycRequestCallService.requestACallDrupal(apiUrlA).subscribe(
            (response: any) => {
                if (response && response.length) {
                    this.apiResponseA = response[0];
                }
            },
            err => {
                console.log('Section B Request Call Api A error');
                console.log(err);
            }
        );
    }

    get5050Data() {
        const apiUrlC = '/api/v1/50-50/press/e?_format=json';
        this.rcycHomeService.home(apiUrlC).subscribe(
            response => {
                const apiResponseCTemp: any = response;
                if (apiResponseCTemp && apiResponseCTemp.length > 0) {
                    const res = Object.keys(response).map(function (key) {
                        return response[key];

                    });
                    if (res.length) {
                        this.apiResponseC = res[0];
                    }
                }
            },
            err => { }

        );
    }

    getPressContacts() {
        let apiUrlB = '/api/v1/press/content/c?_format=json';
        this.rcycRequestCallService.requestACallDrupal(apiUrlB).subscribe(
            (response: any) => {
                if (response && response.length) {
                    this.apiResponseB = response;
                }
            },
            err => {
                console.log('Section B Request Call Api B error');
                console.log(err);
            }
        );
    }

    getPressReleasesHeader(){
        let apiUrlD = '/api/v1/dynamic-title-eyebrow/press?_format=json';
        this.rcycRequestCallService.requestACallDrupal(apiUrlD).subscribe(
            (response: any) => {
                if (response && response.length) {
                    this.apiResponseD = response[0];
                }
            },
            err => {
                console.log('Press Heading error');
                console.log(err);
            }
        );
    }

    getPressReleaseData(page: number) {
        const apiUrlD: string = '/api/v1/50-50/press/d?_format=json';
        this.rcycInternalApisService.getDrupal(apiUrlD).pipe(
            catchError(err => {
                console.error(err);
                return of([]);
            })
        ).subscribe((response: any[]) => {
            this.totalData = response?.length;
            const startIndex = (page - 1) * this.perPage;
            this.allPressReleaseData = response.slice(startIndex, startIndex + this.perPage);
        });
    }

    onPageChanged(page: number) {
        this.getPressReleaseData(page + 1);
    }

    get totalPages(): number {
        return Math.ceil(this.totalData / this.perPage);
    }

}
