import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../rcyc-config/config';

@Injectable()
export class RcycDeckPlanRedesignService {

    API_BASE_URL: any =   AppConfig.API_BASE_URL;
  constructor(

        private http: HttpClient
    ) { }

    /* =====================================================================================================================================
    Funtion to call All Apis for Deck-plans
    * ====================================================================================================================================*/

    deckPlans(apiUrl: string) {

      const url = this.API_BASE_URL + apiUrl;
        return this.http.get(url);
    }

}
