<swal #suiteSwal (beforeOpen)="beforeOpen($event)" (open)="onOpen($event)" [showConfirmButton]="false"
  [showCancelButton]="false" [showCloseButton]="true" customClass="diningSwtOuter" [allowOutsideClick]="false"
  [animation]="false" (close)="onClose($event)" [reverseButtons]="true" [width]="'75%'">
  <ng-container *swalPartial>
    <div
      class="grid gap-[3rem] grid-cols-2" *ngIf="!isMobile && !isTab" id="suites">
      <div class="column is-6 p02_photo_row">
        <div class="p02_photo_row banner_wrapper pl-[32.96px]" *ngIf="suitemoreInfo_slider">
          <ngu-carousel #pageNguCarousel *ngIf="suitemoreInfo_slider" [inputs]="pageCarousel"
            [dataSource]="suitemoreInfo_slider" (onMove)="onmoveFn($event)">
            <ngu-item *nguCarouselDef="let item; let j=index" class="p-0">
              <img *ngIf="item?.field_image" src="{{item?.field_image}}" alt=""
                class="min-w-full w-auto w-full max-w-[none] h-auto min-h-full">
              <iframe *ngIf="item?.field_video" [src]="video"
                class="min-w-full w-auto w-full max-w-[none] h-auto min-h-full"></iframe>
            </ngu-item>
          </ngu-carousel>
          <div class="w-full container max-w-[552px] slider-nav-lg mt-4 !px-0" *ngIf="nguTotalPage > 1">
            <div class="flex justify-center gap-3">
              <div class="cursor-pointer flex leading-8" (click)="prevSlide()"><img
                  src="assets/img/arrow_left_small.svg" alt="">
                PREVIOUS</div>
              <div
                class="carousel-itinerary-slider-experience-dots relative flex items-center grow lg:max-w-[375px] justify-center">
                <ul class="slick-dots" role="tablist" style="">
                  <li *ngFor="let page of suitemoreInfo_slider; let j=index" [class]="j+1==nguPage? 'slick-active': ''"
                    role="presentation"><button type="button" role="tab" id="slick-slide-control0+'j'"
                      aria-controls="slick-slide0+'j'" aria-label="nguPage of 3" tabindex="0"
                      aria-selected="true">{{nguPage}}</button></li>
                </ul>
              </div>
              <div class="cursor-pointer flex leading-8" (click)="nextSlide()">NEXT <img
                  src="assets/img/arrow_right_small.svg" alt=""></div>
            </div>
            <div class="flex justify-center">
              {{nguPage}} / {{nguTotalPage}}
            </div>
          </div>
        </div>
        <div class="lg:flex lg:gap-4 lg:w-full lg:justify-start mt-[24px] mb-6 px-[32.96px]">
          <div *ngIf="suitemoreInfo_slider && suitemoreInfo_slider.length>1" class="flex">
            <button (click)="setImageMode('tour')"
              class="button-small min-w-[84px] lg:min-h-6 px-0 lg:px-0 -mr-[1px] m-0 text-sm group w-1/3"
              [class]="imageScrollPosition === 'tour' ? 'button-primary' : 'button-secondary'">
              <span *ngIf="imageScrollPosition === 'tour'">
                <img src="assets/img/Motion-white.svg" class="flex group-hover:display-none w-4" alt="">
                <img src="assets/img/Motion.svg" class="display-none group-hover:flex w-4" alt="">
              </span>
              <span *ngIf="imageScrollPosition !== 'tour'">
                <img src="assets/img/Motion-white.svg" class="display-none group-hover:flex w-4" alt="">
                <img src="assets/img/Motion.svg" class="flex group-hover:display-none w-4" alt="">
              </span>
              <span class="ml-1">
                Tour
              </span>
            </button>
            <button (click)="setImageMode('map')"
              class="button-small min-w-[120px] lg:min-h-6 px-0 lg:px-0 m-0 text-sm group w-1/3"
              [class]="imageScrollPosition === 'map' ? 'button-primary' : 'button-secondary'"
              style="border-right: none ;">
              <span *ngIf="imageScrollPosition === 'map'">
                <img src="assets/img/Map-w.svg" class="flex group-hover:display-none w-3" alt="">
                <img src="assets/img/map.svg" class="display-none group-hover:flex w-3" alt="">
              </span>
              <span *ngIf="imageScrollPosition !== 'map'">
                <img src="assets/img/Map-w.svg" class="display-none group-hover:flex w-3" alt="">
                <img src="assets/img/map.svg" class="flex group-hover:display-none w-3" alt="">
              </span>
 
              <span class="ml-1">
                FloorPlan
              </span>
            </button>
            <button (click)="setImageMode('photo')"
              class="button-small min-w-[84px] lg:min-h-6 px-0 lg:px-0 m-0 text-sm group w-1/3 border-l-transparent"
              [class]="imageScrollPosition === 'photo' ? 'button-primary' : 'button-secondary'">
              <span *ngIf="imageScrollPosition === 'photo'">
                <img src="assets/img/photo.svg" class="display-none group-hover:flex w-4" alt="">
                <img src="assets/img/photo-white.svg" class="flex group-hover:display-none w-4" alt="">
              </span>
              <span *ngIf="imageScrollPosition !== 'photo'">
                <img src="assets/img/photo-white.svg" class="display-none group-hover:flex w-4" alt="">
                <img src="assets/img/photo.svg" class="flex group-hover:display-none w-4" alt="">
              </span>
 
              <span class="ml-1">
                Photo
              </span>
            </button>
          </div>
        </div>
      </div>
      <div class="column is-6">
        <div>{{eyebrow}}</div>
        <h2 class="text-[23px] lg:text-[32px] leading-[26px] lg:leading-normal font-adobe-caslon-pro tracking-[1px] mt-[24px]">
          <span>{{suitetitle | convertHtml}}</span></h2>
        <div class="lg:flex lg:justify-between">
          <div class="w-1/3 mr-4">
            <div *ngIf="suiteSize">
              <div class="text-[18px] font-adobe-caslon-pro uppercase mb-1 tracking-[1px]">
                Suites Size
              </div>
              <h2 class="text-[14px] font-proxima-nova tracking-[1px]">
                {{suiteSize}}
              </h2>
            </div>
          </div>
          <div class="w-1/3 mr-4">
            <div *ngIf="terraceSize">
              <div class="text-[18px] font-adobe-caslon-pro uppercase mb-1 tracking-[1px]">
                Terrace Size
              </div>
              <h2 class="text-[14px] font-proxima-nova tracking-[1px]">
                {{terraceSize}}
              </h2>
            </div>
          </div>
          <div class="w-1/3 mr-4">
            <div *ngIf="isPricing" class="text-[18px] font-adobe-caslon-pro uppercase mb-5 tracking-[1px]">
              Fares From
            </div>
            <h2 *ngIf="isPricing" class="text-[14px] font-proxima-nova tracking-[1px]">
              <div *ngIf="data.price === 0 || data.price">
                <span *ngIf="data.currency=='USM'">
                  <span *ngIf="(data.price % 1) === 0">{{data.price |
                    currency:'USD':'symbol':'1.0-0'}}</span>
                  <span *ngIf="(data.price % 1) !== 0">{{data.price |
                    currency:'USD':'symbol':'1.2-2'}}</span>
                  USD*
                </span>
                <span *ngIf="data.currency!='USM'">
                  <span *ngIf="(data.price % 1) === 0">{{data.price |
                    currency:data.currency:'symbol':'1.0-0'}}</span>
                  <span *ngIf="(data.price % 1) !== 0">{{data.price |
                    currency:data.currency:'symbol':'1.2-2'}}</span>
                  {{data.currency}}*
                </span>
              </div>
              <span *ngIf="!data.price" [innerHTML]="calltext"></span>
            </h2>
          </div>
        </div>
        <div>
          <div *ngFor="let tab of suitemoreInfo" class="mb-[29.5px] flex flex-col w-full"
            (click)="toogleTab(tab.field_sort_order)">
            <div class="flex justify-between items-center cursor-pointer">
              <div class="text-[20px] font-proxima-nova font-semibold">
                {{tab.field_tile_title | convertHtml}}
              </div>
              <div class="w-[18px] h-[18px] lg:w-[26px] lg:h-[26px]
            top-[16px] lg:top-[10px] right-[8px] lg:right-[24px] text-[10px] leading-none">
                <img *ngIf="!isOpened(tab.field_sort_order)" src="assets/img/plus_icon.svg" alt="">
                <img *ngIf="isOpened(tab.field_sort_order)" src="assets/img/Minus_icon.svg" alt="">
              </div>
            </div>
            <div [ngClass]="{
        'max-h-0 opacity-0 overflow-hidden': !isOpened(tab.field_sort_order),
        'max-h-[500px] opacity-100 overflow-visible': isOpened(tab.field_sort_order)
      }" class="transition-[max-height] duration-3000 ease-in-out">
              <div class="text-[16px] mt-[16px] mr-[40px] font-proxima-nova font-regular listItem"
                [innerHTML]="tab.body"></div>
            </div>
            <div class="border-solid border-t border-[#646464]"
              [class]="isOpened(tab.field_sort_order)? 'mt-0': 'mt-[21px]'"></div>
          </div>
        </div>        
        <div class="w-1/2">
          <a class="link-cta button-secondary button-secondary--light uppercase tracking-[.5px] !py-[18.5px] text-[16px]"
            (keyup.enter)="requestAQuoteClick()" (click)="requestAQuoteClick()"
            routerLink="/request-quote">
            Request A Quote
          </a>
        </div>
      </div>
    </div>
    <div class="flex flex-col" *ngIf="isMobile || isTab" id="suites">      
      <div>{{eyebrow}}</div>
      <h2 class="text-[23px] lg:text-[32px] leading-[26px] lg:leading-normal font-adobe-caslon-pro tracking-[1px] mt-[24px]">
        <span>{{suitetitle | convertHtml}}</span></h2>
      <div class="p02_photo_row">
        <div class="lg:flex lg:gap-4 lg:w-full lg:justify-start">
          <div *ngIf="suitemoreInfo_slider && suitemoreInfo_slider.length>1" class="flex">
            <button (click)="setImageMode('tour')"
              class="flex items-center button-small min-w-[84px] lg:min-h-6 px-0 lg:px-0 -mr-[1px] m-0 text-sm w-1/3"
              [class]="imageScrollPosition === 'tour' ? 'button-primary' : 'button-secondary'">
              <span *ngIf="imageScrollPosition === 'tour'">
                <img src="assets/img/Motion-white.svg" class="flex w-4" alt="">
              </span>
              <span *ngIf="imageScrollPosition !== 'tour'">
                <img src="assets/img/Motion.svg" class="flex w-4" alt="">
              </span>
              <span class="ml-1">
                Tour
              </span>
            </button>
            <button (click)="setImageMode('map')"
              class="flex items-center button-small min-w-[120px] lg:min-h-6 px-0 lg:px-0 m-0 text-sm group w-1/3"
              [class]="imageScrollPosition === 'map' ? 'button-primary' : 'button-secondary'"
              style="border-right: none ;">
              <span *ngIf="imageScrollPosition === 'map'">
                <img src="assets/img/Map-w.svg" class="flex w-4" alt="">
              </span>
              <span *ngIf="imageScrollPosition !== 'map'">
                <img src="assets/img/map.svg" class="flex w-4" alt="">
              </span>

              <span class="ml-1">
                FloorPlan
              </span>
            </button>
            <button (click)="setImageMode('photo')"
              class="flex items-center button-small min-w-[84px] lg:min-h-6 px-0 lg:px-0 m-0 text-sm group w-1/3 border-l-transparent"
              [class]="imageScrollPosition === 'photo' ? 'button-primary' : 'button-secondary'">
              <span *ngIf="imageScrollPosition === 'photo'">
                <img src="assets/img/photo-white.svg" class="flex w-5" alt="">
              </span>
              <span *ngIf="imageScrollPosition !== 'photo'">
                <img src="assets/img/photo.svg" class="flex w-5" alt="">
              </span>

              <span class="ml-1">
                Photo
              </span>
            </button>
          </div>
        </div>
        <div class="p02_photo_row banner_wrapper" *ngIf="suitemoreInfo_slider">
          <ngu-carousel #pageNguCarousel *ngIf="suitemoreInfo_slider" [inputs]="pageCarousel"
            [dataSource]="suitemoreInfo_slider" (onMove)="onmoveFn($event)">
            <ngu-item *nguCarouselDef="let item; let j=index" class="p-0">
              <img *ngIf="item?.field_image" src="{{item?.field_image}}" alt="" class="min-w-full w-auto w-full max-w-[none] h-auto min-h-full">
              <iframe *ngIf="item?.field_video" [src]="video"
                class="min-w-full w-auto w-full max-w-[none] h-auto min-h-full"></iframe>
              </ngu-item>
          </ngu-carousel>
          <div class="w-full container max-w-[552px] slider-nav-lg mt-4 !px-0" *ngIf="nguTotalPage > 1">
            <div class="flex justify-center gap-3">
              <div class="cursor-pointer flex leading-8 text-[14px]" (click)="prevSlide()"><img src="assets/img/arrow_left_small.svg"
                  alt="">
                PREVIOUS</div>
              <div
                class="carousel-itinerary-slider-experience-dots relative flex items-center grow lg:max-w-[375px] justify-center">
                <ul class="slick-dots" role="tablist" style="">
                  <li *ngFor="let page of suitemoreInfo_slider; let j=index" [class]="j+1==nguPage? 'slick-active': ''" role="presentation"><button type="button" role="tab"
                    id="slick-slide-control0+'j'" aria-controls="slick-slide0+'j'" aria-label="nguPage of 3" tabindex="0"
                    aria-selected="true">{{nguPage}}</button></li>
                </ul>
              </div>
              <div class="cursor-pointer flex leading-8 text-[14px]" (click)="nextSlide()">NEXT <img
                  src="assets/img/arrow_right_small.svg" alt=""></div>
            </div>
            <div class="flex justify-center ml-8">
              {{nguPage}} / {{nguTotalPage}}
            </div>
          </div>
        </div>       
      </div>
      <div class="flex flex-col mt-[32px]">        
        <div class="flex flex-col mb-[32px]">
          <div class="w-full m-auto mb-[12px]">
            <div *ngIf="suiteSize">
              <div class="text-[18px] regular font-adobe-caslon-pro uppercase mb-1 text-[#646464]">
                Suites Size
              </div>
              <h2 class="text-[18px] font-proxima-nova">
                {{suiteSize}}
              </h2>
            </div>
          </div>
          <div class="w-full m-auto mb-[12px]">
            <div *ngIf="terraceSize">
              <div class="text-[18px] regular font-adobe-caslon-pro uppercase mb-1 text-[#646464]">
                Terrace Size
              </div>
              <h2 class="text-[18px] font-proxima-nova">
                {{terraceSize}}
              </h2>
            </div>
          </div>
          <div class="w-full m-auto">
            <div *ngIf="isPricing" class="text-[18px] regular font-adobe-caslon-pro uppercase mb-1 text-[#646464]">
              Fares From
            </div>
            <h2 *ngIf="isPricing" class="text-[18px] font-proxima-nova">
              <div *ngIf="data.price === 0 || data.price">
                <span *ngIf="data.currency=='USM'">
                  <span *ngIf="(data.price % 1) === 0">{{data.price |
                    currency:'USD':'symbol':'1.0-0'}}</span>
                  <span *ngIf="(data.price % 1) !== 0">{{data.price |
                    currency:'USD':'symbol':'1.2-2'}}</span>
                  USD*
                </span>
                <span *ngIf="data.currency!='USM'">
                  <span *ngIf="(data.price % 1) === 0">{{data.price |
                    currency:data.currency:'symbol':'1.0-0'}}</span>
                  <span *ngIf="(data.price % 1) !== 0">{{data.price |
                    currency:data.currency:'symbol':'1.2-2'}}</span>
                  {{data.currency}}*
                </span>
              </div>
              <span *ngIf="!data.price" [innerHTML]="calltext"></span>
            </h2>
          </div>
        </div>
        <div>
          <div *ngFor="let tab of suitemoreInfo"
            class="mb-[29.5px] flex flex-col w-full transition-all duration-[1000ms]"
            (click)="toogleTab(tab.field_sort_order)">
            <div class="flex justify-between">
              <div class="text-[20px] font-proxima-nova font-semibold">
                {{tab.field_tile_title | convertHtml}}
              </div>
              <div class="w-[26px] h-[18px] lg:w-[26px] lg:h-[26px]
                  top-[16px] lg:top-[10px] right-[8px] lg:right-[24px] text-[10px] leading-none">
                <img *ngIf="!isOpened(tab.field_sort_order)" src="assets/img/plus_icon.svg" alt="">
                <img *ngIf="isOpened(tab.field_sort_order)" src="assets/img/Minus_icon.svg" alt="">
              </div>
            </div>
            <div *ngIf="isOpened(tab.field_sort_order)" class="mt-[16px] mr-[40px] transition-all duration-[2000ms]"
              [class]="isOpened(tab.field_sort_order)? 'opacity-100' : 'opacity-0'">
              <div class="text-[16px] font-proxima-nova font-regular listItem" [innerHTML]="tab.body"></div>
            </div>
            <div class="mt-[14px] border-solid border-t border-[#646464]"></div>
          </div>
        </div>
        <div>
          <a class="link-cta button-secondary button-secondary--light uppercase tracking-[.5px] !py-[18.5px]"
            (keyup.enter)="requestAQuoteClick()" (click)="requestAQuoteClick()"
            routerLink="/request-quote">
            Request A Quote
          </a>
        </div>
      </div>
    </div>
  </ng-container>
</swal>