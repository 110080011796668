<div id="rcyc-press" class="bodyContentOuter">
  <section class="bannerSection">
    <app-rcyc-hero-module *ngIf="apiResponseA" [responseArray]="[apiResponseA]"></app-rcyc-hero-module>
  </section>

  <div>
    <div class="container mx-auto px-4 pt-[40px] lg:pt-[64px] overflow-x-hidden">
      <div class="text-[14px] uppercase tracking-wide mb-[24px]" [innerHtml]="apiResponseB[0]?.field_com_eyebrow"></div>
      <div class="text-[29px]  lg:text-[49px] font-caslon mb-[48px] lg:mb-[64px]"
        [innerHtml]="apiResponseB[0]?.title"></div>
      <div class="grid gap-4 lg:gap-12 grid-cols-1 lg:grid-cols-2 mb-[40px] lg:mb-[64px]">
        <div *ngFor="let item of apiResponseB" class="flex flex-col bg-light-blue-2 px-[32px] py-[32px]">
          <div class=" lg:text-[24px] text-[24px] contactSection leading-normal" [innerHtml]="item.field_summery"></div>
        </div>
      </div>
    </div>

    <div id="scrollPosition">
      <div class="container mt-[40px] lg:mt-[64px] lg:mb-[32px]">
        <div class="text-[14px] uppercase tracking-wide mb-[16px]" [innerHtml]="apiResponseD?.field_com_eyebrow"></div>
        <div class="text-[29px] lg:text-[49px] uppercase font-big-caslon" [innerHtml]="apiResponseD?.title"></div>
      </div>
      <ng-container *ngIf="allPressReleaseData">
        <ng-container *ngFor="let PressReleaseData of allPressReleaseData">
          <div class="py-10 lg:py-8">
            <app-rcyc-50-50 [data]="PressReleaseData"></app-rcyc-50-50>
          </div>
        </ng-container>
      </ng-container>
        <section>
          <div class="container pb-[40px] lg:pb-[64px]">
            <div *ngIf="allPressReleaseData?.length && totalData && (allPressReleaseData?.length < totalData)">
              <app-rcyc-pagination [totalItems]="totalData" [itemsPerPage]="perPage"
                [pageNumbersToShow]="pageNumbersToShow" (pageChanged)="onPageChanged($event)"></app-rcyc-pagination>
            </div>
          </div>
        </section>
    </div>
    <div class="lg:pt-[64px] pt-[40px] imagelibrarySection">
      <app-rcyc-50-50 *ngIf="apiResponseC" [data]="apiResponseC"></app-rcyc-50-50>
    </div>
  </div>